import React, { Component } from 'react';
import getAxiosInstance from '../../axios-clicks';
import Modal from '../../components/UI/Modal/Modal';
import Button from '../../components/UI/Button/Button';
import styles from './LibraryCrud.module.css';
import Spinner from '../../components/UI/Spinner/Spinner';
import { extractRequestErrorMessage } from '../../components/Utils/ExtractRequestErrorMessage';

class LibraryCrud extends Component {
    constructor(props) {
        super(props);
        this.state = {
            libraries: [],
            libraryTypes: [],
            showCreateModal: false,
            showEditModal: false,
            showDeleteModal: false,
            currentLibrary: { id: null, name: '', margin: 0, type: '' },
            loading: false,
        }; this.axiosInstance = getAxiosInstance(this.props.sandbox);
    }

    componentDidMount() {
        this.fetchLibraries();
        this.fetchLibraryTypes();
    }

    fetchLibraries = () => {
        this.setState({ loading: true });
        this.axiosInstance.get('/library')
            .then(response => {
                this.setState({ libraries: response.data, loading: false });
            })
            .catch(error => {
                const errorMessage = extractRequestErrorMessage(error);
                alert(errorMessage);
                this.setState({ loading: false });
            });
    };

    fetchLibraryTypes = () => {
        this.setState({ loading: true });
        this.axiosInstance.get('/library/libraryTypes')
            .then(response => {
                this.setState({ libraryTypes: response.data, loading: false });
            })
            .catch(error => {
                const errorMessage = extractRequestErrorMessage(error);
                alert(errorMessage);
                this.setState({ loading: false });
            });
    };

    handleInputChange = (event) => {
        const { name, value } = event.target;
        
        this.setState(prevState => ({
            currentLibrary: { ...prevState.currentLibrary, [name]: value }
        }));
    };

    handleSubmit = (event) => {
        event.preventDefault();
        const library = this.state.currentLibrary;
        

            const marginValue = parseFloat(library.margin);
            if (!marginValue || marginValue <= 0 || marginValue >= 1) {
                alert('Margin must be between 0 and 1');
                return;
            }
        
        this.setState({ loading: true });

        if (library.id) {
            this.setState({ loading: true });
            this.axiosInstance.put(`/library/${library.id}`, library)
                .then(this.fetchLibraries)
                .catch(error => {
                    const errorMessage = extractRequestErrorMessage(error);
                    alert(errorMessage);
                    this.setState({ loading: false });
                });
        } else {
            this.setState({ loading: true });
            this.axiosInstance.post('/library', library)
                .then(this.fetchLibraries)
                .catch(error => {
                    const errorMessage = extractRequestErrorMessage(error);
                    alert(errorMessage);
                    this.setState({ loading: false });
                });
        }
        this.closeModal();
    };

    openModal = (type, library = { id: null, name: '', margin: 0, type: this.state.libraryTypes[0] || '' }) => {
        this.setState({
            [type]: true,
            currentLibrary: library
        });
    };

    closeModal = () => {
        this.setState({
            showCreateModal: false,
            showEditModal: false,
            showDeleteModal: false
        });
    };

    toggleLibraryStatus = () => {
        const { id, enabled } = this.state.currentLibrary;
        this.setState({ loading: true });
        const endpoint = `/library/${enabled ? 'disable' : 'enable'}/${id}`;
        this.axiosInstance.put(endpoint)
            .then(this.fetchLibraries)
            .catch(error => {
                const errorMessage = extractRequestErrorMessage(error);
                alert(errorMessage);
                this.setState({ loading: false });
            });
        this.closeModal();
    };

    renderCreateModal = () => {
        const { showCreateModal, currentLibrary, libraryTypes } = this.state;
        return (
            <Modal show={showCreateModal} modalClosed={this.closeModal}>
                <div>
                    <div>
                        <label htmlFor="name">Name:</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={currentLibrary.name}
                            onChange={this.handleInputChange}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="margin">Margin:</label>
                        <input
                            type="number"
                            id="margin"
                            name="margin"
                            value={currentLibrary.margin}
                            onChange={this.handleInputChange}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="type">Type:</label>
                        <select
                            id="type"
                            name="type"
                            value={currentLibrary.type}
                            onChange={this.handleInputChange}
                            required
                        >
                            {libraryTypes.map(type => (
                                <option key={type} value={type}>{type}</option>
                            ))}
                        </select>
                    </div>
                    <div className={styles.modalButtonContainer}>
                        <Button btnType="Success" clicked={this.handleSubmit}>Save</Button>
                        <Button btnType="Danger" clicked={this.closeModal} >Cancel</Button>
                    </div>
                </div>
            </Modal>
        );
    };

    renderUpdateModal = () => {
        const { showEditModal, currentLibrary, libraryTypes } = this.state;
        return (
            <Modal show={showEditModal} modalClosed={this.closeModal}>
                <div>
                    <div>
                        <label htmlFor="name">Name:</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={currentLibrary.name}
                            onChange={this.handleInputChange}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="margin">Margin:</label>
                        <input
                            type="number"
                            id="margin"
                            name="margin"
                            value={currentLibrary.margin}
                            onChange={this.handleInputChange}
                            min="0"
                            max="1"
                            step="0.01"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="type">Type:</label>
                        <select
                            id="type"
                            name="type"
                            value={currentLibrary.type}
                            onChange={this.handleInputChange}
                            required
                        >
                            {libraryTypes.map(type => (
                                <option key={type} value={type}>{type}</option>
                            ))}
                        </select>
                    </div>
                    <div className={styles.modalButtonContainer}>
                        <Button btnType="Success" clicked={this.handleSubmit}>Update</Button>
                        <Button btnType="Danger" clicked={this.closeModal} >Cancel</Button>
                    </div>
                </div>
            </Modal>
        );
    };

    renderToggleStatusModal = () => {
        const { showDeleteModal, currentLibrary } = this.state;
        const action = currentLibrary.enabled ? 'Disable' : 'Enable';
        return (
            <Modal show={showDeleteModal} modalClosed={this.closeModal}>
                <div>
                    <p style={{ color: 'red' }}>Attention: if you disable a library, you might get an SOII with status waiting that should become DESPERATE</p>
                    <p>Are you sure you want to {action.toLowerCase()} the library "{currentLibrary.name}"?</p>
                    <div className={styles.modalButtonContainer}>
                        <Button btnType="Success" clicked={this.toggleLibraryStatus}>{action}</Button>
                        <Button btnType="Danger" clicked={this.closeModal}>Cancel</Button>
                    </div>
                </div>
            </Modal>
        );
    };

    render() {
        const { libraries, loading } = this.state;
        if (loading) {
            return <Spinner />;
        }
        return (
            <div>
                <button className={`${styles.button} ${styles.newButton}`} onClick={() => this.openModal('showCreateModal')}>New Library</button>
                <table className={styles.libraryTable}>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Margin</th>
                            <th>Type</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {libraries.map(library => (
                            <tr key={library.id}>
                                <td>{library.id}</td>
                                <td>{library.name}</td>
                                <td>{library.margin}</td>
                                <td>{library.type}</td>
                                <td className={styles.buttonContainer}>
                                    <button className={`${styles.button} ${styles.modifyButton}`} onClick={() => this.openModal('showEditModal', library)}>Modify</button>
                                    <button className={`${styles.button} ${styles.deleteButton}`} onClick={() => this.openModal('showDeleteModal', library)}>
                                        {library.enabled ? 'Disable' : 'Enable'}
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                {this.renderCreateModal()}
                {this.renderUpdateModal()}
                {this.renderToggleStatusModal()}
            </div>
        );
    }
}

export default LibraryCrud;
