import React, { Component } from 'react';
import Modal from '../../components/UI/Modal/Modal';
import getAxiosInstance from '../../axios-clicks';
import styles from './FournitureCrud.module.css';
import Button from '../../components/UI/Button/Button';
import Spinner from '../../components/UI/Spinner/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

class FournitureManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchQuery: {
                designation: '',
                fourniture: '',
                article: ''
            },
            data: [],
            selectedFourniture: null,
            showModal: false,
            modalType: '',
            modalData: {
                fourniture: '',
                article: '',
                marque: '',
                gamme: 'economique',
                price: 0.0,
                designation: ''
            },
            message: '',
            loading: false,
        };
        this.axiosInstance = getAxiosInstance(this.props.sandbox);
    }

    handleSearchChange = (e, type) => {
        const newQuery = { designation: '', fourniture: '', article: '' };
        newQuery[type] = e.target.value;
        this.setState({ searchQuery: newQuery });
    };

    handleSearch = (type) => {
        const { searchQuery } = this.state;
        const query = searchQuery[type];
        let url = '';

        if (type === 'designation') url = `/article/search_fdb_adb_dfb_by_dfb?query=${query}&limit=200`;
        if (type === 'fourniture') url = `/article/search_fdb_adb_dfb_by_fdb?query=${query}&limit=200`;
        if (type === 'article') url = `/article/search_fdb_adb_dfb_by_adb?query=${query}&limit=200`;

        this.setState({ loading: true });
        this.axiosInstance.get(url)
            .then(response => {
                this.setState({ data: response.data, loading: false });
            })
            .catch(error => {
                this.setState({ loading: false });
                console.error('Error fetching data:', error);
            });
    };

    handleCheckboxChange = (fournitureId) => {
        this.setState(prevState => ({
            selectedFourniture: prevState.selectedFourniture === fournitureId ? null : fournitureId
        }));
    };


    openModal = (type) => {
        this.setState({ showModal: true, modalType: type });
    };

    closeModal = () => {
        this.setState({
            showModal: false,
            modalType: '',
            modalData: {
                fourniture: '',
                article: '',
                marque: '',
                gamme: 'economique',
                price: 0.0,
                designation: ''
            }
        });
    };

    handleModalChange = (e) => {
        const { name, value } = e.target;
        this.setState(prevState => ({
            modalData: {
                ...prevState.modalData,
                [name]: value
            }
        }));
    };

    validateModalData = () => {
        const { modalType, modalData } = this.state;
    
        if (modalType === 'new fourniture') {
            return (
                modalData.fourniture.trim() !== '' &&
                modalData.article.trim() !== '' &&
                modalData.gamme.trim() !== '' &&
                modalData.price > 0
            );
        }
    
        if (modalType === 'new article') {
            return (
                modalData.article.trim() !== '' &&
                modalData.gamme.trim() !== '' &&
                modalData.price > 0
            );
        }
    
        if (modalType === 'add article') {
            return (
                modalData.article.trim() !== '' &&
                modalData.gamme.trim() !== '' &&
                modalData.price > 0
            );
        }
    
        if (modalType === 'add designation') {
            return modalData.designation.trim() !== '';
        }
    
        return false;
    };

    handleModalSubmit = () => {

        if (!this.validateModalData()) {
            this.setState({ message: 'Please fill in all required fields.' });
            return;
        }
        const { modalType, modalData, selectedFourniture } = this.state;
        let url = '';
        let data = {};

        this.setState({ loading: true });
        if (modalType === 'new fourniture') {
            url = '/article/save-fourniture-with-article-de-bureau';
            data = {
                fourniture: modalData.fourniture,
                article: modalData.article,
                marque: modalData.marque,
                gamme: modalData.gamme,
                prixVente: modalData.price
            };
        }

        if (modalType === 'new article') {
            url = '/article/save-article-de-bureau';
            data = {
                nom: modalData.article,
                marque: modalData.marque,
                gamme: modalData.gamme,
                prixVente: modalData.price,
            };
        }

        if (modalType === 'add article') {
            url = '/article/add-article-de-bureau-to-fourniture';
            data = {
                nom: modalData.article,
                marque: modalData.marque,
                gamme: modalData.gamme,
                prixVente: modalData.price,
                idFournitureDeBureau: selectedFourniture
            };
        }

        if (modalType === 'add designation') {
            url = '/article/add-designation-de-fourniture-de-bureau-to-fourniture';
            data = {
                designation: modalData.designation,
                idFournitureDeBureau: selectedFourniture
            };
        }

        this.axiosInstance.post(url, data)
            .then(response => {
                if (response.status === 200) {
                    this.setState({ message: 'Successfully created!' });
                    this.clearSearchBars();
                }
                this.setState({ loading: false });
            })
            .catch(error => {
                this.setState({ message: 'Error not created', loading: false });
                console.error('Error creating item:', error);
            })
            .finally(() => {
                this.closeModal();
            });
    };

    clearSearchBars = () => {
        this.setState({
            searchQuery: { designation: '', fourniture: '', article: '' },
            data: [],
            selectedFourniture: null,
        });
    };
    render() {
        const { searchQuery, data, selectedFourniture, showModal, modalType, modalData, message, loading } = this.state;
        if (loading) {
            return <Spinner />;
        }
        return (
            <div className={styles.FournitureManager}>
                <div className={styles.ButtonGroup}>
                    <button
                        onClick={() => this.openModal('new fourniture')}
                        disabled={selectedFourniture !== null}>
                        New Fourniture
                    </button>
                    <button
                        onClick={() => this.openModal('new article')}
                        disabled={selectedFourniture !== null}>
                        New Article
                    </button>
                    <button
                        onClick={() => this.openModal('add article')}
                        disabled={selectedFourniture === null}>
                        Add Article
                    </button>
                    <button
                        onClick={() => this.openModal('add designation')}
                        disabled={selectedFourniture === null}>
                        Add Designation
                    </button>
                </div>

                {message && <p>{message}</p>}

                <div className={styles.Table}>
                    <table>
                        <thead>
                            <tr>
                                <th>Designation</th>
                                <th>Fourniture</th>
                                <th>Article</th>
                            </tr>
                            <tr>
                                <th>
                                    <input
                                        type="text"
                                        value={searchQuery.designation}
                                        onChange={(e) => this.handleSearchChange(e, 'designation')}
                                        disabled={searchQuery.fourniture || searchQuery.article} />
                                    <button onClick={() => this.handleSearch('designation')}>
                                        <FontAwesomeIcon icon={faSearch} />
                                    </button>
                                </th>
                                <th>
                                    <input
                                        type="text"
                                        value={searchQuery.fourniture}
                                        onChange={(e) => this.handleSearchChange(e, 'fourniture')}
                                        disabled={searchQuery.designation || searchQuery.article} />
                                    <button onClick={() => this.handleSearch('fourniture')}>
                                        <FontAwesomeIcon icon={faSearch} />
                                    </button>
                                </th>
                                <th>
                                    <input
                                        type="text"
                                        value={searchQuery.article}
                                        onChange={(e) => this.handleSearchChange(e, 'article')}
                                        disabled={searchQuery.designation || searchQuery.fourniture} />
                                    <button onClick={() => this.handleSearch('article')}>
                                        <FontAwesomeIcon icon={faSearch} />
                                    </button>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((fourniture, index) => (
                                <tr key={index}>
                                    <td>{fourniture.designationsDeFournitureDeBureaux.map(d => <div key={d.id}>{d.designation}</div>)}</td>
                                    <td>
                                        {fourniture.idFourniture !== 0 && (
                                            <input
                                                type="checkbox"
                                                checked={selectedFourniture === fourniture.idFourniture}
                                                onChange={() => this.handleCheckboxChange(fourniture.idFourniture)} />
                                        )}
                                        {fourniture.fournitureName}
                                    </td>
                                    <td>{fourniture.articlesDeBureaux.map(a => <div key={a.id}>{a.nom} - {a.prixVente}</div>)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>



                <Modal show={showModal} modalClosed={this.closeModal}>
                    <div className={styles.ModalContent}>
                        {modalType === 'new fourniture' && (
                            <>
                                <input
                                    type="text"
                                    placeholder="Fourniture"
                                    name="fourniture"
                                    value={modalData.fourniture}
                                    onChange={this.handleModalChange} />
                                <input
                                    type="text"
                                    placeholder="Article"
                                    name="article"
                                    value={modalData.article}
                                    onChange={this.handleModalChange} />
                                <input
                                    type="text"
                                    placeholder="Marque"
                                    name="marque"
                                    value={modalData.marque}
                                    onChange={this.handleModalChange} />
                                <select
                                    name="gamme"
                                    value={modalData.gamme}
                                    onChange={this.handleModalChange}>
                                    <option value="classique">Classique</option>
                                </select>
                                <input
                                    type="number"
                                    placeholder="Price"
                                    name="price"
                                    value={modalData.price}
                                    onChange={this.handleModalChange} />
                            </>
                        )}

                        {modalType === 'new article' && (
                            <>
                                <input
                                    type="text"
                                    placeholder="Article"
                                    name="article"
                                    value={modalData.article}
                                    onChange={this.handleModalChange} />
                                <input
                                    type="text"
                                    placeholder="Marque"
                                    name="marque"
                                    value={modalData.marque}
                                    onChange={this.handleModalChange} />
                                <select
                                    name="gamme"
                                    value={modalData.gamme}
                                    onChange={this.handleModalChange}>
                                    <option value="economique">Economique</option>
                                    <option value="classique">Classique</option>
                                    <option value="premium">Premium</option>
                                </select>
                                <input
                                    type="number"
                                    placeholder="Price"
                                    name="price"
                                    value={modalData.price}
                                    onChange={this.handleModalChange} />
                            </>
                        )}

                        {modalType === 'add article' && (
                            <>
                                <input
                                    type="text"
                                    placeholder="Article"
                                    name="article"
                                    value={modalData.article}
                                    onChange={this.handleModalChange} />
                                <input
                                    type="text"
                                    placeholder="Marque"
                                    name="marque"
                                    value={modalData.marque}
                                    onChange={this.handleModalChange} />
                                <select
                                    name="gamme"
                                    value={modalData.gamme}
                                    onChange={this.handleModalChange}>
                                    <option value="economique">Economique</option>
                                    <option value="classique">Classique</option>
                                    <option value="premium">Premium</option>
                                </select>
                                <input
                                    type="number"
                                    placeholder="Price"
                                    name="price"
                                    value={modalData.price}
                                    onChange={this.handleModalChange} />
                            </>
                        )}

                        {modalType === 'add designation' && (
                            <input
                                type="text"
                                placeholder="Designation"
                                name="designation"
                                value={modalData.designation}
                                onChange={this.handleModalChange} />
                        )}

                        <div className={styles.ButtonGroup}>
                            <Button btnType="Success" clicked={this.handleModalSubmit}>OK</Button>
                            <Button btnType="Danger" clicked={this.closeModal}>Cancel</Button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default FournitureManager;
