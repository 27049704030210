import React, { Component } from 'react';
import getAxiosInstance from '../../axios-clicks';
import Modal from '../../components/UI/Modal/Modal';
import Button from '../../components/UI/Button/Button';
import Spinner from '../../components/UI/Spinner/Spinner';
import styles from './SearchOrDeliverChoice.module.css';

class SearchOrDeliverChoice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderIds: [],
            showChoiceModal: false,
            currentOrderId: '',
            choice: '',
            loading: false,
        };
        this.axiosInstance = getAxiosInstance(this.props.sandbox);
    }

    componentDidMount() {
        this.fetchOrderIds();
    }

    fetchOrderIds = () => {
        this.setState({ loading: true });
        this.axiosInstance.get('/orders/search-or-deliver-choice')
            .then(response => {
                this.setState({ orderIds: response.data, loading: false });
            })
            .catch(error => {
                alert('Failed to fetch order IDs');
                this.setState({ loading: false });
            });
    };

    handleChoiceChange = (event) => {
        this.setState({ choice: event.target.value });
    };

    handleSubmit = () => {
        const { currentOrderId, choice } = this.state;
        if (!choice) {
            alert('Please select a choice');
            return;
        }
        
        this.setState({ loading: true });
        this.axiosInstance.post('/orders/search-or-deliver-choice', { orderId: currentOrderId, choice })
            .then(response => {
                if (response.status === 200) {
                    this.setState(prevState => ({
                        orderIds: prevState.orderIds.filter(id => id !== currentOrderId),
                        showChoiceModal: false,
                        loading: false
                    }));
                } else {
                    this.setState({ loading: false });
                    alert('Failed to update choice');
                }
            })
            .catch(error => {
                alert('Failed to update choice');
                this.setState({ loading: false });
            });
    };

    openChoiceModal = (orderId) => {
        this.setState({
            showChoiceModal: true,
            currentOrderId: orderId,
            choice: ''
        });
    };

    closeChoiceModal = () => {
        this.setState({
            showChoiceModal: false,
            currentOrderId: '',
            choice: ''
        });
    };

    renderChoiceModal = () => {
        const { showChoiceModal, currentOrderId, choice } = this.state;
        return (
            <Modal show={showChoiceModal} modalClosed={this.closeChoiceModal}>
                <div className={styles.choiceModalContent}>
                    <h2>{currentOrderId}</h2>
                    <div>
                        <label htmlFor="choice">Choose:</label>
                        <select
                            id="choice"
                            name="choice"
                            value={choice}
                            onChange={this.handleChoiceChange}
                            required
                        >
                            <option value="">Select</option>
                            <option value="search">Search</option>
                            <option value="deliver">Deliver</option>
                        </select>
                    </div>
                    <div className={styles.modalButtonContainer}>
                        <Button btnType="Success" clicked={this.handleSubmit}>Confirm</Button>
                        <Button btnType="Danger" clicked={this.closeChoiceModal}>Cancel</Button>
                    </div>
                </div>
            </Modal>
        );
    };

    render() {
        const { orderIds, loading } = this.state;
        if (loading) {
            return <Spinner />;
        }
        return (
            <div>
                <table className={styles.orderIdTable}>
                    <thead>
                        <tr>
                            <th>Order ID</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orderIds.map(orderId => (
                            <tr key={orderId}>
                                <td>{orderId}</td>
                                <td className={styles.buttonContainer}>
                                    <button className={`${styles.button} ${styles.chooseButton}`} onClick={() => this.openChoiceModal(orderId)}>
                                        Choose
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {this.renderChoiceModal()}
            </div>
        );
    }
}

export default SearchOrDeliverChoice;
