import axios from 'axios';


const getAxiosInstance = (isSandbox) => {
    const baseURL = isSandbox 
        ? window.env.REACT_APP_BACKEND_STAGING_API_URL
        : window.env.REACT_APP_BACKEND_PRODUCTION_API_URL
    return axios.create({
        baseURL: baseURL
    });
};

export default getAxiosInstance;