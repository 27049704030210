import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import classes from './OrderProcessing.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faHouse, faThumbsUp, faThumbsDown, faQuestion, faStar, faStarHalfAlt } from '@fortawesome/free-solid-svg-icons'
import { faStar as faStarRegular} from '@fortawesome/free-regular-svg-icons';
import Modal from '../../components/UI/Modal/Modal';
import getAxiosInstance from '../../axios-clicks';
import Spinner from '../../components/UI/Spinner/Spinner';
import LibraryOrderPrintComponent from './LibraryOrderPrintComponent';
import jsPDF from "jspdf";
import "jspdf-autotable";
import './Amiri-Regular-normal.js'; 
import './fa-solid-900-normal.js'; 
import './fa-regular-400-normal.js'; 

class OrderProcessing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rassembleurIsFree: true,
      hasArticles: true,
      fetchAttempted: false,
      selectedItems: [],
      currentTab: 'original',
      tabs: {
        original: [],
        found: [],
        notFound: [],
        unknown: [],
      },
      libraryOrderId: null,
      showPartialFoundModal: false,
      modalItemName: '',
      modalItemLevel: '',
      modalItemType: '',
      modalItemQuantity: 0,
      modalItemMaxQuantity: 0,
      isDoneClicked: false,
      showPrintRecapButton: false,
      recap: null,
      loading: false,
    };
    this.axiosInstance = getAxiosInstance(this.props.sandbox);
  }

  handleFetchClick = () => {

    this.setState({ loading: true });
    const userId = this.props.userId;
    console.log(userId);
    this.axiosInstance.get(`/libraryOrders/fetchLibraryOrder/${userId}`)
      .then((response) => {
        const { libraryOrderId, libraryOrderItemsRequest } = response.data;

        if (!libraryOrderItemsRequest || libraryOrderItemsRequest.length === 0) { // if no articles
          this.setState({
            hasArticles: false,
            fetchAttempted: true
          });
        } else {
          this.setState({
            libraryOrderId,
            rassembleurIsFree: false,
            hasArticles: true,
            fetchAttempted: true,
            tabs: {
              ...this.state.tabs,
              original: libraryOrderItemsRequest.map(item => ({
                studentOrderItemId: item.studentOrderItemId,
                fournitureName: item.fournitureName,
                schoolLevelDesignation: item.schoolLevelDesignation,
                quantity: item.quantity,
                articleId: item.articleId,
                gamme: item.gamme,
                articleType: item.articleType,
              })),
            },
          });
        }
        this.setState({ loading: false });
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          // handle error: no articles found
          this.setState({
            hasArticles: false,
            fetchAttempted: true,
          });
        } else {
          console.error("Error fetching data: ", error);
        }
        this.setState({ loading: false });
      });
  };

  handleCheckboxChange = (itemId) => {
    const { selectedItems } = this.state;
    if (selectedItems.includes(itemId)) {
      this.setState({
        selectedItems: selectedItems.filter((id) => id !== itemId),
      });
    } else {
      this.setState({
        selectedItems: [...selectedItems, itemId],
      });
    }
  };

  handleMoveButtonClick = () => {
    const { currentTab } = this.state;
    const tabs = Object.keys(this.state.tabs);
    const otherTabs = tabs.filter(tab => tab !== currentTab);
    if (this.state.selectedItems.length !== 0) {
      this.setState({
        showMoveModal: true,
        moveModalButtons: otherTabs,
        moveModalSelectedTab: otherTabs[0], // Select the first tab as the default
      });
    }
  };

  handleMoveModalTabButtonClick = (tab) => {
    const { selectedItems, currentTab, tabs } = this.state;

    // Get the actual items from the current tab
    const movingItems = tabs[currentTab].filter(item => selectedItems.includes(item.studentOrderItemId));

    console.log('Moving items:', movingItems); // Log moving items

    // Existing items in the target tab
    const existingItems = tabs[tab];

    console.log('Existing items:', existingItems); // Log existing items

    // Merge movingItems and existingItems 
    // If item already exists in the target tab, increase the quantity.
    // If it doesn't exist, add the item.
    const updatedItems = [...existingItems];

    movingItems.forEach(movingItem => {

      console.log('Moving item:', movingItem); // Log each item we are trying to move

      const foundIndex = updatedItems.findIndex(item => item.studentOrderItemId === movingItem.studentOrderItemId);

      console.log('Found index:', foundIndex); // Log the found index

      if (foundIndex > -1) {
        updatedItems[foundIndex].quantity += movingItem.quantity;
      } else {
        updatedItems.push(movingItem);
      }

      console.log('Updated items after move:', updatedItems); // Log the updated items after each move
    });

    // Set the state with the updated items, remove the selected items from the current tab
    this.setState(prevState => ({
      tabs: {
        ...prevState.tabs,
        [tab]: updatedItems,
        [currentTab]: prevState.tabs[currentTab].filter(item => !selectedItems.includes(item.studentOrderItemId)),
      },
      showMoveModal: false,
      selectedItems: [],
    }));
  };


  handleMoveModalCloseClick = () => {
    this.setState({ showMoveModal: false });
  };

  handleModalOptionClick = (tab) => {
    const { selectedItems, currentTab } = this.state;

    // Move selected items to the chosen tab
    this.setState((prevState) => ({
      tabs: {
        ...prevState.tabs,
        [tab]: [...prevState.tabs[tab], ...selectedItems],
      },
    }));

    // Remove selected items from the current tab
    this.setState((prevState) => ({
      tabs: {
        ...prevState.tabs,
        [currentTab]: prevState.tabs[currentTab].filter(
          (item) => !selectedItems.includes(item.studentOrderItemId)
        ),
      },
      selectedItems: [],
      showPartialFoundModal: false,
    }));
  };

  handleOriginalItemClick = (item) => {
    const { currentTab } = this.state;

    if (currentTab === 'original' && item.quantity > 1) {
      this.setState({
        showPartialFoundModal: true,
        modalItemName: item.fournitureName,
        modalItemLevel: item.schoolLevelDesignation,
        modalItemType: item.articleType,
        modalItemQuantity: item.quantity,
        modalItemMaxQuantity: item.quantity,
      });
    }
  };

  handleModalQuantityChange = (quantity) => {
    this.setState({ modalItemQuantity: quantity });
  };

  handlePartialFoundModalFoundClick = () => {
    const {
      modalItemName,
      modalItemLevel,
      modalItemType,
      modalItemQuantity,
      tabs,
      currentTab,
    } = this.state;

    const currentTabItems = tabs[currentTab];
    const foundItems = tabs.found;

    // Check if the item already exists in the "found" tab
    const existingItemIndex = foundItems.findIndex(item => item.fournitureName === modalItemName);

    // Find the original item from the current tab
    const originalItemIndex = currentTabItems.findIndex(item => item.fournitureName === modalItemName);
    const originalItem = originalItemIndex !== -1 ? currentTabItems[originalItemIndex] : null;

    if (existingItemIndex !== -1) {
      // If the item already exists, update the quantity
      const updatedFoundItems = [...foundItems];
      updatedFoundItems[existingItemIndex].quantity += modalItemQuantity;

      this.setState(prevState => ({
        tabs: {
          ...prevState.tabs,
          found: updatedFoundItems,
        },
        showPartialFoundModal: false,
      }));
    } else if (originalItem) {
      // If the item does not exist in "found" tab, add it with the same id as original item
      const newItem = {
        studentOrderItemId: originalItem.studentOrderItemId,  // Keep the same id as the original item
        articleId: originalItem.articleId,
        fournitureName: modalItemName,
        schoolLevelDesignation: modalItemLevel,
        articleType: modalItemType,

        quantity: modalItemQuantity,
      };

      this.setState(prevState => ({
        tabs: {
          ...prevState.tabs,
          found: [...foundItems, newItem],
        },
        showPartialFoundModal: false,
      }));
    }

    // Update the quantity of the item in the current tab
    const updatedCurrentTabItems = currentTabItems.map(item => {
      if (item.fournitureName === modalItemName) {
        const remainingQuantity = item.quantity - modalItemQuantity;
        if (remainingQuantity > 0) {
          return {
            ...item,
            quantity: remainingQuantity,
          };
        }
        return null; // Remove the item from the current tab
      }
      return item;
    })
      .filter(Boolean);

    this.setState(prevState => ({
      tabs: {
        ...prevState.tabs,
        [currentTab]: updatedCurrentTabItems,
      },
    }));
  };


  handleOptionDownloadClick = () => {
    // Simulating HTTP request to download a file
    // Replace with your actual download code
    console.log('Downloading file...');
  };
  printRecap = (printWindow) => {
    console.log("inside printRecap");

    ReactDOM.render(<LibraryOrderPrintComponent recap={this.state.recap} />, printWindow.document.body);
    printWindow.document.title = "Print Items";

    // Delay the print command slightly to ensure the content has rendered
    printWindow.setTimeout(() => {
      printWindow.print();
      // printWindow.close();
    }, 250); // Adjust delay as necessary
  };


  handleOptionDoneClick = () => {
    const { libraryOrderId, tabs } = this.state;
    // Check if the original tab has items
    if (tabs.original.length > 0) {
      // Show the error message
      alert('Original contient encore des articles');
      return; // Exit the function to avoid proceeding with the request
    }
    const payload = {
      libraryOrderId,
      libraryOrderItemsFound: tabs.found,
      libraryOrderItemsNotFound: tabs.notFound,
      libraryOrderItemsUnknown: tabs.unknown,
    };


    this.setState({ loading: true });
    this.axiosInstance.post('/libraryOrders/processLibraryOrder', payload)
      .then(response => {

        const { livrairieLibrary, recap } = response.data;
        if (livrairieLibrary) {
          this.setState({
            isDoneClicked: true,
            recap: recap,
            showPrintRecapButton: true,
            loading: false,
          });

        } else {
          this.setState({
            rassembleurIsFree: true,
            selectedItems: [],
            currentTab: 'original',
            tabs: {
              original: [],
              found: [],
              notFound: [],
              unknown: [],
            },
            libraryOrderId: null,
            showPartialFoundModal: false,
            modalItemName: '',
            modalItemLevel: '',
            modalItemType: '',
            modalItemQuantity: 0,
            modalItemMaxQuantity: 0,
            loading: false,
          });
        }


      })
      .catch(error => {
        console.error("Error processing order: ", error);
        this.setState({ loading: false });
      });
  };
  handlePrintRecap = () => {
    const printWindow = window.open('', '_blank');
    if (!printWindow) {
      alert("Please allow popups for this site.");
      return;
    }
    printWindow.document.write("<h2>Loading...</h2>");
    console.log("IS LIVRAIRIE")
    this.printRecap(printWindow);
  }

  handleFinishClick = () => {
    this.setState({
      rassembleurIsFree: true,
      selectedItems: [],
      currentTab: 'original',
      tabs: {
        original: [],
        found: [],
        notFound: [],
        unknown: [],
      },
      libraryOrderId: null,
      showPartialFoundModal: false,
      modalItemName: '',
      modalItemLevel: '',
      modalItemType: '',
      modalItemQuantity: 0,
      modalItemMaxQuantity: 0,
      isDoneClicked: false,
      recap: null,
      showPrintRecapButton: false,
      loading: false,
    });
  }

  handleSelectAllCheckboxChange = () => {
    const { selectedItems, tabs, currentTab } = this.state;
    let newSelectedItems;

    if (selectedItems.length < tabs[currentTab].length) {
      // If not all items are selected, select all
      newSelectedItems = tabs[currentTab].map(item => item.studentOrderItemId);
    } else {
      // If all items are already selected, deselect all
      newSelectedItems = [];
    }

    this.setState({ selectedItems: newSelectedItems });
  }

  renderToolbar() {
    const { currentTab } = this.state;

    const tabs = ['original', 'found', 'notFound', 'unknown', 'option'];

    return (


      <ul className={classes.ScrollableStudentsMenu}>
        {tabs.map((tab, index) => (
          <li
            key={tab}
            className={`${classes.ScrollableStudentsMenuItem} ${currentTab === tab ? classes.ActiveStudentItem : ""}`}
            onClick={() => this.setState({ currentTab: tab })}
          >
            {tab === 'option' ? <FontAwesomeIcon icon={faCog} /> : tab}
          </li>
        ))}
      </ul>
    );
  }

  renderSelectedItems() {
    const { selectedItems, tabs, currentTab } = this.state;

    if (currentTab === "option") {
      return null;
    }

    // Determine if the "Select All" checkbox should be checked
    const isAllSelected = tabs[currentTab].length === selectedItems.length;

    return (
      <div>
        <input
          type="checkbox"
          checked={isAllSelected}
          onChange={this.handleSelectAllCheckboxChange}
        />
        <label>Selected items: {selectedItems.length}</label>
        <button className={classes.NeutreButton}
          onClick={this.handleMoveButtonClick}
          disabled={selectedItems.length === 0}>
          Move</button>
      </div>
    );
  }


  // Function to get the appropriate icon based on the 'gamme' value
  getIconForGamme(gamme) {
    switch (gamme) {
      case 'premium':
        return <FontAwesomeIcon icon={faStar} />;

      case 'classique':
        return <FontAwesomeIcon icon={faStarHalfAlt} />;
      case 'economique':
        return <FontAwesomeIcon icon={faStarRegular} />;
      default:
        return null;
    }
  }
  getIconForGammePDF = (gamme) => {
    switch(gamme) {
        case 'economique':
            return '\uf006'; // Empty star icon
        case 'classique':
            return '\uf123'; // Half star icon
        case 'premium':
            return '\uf005'; // Full star icon
        default:
            return ''; // Question mark icon (for unknown values)
    }
};
downloadPdf = () => {
  const { tabs, libraryOrderId } = this.state;
  const items = tabs["original"];
  const doc = new jsPDF({
      unit: 'pt',
      format: 'a4',
      lang: 'ar'
  });

  doc.setFont("Amiri-Regular");
  doc.text(`Library Order: ${libraryOrderId}`, 10, 30);

  const columns = ["Qte", "Article"];
  const rows = items.map(item => [
      `${item.quantity > 1 ? `${item.quantity.toString()}` : ""}`,
      `${this.getIconForGammePDF(item.gamme)} ${item.fournitureName} ${item.articleType === "Livre" ? ` - ${item.schoolLevelDesignation}` : ""}`
  ]);

  // Calculate the necessary height for each row based on the "Article" column
  const rowHeights = rows.map(row => {
      const text = row[1]; // Text in the "Article" column
      const textDimensions = doc.getTextDimensions(text, {
          maxWidth: 450 // Adjust maxWidth according to your column width
      });
      return textDimensions.h ; // Adding padding
  });

  doc.autoTable({
      head: [columns],
      body: rows,
      theme: 'grid',
      styles: { 
          fontSize: 10, 
          // cellPadding: 5,
          overflow: 'linebreak', // Enable text wrapping
          font: "Amiri-Regular",
      },
      columnStyles: {
          0: { cellWidth: 30 },  // Decrease width of the Qte column
          1: { cellWidth: 'auto' } // Adjust automatically the width of the Article column
      },
      didParseCell: (data) => {
          // Set the row height based on the pre-calculated height for the "Article" column
          if (data.section === 'body' && data.column.index === 1) {
              data.row.height = rowHeights[data.row.index];
          }
          // Clear the default text for the "Article" column
          if (data.section === 'body' && data.column.index === 1) {
              data.cell.text = '';
          }
      },
      didDrawCell: (data) => {
        // Custom rendering for Article text and icon
        if (data.section === 'body' && data.column.index === 1 && data.cell.raw) {
            const rowIndex = data.row.index;
            const item = items[rowIndex];
      
            // Ensure the item exists before accessing its properties
            if (item) {
                const gamme = item.gamme;
                const icon = this.getIconForGammePDF(gamme);
                const text = item.fournitureName + (item.articleType === "Livre" ? ` - ${item.schoolLevelDesignation}` : "");
                const cellX = data.cell.x + 5;
                const cellY = data.cell.y + data.cell.height / 2; // Center vertically in the cell
      
                // Set the correct font for the icon
                if (gamme === 'economique') {
                    doc.setFont("fa-regular-400"); // FontAwesome Regular for empty star
                } else {
                    doc.setFont("fa-solid-900"); // FontAwesome Solid for half/full star
                }
      
                // Check if the text is too large and handle accordingly
                const textDimensions = doc.getTextDimensions(text, {
                    maxWidth: data.cell.width - 20
                });
      
                // Adjust text size or split if necessary to fit within the cell
                let finalText = text;
                if (textDimensions.h > data.cell.height) {
                    finalText = doc.splitTextToSize(text, data.cell.width - 20);
                }
      
                doc.text(icon, cellX, cellY); // Adjust y position for better alignment
      
                // Draw the Arabic text next to the icon
                doc.setFont("Amiri-Regular");
                doc.text(finalText, cellX + 10, cellY, {
                    maxWidth: data.cell.width - 20 // Adjust text wrapping within cell
                });
            }
        }
      }
      
  });

  const filename = `LibOrder_${libraryOrderId}.pdf`;
  doc.save(filename);
};


  renderOrderItem(item) {
    return (
      <div key={item.studentOrderItemId}>
        <input
          type="checkbox"
          checked={this.state.selectedItems.includes(item.studentOrderItemId)}
          onChange={() => this.handleCheckboxChange(item.studentOrderItemId)}
        />
        <span onClick={() => this.handleOriginalItemClick(item)}>
          <strong>{item.quantity}</strong> -
          {item.articleType !== 'Livre' && item.gamme ? this.getIconForGamme(item.gamme) : null}
          {item.fournitureName}
          {item.articleType === "Livre" ? ` - ${item.schoolLevelDesignation}` : ""}
        </span>
      </div>
    );
  }


  renderOrderItems() {
    const { currentTab, tabs } = this.state;
    const items = tabs[currentTab];
    // Check if tabItems is not undefined before trying to map over it
    if (items) {
      return <div>{items.map((item) => this.renderOrderItem(item))}</div>;
    } else {
      return null; // or return some placeholder element, or set tabItems to an empty array before the map operation
    }
  }
  renderMoveModal() {
    const { showMoveModal, moveModalButtons } = this.state;

    return (
      showMoveModal && (
        <Modal show={showMoveModal}
          modalClosed={this.handleMoveModalCloseClick}>
          <div className={classes.MoveModalButtonsContainer}>
            {/* <h3>Select a tab to move the items:</h3> */}
            {moveModalButtons.map(tab => (
              <button
                key={tab}
                onClick={() => this.handleMoveModalTabButtonClick(tab)}
                className={classes.MoveIconButton}

              >
                {tab === 'original' && <FontAwesomeIcon icon={faHouse} className={classes.OriginalTab} />}
                {tab === 'found' && <FontAwesomeIcon icon={faThumbsUp} className={classes.ThumbUp} />}
                {tab === 'notFound' && <FontAwesomeIcon icon={faThumbsDown} className={classes.ThumbDown} />}
                {tab === 'unknown' && <FontAwesomeIcon icon={faQuestion} className={classes.Question} />}
              </button>
            ))}
          </div>
        </Modal>
      )
    );
  }
  renderPartialFoundModal() {
    const { showPartialFoundModal, modalItemName, modalItemLevel, modalItemQuantity, modalItemMaxQuantity } = this.state;

    return (
      showPartialFoundModal && modalItemMaxQuantity > 1 && (
        <Modal show={showPartialFoundModal}
          modalClosed={() => this.setState({ showPartialFoundModal: false })}>
          <div className={classes.PartialModal}>
            <div className={classes.PartialModalContent}>
              <div>
                <label className={classes.ModalItemName}>{modalItemName}</label>
                <div className={classes.QuantityContainer}>
                  <button
                    className={classes.DicreaseQuantityButton}
                    onClick={() => this.handleModalQuantityChange(modalItemQuantity - 1)}
                    disabled={modalItemQuantity <= 1}
                  >
                    -
                  </button>
                  <span className={classes.PartialModalQuantity}>{modalItemQuantity}</span>
                  <button
                    className={classes.IncreaseQuantityButton}
                    onClick={() => this.handleModalQuantityChange(modalItemQuantity + 1)}
                    disabled={modalItemQuantity >= modalItemMaxQuantity}
                  >
                    +
                  </button>
                </div>
              </div>
              <button onClick={this.handlePartialFoundModalFoundClick}>
                <FontAwesomeIcon icon={faThumbsUp} className={classes.ThumbUp} />
              </button>
            </div>
          </div>
        </Modal>
      )
    );
  }

  renderOptionTab() {
    return (
      <div className="option-tab">
        <div className="library-order-id">
          ID: {this.state.libraryOrderId}
        </div>
        {!this.state.isDoneClicked && (<button className={classes.ValidateButton} disabled={this.state.isDoneClicked} onClick={this.handleOptionDoneClick}>Done</button>)
        }
        {!this.state.isDoneClicked && (<button className={classes.DownloadButton} disabled={this.state.isDoneClicked} onClick={this.downloadPdf}>download PDF</button>)
        }

        {this.state.showPrintRecapButton && (<button
          className={classes.FinishButton}
          onClick={this.handlePrintRecap}
        >
          Print Recap
        </button>)
        }
      {this.state.isDoneClicked && (<button
          className={classes.FinishButton}
          onClick={this.handleFinishClick}
          disabled={!this.state.isDoneClicked}
        >
          Finish
        </button>)}
        
      </div>
    );
  }

  render() {
    const { rassembleurIsFree } = this.state;

    return (
      <div>
        {this.state.loading && <Spinner />}
        {!this.state.loading && (
          <>
            {rassembleurIsFree ? (
              <div className={classes.GoContainer}>
                <button className={classes.GoButton} onClick={this.handleFetchClick}>GO</button>
                {this.state.fetchAttempted && !this.state.hasArticles && <div>... Réessayer dans 5 secondes</div>}
              </div>
            ) : (
              <div className={classes.OrderProcessing}>
                {this.renderToolbar()}
                {this.renderSelectedItems()}
                {this.renderOrderItems()}
                {this.state.currentTab === 'option' && this.renderOptionTab()}
                {this.renderPartialFoundModal()}
              </div>
            )}
            {this.renderMoveModal()}
          </>
        )}
      </div>
    );

  }
}

export default OrderProcessing;




