// Import necessary modules
import React, { Component } from 'react';
import axios from 'axios';
import styles from './TakeLoan.module.css'; // Import CSS module
import getAxiosInstance from '../../axios-clicks';
import Spinner from '../../components/UI/Spinner/Spinner';

class TakeLoan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lenders: [],
            destinationAccounts: [],
            selectedLender: '',
            selectedDestinationAccount: '',
            amount: '',
            isFormValid: false,
            successMessage: '',
            errorMessage: '',
            showForm: false,
            loading: false,
        };
        this.axiosInstance = getAxiosInstance(this.props.sandbox);
    }

    fetchOptions = () => {
        this.setState({ loading: true });
        axios.all([
            this.axiosInstance.get('/finances/persons'),
            this.axiosInstance.get('/finances/tresorerie-accounts')
        ])
            .then(axios.spread((lendersRes, destinationAccountsRes) => {
                const transformedLenders = lendersRes.data.map(lender => ({
                    id: lender.id, // Assuming lender object has an id field
                    name: lender.name // Assuming lender object has a name field
                }));
                const transformedDestinationAccounts = destinationAccountsRes.data.map((name, index) => ({
                    id: index+1, // or any other unique identifier logic
                    name: name
                }));
                this.setState({
                    lenders: transformedLenders,
                    destinationAccounts: transformedDestinationAccounts,
                    showForm: true,
                    loading: false,
                });
            }))
            .catch(error => {
                const errorMessage = error.response && error.response.data ? error.response.data : 'Error fetching options.';
                this.setState({ errorMessage: errorMessage, successMessage: '', loading: false });
            });
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value }, this.validateForm);
    }

    validateForm = () => {
        const { selectedLender, selectedDestinationAccount,  amount } = this.state;
        const isAmountValid = parseFloat(amount) > 0;
        this.setState({
            isFormValid: selectedLender && selectedDestinationAccount && isAmountValid
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({ loading: true });
        const { selectedLender, selectedDestinationAccount, amount } = this.state;
        const data = {
            lender: selectedLender,
            destinationAccount: selectedDestinationAccount,
            amount: parseFloat(amount),
        };
        console.log(data);
        this.axiosInstance.post('/finances/record-loan', data)
            .then(response => {
                this.setState({ successMessage: 'Expense registered successfully!', errorMessage: '', loading: false });
            })
            .catch(error => {
                const errorMessage = error.response && error.response.data ? error.response.data : 'Error registering expense.';
                this.setState({ errorMessage: errorMessage, successMessage: '', loading: false });
            });
    }

    render() {
        const { lenders, destinationAccounts, isFormValid, successMessage, errorMessage, showForm } = this.state;
        console.log(lenders);
        return (
            <div className={styles.expenseForm}>
                {this.state.loading && <Spinner />}
                {!this.state.loading && (
                    <>
                        <h1>Register New Loan</h1>
                        <button onClick={this.fetchOptions}>New Loan</button>
                        {showForm && (
                            <form onSubmit={this.handleSubmit}>
                                <select name="selectedLender" onChange={this.handleChange} value={this.state.selectedLender}>
                                    <option value="">Select Lender</option>
                                    {lenders.map((lender, index) => <option key={index} value={lender.name}>{lender.name}</option>)}
                                </select>
                                <select name="selectedDestinationAccount" onChange={this.handleChange} value={this.state.selectedDestinationAccount}>
                                    <option value="">Select Destination Account</option>
                                    {destinationAccounts.map((account, index) => <option key={index} value={account.name}>{account.name}</option>)}
                                </select>
                                <input type="number" name="amount" placeholder="Amount" step="0.01" onChange={this.handleChange} />
                                <button type="submit" disabled={!isFormValid}>Done</button>
                            </form>
                        )}
                        {successMessage && <p className={styles.success}>{successMessage}</p>}
                        {errorMessage && <p className={styles.error}>{errorMessage}</p>}
                    </>
                )}
            </div>
        );
    }
}

export default TakeLoan;
