import React, { Component } from 'react';
import getAxiosInstance from '../../axios-clicks';
import Modal from '../../components/UI/Modal/Modal';
import Button from '../../components/UI/Button/Button';
import styles from './LibraryGammeCrud.module.css';
import Spinner from '../../components/UI/Spinner/Spinner';
import { extractRequestErrorMessage } from '../../components/Utils/ExtractRequestErrorMessage';

class LibraryGammeCrud extends Component {
    constructor(props) {
        super(props);
        this.state = {
            libraryGammes: [],
            libraries: [],
            showCreateModal: false,
            showDeleteModal: false,
            currentLibraryGamme: { libraryId: '', libraryName: '', gamme: '' },
            loading: false,
        };
        this.axiosInstance = getAxiosInstance(this.props.sandbox);
    }

    componentDidMount() {
        this.fetchLibraryGammes();
        this.fetchLibraries();
    }

    fetchLibraryGammes = () => {
        this.setState({ loading: true });
        this.axiosInstance.get('/libraryGamme')
            .then(response => {
                this.setState({ libraryGammes: response.data, loading: false });
            })
            .catch(error => {
                alert('Failed to fetch library gammes');
                this.setState({ loading: false });
            });
    };

    fetchLibraries = () => {
        this.axiosInstance.get('/library')
            .then(response => {
                this.setState({ libraries: response.data });
            })
            .catch(error => {
                alert('Failed to fetch libraries');
            });
    };

    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState(prevState => ({
            currentLibraryGamme: { ...prevState.currentLibraryGamme, [name]: value }
        }));
    };

    handleSubmit = (event) => {
        event.preventDefault();
        const { libraryId, gamme } = this.state.currentLibraryGamme;
        if (!libraryId || !gamme) {
            alert('Please select both library and gamme.');
            return;
        }

        const library = this.state.libraries.find(lib => lib.id === libraryId);
        const newLibraryGamme = {
            ...this.state.currentLibraryGamme,
            libraryName: library ? library.name : ''
        };

        this.setState({ loading: true });
        this.axiosInstance.post('/libraryGamme', newLibraryGamme)
            .then(this.fetchLibraryGammes)
            .catch(error => {
                alert('Failed to save library gamme');
                this.setState({ loading: false });
            });
        this.closeModal();
    };

    openModal = (type, libraryGamme = { libraryId: '', libraryName: '', gamme: '' }) => {
        this.setState({
            [type]: true,
            currentLibraryGamme: { ...libraryGamme }
        });
    };

    closeModal = () => {
        this.setState({
            showCreateModal: false,
            showDeleteModal: false
        });
    };

    deleteLibraryGamme = () => {
        const { libraryId, gamme } = this.state.currentLibraryGamme;
        this.setState({ loading: true });
        this.axiosInstance.delete(`/libraryGamme/${libraryId}/${gamme}`)
            .then(this.fetchLibraryGammes)
            .catch(error => {
                alert('Failed to delete libraryGamme');
                this.setState({ loading: false });
            });
        this.closeModal();
    };

    renderCreateModal = () => {
        const { showCreateModal, currentLibraryGamme, libraries } = this.state;
        return (
            <Modal show={showCreateModal} modalClosed={this.closeModal}>
                <div>
                    <div>
                        <label htmlFor="libraryId">Library:</label>
                        <select
                            id="libraryId"
                            name="libraryId"
                            value={currentLibraryGamme.libraryId}
                            onChange={this.handleInputChange}
                            required
                        >
                            <option value="">Select Library</option>
                            {libraries.map(library => (
                                <option key={library.id} value={library.id}>{library.name}</option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label htmlFor="gamme">Gamme:</label>
                        <select
                            id="gamme"
                            name="gamme"
                            value={currentLibraryGamme.gamme}
                            onChange={this.handleInputChange}
                            required
                        >
                            <option value="">Select Gamme</option>
                            <option value="economique">Économique</option>
                            <option value="classique">Classique</option>
                            <option value="premium">Premium</option>
                        </select>
                    </div>
                    <div className={styles.modalButtonContainer}>
                        <Button btnType="Success" clicked={this.handleSubmit}>Add LibraryGamme</Button>
                        <Button btnType="Danger" clicked={this.closeModal}>Cancel</Button>
                    </div>
                </div>
            </Modal>
        );
    };

    renderDeleteModal = () => {
        const { showDeleteModal, currentLibraryGamme } = this.state;
        return (
            <Modal show={showDeleteModal} modalClosed={this.closeModal}>
                <div>
                    <p>Are you sure you want to delete the libraryGamme "{currentLibraryGamme.libraryName}"?</p>
                    <div className={styles.modalButtonContainer}>
                        <Button btnType="Success" clicked={this.deleteLibraryGamme}>Delete</Button>
                        <Button btnType="Danger" clicked={this.closeModal}>Cancel</Button>
                    </div>
                </div>
            </Modal>
        );
    };

    render() {
        const { libraryGammes, loading } = this.state;
        if (loading) {
            return <Spinner />;
        }
        return (
            <div>
                <button className={`${styles.button} ${styles.newButton}`} onClick={() => this.openModal('showCreateModal')}>New LibraryGamme</button>
                <table className={styles.libraryTable}>
                    <thead>
                        <tr>
                            <th>Library ID</th>
                            <th>Library Name</th>
                            <th>Gamme</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {libraryGammes.map(libraryGamme => (
                            <tr key={libraryGamme.libraryId + libraryGamme.gamme}>
                                <td>{libraryGamme.libraryId}</td>
                                <td>{libraryGamme.libraryName}</td>
                                <td>{libraryGamme.gamme}</td>
                                <td className={styles.buttonContainer}>
                                    <button className={`${styles.button} ${styles.deleteButton}`} onClick={() => this.openModal('showDeleteModal', libraryGamme)}>
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {this.renderCreateModal()}
                {this.renderDeleteModal()}
            </div>
        );
    }
}

export default LibraryGammeCrud;
