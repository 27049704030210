// RecoltedOrders.js
import React, { Component } from 'react';
import getAxiosInstance from '../../axios-clicks';
import classes from './RecoltedOrders.module.css'; // Assuming CSS module is named RecoltedOrders.module.css
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import Spinner from '../../components/UI/Spinner/Spinner';

class RecoltedOrders extends Component {
    constructor(props) {
        super(props);
        this.state = {
        recoltes: [],
        selectedRecolteId: null, // Track the id of the recolte whose details are being viewed

    };this.axiosInstance = getAxiosInstance(this.props.sandbox);
}

    componentDidMount() {
        this.fetchRecoltes();
    }

    fetchRecoltes = () => {
        const { userId } = this.props; // Assume userId is passed as a prop to this component
        this.setState({ loading: true });
        this.axiosInstance.get(`/recolte/recoltes/${userId}`).then(response => {
            console.log(response.data);
            this.setState({ recoltes: response.data , loading: false});
        }).catch(error => {
            this.setState({ loading: false });
            console.error("Error fetching recoltes:", error);
        });
    };

    handleViewDetails = (recolteId) => {
        this.setState(prevState => ({
            selectedRecolteId: prevState.selectedRecolteId === recolteId ? null : recolteId
        }));
    };

    handleUnrecolt = (libraryOrderId) => {
        this.setState({ loading: true });
        this.axiosInstance.get(`/recolte/unrecoltOrder/${libraryOrderId}`).then(() => {
            this.setState(prevState => ({
                recoltes: prevState.recoltes.map(recolte => {
                    if (recolte.recolteId === prevState.selectedRecolteId) {
                        return {
                            ...recolte,
                            recoltedOrders: recolte.recoltedOrders.filter(order => order.libraryOrderId !== libraryOrderId),
                        };
                    }
                    return recolte;
                }),
                loading: false,
            }));
        }).catch(error => {
            this.setState({ loading: false });
            console.error("Error unrecolting order:", error);
        });
    };

    renderRecoltes() {
        const { recoltes, selectedRecolteId } = this.state;
        return recoltes.map(recolte => (
            <div key={recolte.recolteId} className={`${classes.Recolte} ${classes[recolte.status]}`}>
                <span>{recolte.date} - RECOLTE: {recolte.recolteId}</span>
                <FontAwesomeIcon icon={faEye} className={classes.faEye} onClick={() => this.handleViewDetails(recolte.recolteId)} title="View Details" />
                {selectedRecolteId === recolte.recolteId && this.renderRecolteDetails(recolte)}
            </div>
        ));
    }

    renderRecolteDetails(recolte) {
        const groupedByLibrary = recolte.recoltedOrders.reduce((acc, order) => {
            acc[order.libraryName] = [...(acc[order.libraryName] || []), order];
            return acc;
        }, {});

        return (
            <div className={classes.RecolteDetails}>
                {Object.entries(groupedByLibrary).map(([libraryName, orders]) => (
                    <div key={libraryName}>
                        <strong>{libraryName}</strong>
                        {orders.map(order => (
                            <div key={order.libraryOrderId}>
                                <span className={classes.OrderId}>
                                    {order.libraryOrderId}
                                </span>
                                {recolte.status === "New" && <button className={classes.UnrecoltButton} onClick={() => this.handleUnrecolt(order.libraryOrderId)}>Unrecolt</button>}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        );
    }

    render() {
        return (
            <div>
                {this.state.loading && <Spinner />}
        {!this.state.loading && (
          <>
            {this.renderRecoltes()}
          </>
        )}
                
            </div>
        );
    }
}

export default RecoltedOrders;
