import React, { Component } from 'react';
import classes from './SuiviItems.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faHouse, faThumbsUp, faThumbsDown, faQuestion } from '@fortawesome/free-solid-svg-icons';
import Modal from '../../components/UI/Modal/Modal';
import getAxiosInstance from '../../axios-clicks';
import Spinner from '../../components/UI/Spinner/Spinner';
import Button from '../../components/UI/Button/Button';
import _ from 'lodash';
import { extractRequestErrorMessage } from '../../components/Utils/ExtractRequestErrorMessage';

// Mapping DTO attributes to desired display names
const columnMappings = {
  id: "soiiId",
  idStudentOrder: "soID",
  fourniture: "Fourniture",
  price: "Price",
  status: "status",
  idArticle: "idArticle",
  creationDate: "date",
  lastStatusChangeDate: "lcDate",
  librariesCount: "LibCt",
  gamme: "Gamme",
};
class SuiviItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      sortKey: 'id', // default sort column
      sortOrder: 'asc', // Sort order: 'asc' or 'desc'
      selectedItems: [], // IDs of selected items
      // filteredItems: [], // Items after applying filter
      filters: {}, // Filters for each column
      page: 1, // current page
      pageSize: 200, // number of items per page
      totalPages: 0, // total number of pages
      showModal: false,
      showModifyModal: false,
      modifyingItem: null, // holds the item currently being modified
      initialModifyingItem: null, // keep copy of the item being modified to check if it was really modified before sending http request
      errorItems: [],
      showErrorModal: false,
    };
    this.axiosInstance = getAxiosInstance(this.props.sandbox);

    // Debounce the fetchData method so that when a user writes something in the filters, it does not get sent until he stops writing
    this.debouncedFetchData = _.debounce(this.fetchData, 500); // 500ms delay
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    const { page, pageSize, filters, sortKey, sortOrder } = this.state;
    // Constructing filter queries using the keys from the columnMappings
    const filterQueries = Object.entries(filters)
      .filter(([key]) => columnMappings[key]) // Ensure only valid keys are included
      .map(([key, value]) => `filter_${key}=${encodeURIComponent(value)}`)
      .join('&');
    const queryString = `/studentorderindividualitem/getValidatedStudentOrderIndividualItems?page=${page}&size=${pageSize}&sortColumn=${sortKey}&sortOrder=${sortOrder}&${filterQueries}`;


    this.axiosInstance.get(queryString)
      .then(response => {
        // Check if the current page is empty and adjust the page number accordingly
        if (response.data.items.length === 0 && this.state.page > 1) {
          this.setState({ page: this.state.page - 1 });
        }
        // Update state with fetched data and total pages
        this.setState({
          items: response.data.items,
          totalPages: response.data.totalPages,
          showModal: false,
          showModifyModal: false,
          modifyingItem: null, // holds the item currently being modified
          initialModifyingItem: null,
          selectedItems: []
          // other necessary state updates
        });
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };


  handlePageChange = (newPage) => {
    this.setState({ page: newPage , selectedItems: []}, this.fetchData);
  };


  renderPagination() {
    const pageNumbers = Array.from({ length: this.state.totalPages }, (_, i) => i + 1);
    return (
      <div>
        {pageNumbers.map(number => (
          <button key={number} onClick={() => this.handlePageChange(number)}>
            {number}
          </button>
        ))}
      </div>
    );
  }

  // Event Handlers: selectItem, sortColumn, filterItems, deleteItems, modifyItem, markItems

  handleDelete = () => {
    if (window.confirm('Are you sure you want to delete selected items?')) {
      const selectedItemsData = this.state.items
        .filter(item => this.state.selectedItems.includes(item.id))
        .map(({ id, status }) => ({ id, status }));

      this.axiosInstance.post('/studentorderindividualitem/deleteItems', selectedItemsData)
        .then(response => {
          if (response.status === 200) {
            alert('Items were successfully deleted.');
            this.fetchData(); // Refresh the data

          } else {
            alert(`Unexpected response status: ${response.status}`);
          }
        })
        .catch(error => {
          // Handle network or other axios errors
          const errorMessage = extractRequestErrorMessage(error);
          alert(errorMessage);
        });
    }
  };

  handleMoveFullfilmentToStock= () => {
    if (window.confirm('Are you sure you want to move selected items to stock?')) {
      const selectedItemsData = this.state.items
        .filter(item => this.state.selectedItems.includes(item.id));

      this.axiosInstance.post('/studentorderindividualitem/moveItemsFromFullfilmentToStock', selectedItemsData)
        .then(response => {
          if (response.status === 200) {
            alert('Items were successfully moved.');
            this.fetchData(); // Refresh the data

          } else {
            alert(`Unexpected response status: ${response.status}`);
          }
        })
        .catch(error => {
          // Handle network or other axios errors
          const errorMessage = extractRequestErrorMessage(error);
          alert(errorMessage);
        });
    }
  };


  handleModify = () => {
    if (this.state.selectedItems.length === 1) {
      console.log("Modify");
      const modifyingItem = this.state.items.find(item => item.id === this.state.selectedItems[0]);
      this.setState({ modifyingItem, initialModifyingItem: { ...modifyingItem }, showModifyModal: true });
    } else {
      alert('Please select exactly one item to modify.');
    }
  };

  handleModificationSubmit = () => {
    // Assuming modifyingItem contains the latest user-inputted data
    this.axiosInstance.post('/studentorderindividualitem/modifyItem', this.state.modifyingItem)
      .then(response => {
        if (response.status === 200) {
          alert('Modifications were successful.');
          this.fetchData();
        } else {
          alert(`Unexpected response status: ${response.status}`);
        }
      })
      .catch(error => {
        const errorMessage = extractRequestErrorMessage(error);
        alert(errorMessage);
      });
  };

  renderModificationModal() {
    if (!this.state.showModifyModal) return null;
    // Convert string values to numbers for accurate comparison
    const initialPrice = parseFloat(this.state.initialModifyingItem.price);
    const currentPrice = parseFloat(this.state.modifyingItem.price);
    const isCurrentPriceNumber = !isNaN(currentPrice);

    // Determine if the current state of modifyingItem has changed from its initial state
    // And ensure the current price is a valid number
    const hasChanged = (this.state.modifyingItem.fourniture !== this.state.initialModifyingItem.fourniture ||
      currentPrice !== initialPrice || this.state.modifyingItem.gamme !== this.state.initialModifyingItem.gamme) && isCurrentPriceNumber;

    return (
      <Modal show={this.state.showModifyModal} modalClosed={() => this.setState({ showModifyModal: false })}>
        <div className={classes.ModifyformContainer}>
          <label className={classes.Modifylabel}>Name:</label>
          <input
            type="text"
            className={classes.Modifyinput}
            value={this.state.modifyingItem.fourniture}
            onChange={(e) => this.setState({ modifyingItem: { ...this.state.modifyingItem, fourniture: e.target.value } })}
          />
          <label className={classes.Modifylabel}>Price:</label>
          <input
            type="text"
            className={classes.Modifyinput}
            value={this.state.modifyingItem.price}
            onChange={(e) => this.setState({ modifyingItem: { ...this.state.modifyingItem, price: e.target.value } })}
          />
          <label className={classes.Modifylabel}>Gamme:</label>
          <select
            className={classes.Modifyinput}
            value={this.state.modifyingItem.gamme}
            onChange={(e) => this.setState({ modifyingItem: { ...this.state.modifyingItem, gamme: e.target.value } })}
          >
            <option value="economique">Economique</option>
            <option value="classique">Classique</option>
            <option value="premium">Premium</option>
          </select>
        </div>
        <div className="ModalButton">
          <Button btnType="Success"
            disabled={!hasChanged}
            clicked={this.handleModificationSubmit}>OK</Button>
        </div>
      </Modal>
    );
  }

  handleDuplicate = () => {
    if (this.state.selectedItems.length === 1) {
      const selectedItemId = this.state.selectedItems[0];

      this.axiosInstance.post('/studentorderindividualitem/duplicate', { id: selectedItemId })
        .then(response => {
          if (response.status === 200) {
            alert('Dupplication was successful.');
            this.fetchData();
          } else {
            alert(`Unexpected response status: ${response.status}`);
          }
        })
        .catch(error => {
          const errorMessage = extractRequestErrorMessage(error);
          alert(errorMessage);
        });
    } else {
      alert("Please select exactly one item to duplicate.");
    }
  };
  handleMark = () => {
    if (window.confirm('Are you sure you want to mark selected items?')) {
      const selectedItemsData = this.state.items
        .filter(item => this.state.selectedItems.includes(item.id))
        .map(({ id, status }) => ({ id, status }));
        const selectedItemData = selectedItemsData[0];

      this.axiosInstance.post('/studentorderindividualitem/markItem', selectedItemData)
        .then(response => {
          if (response.status === 200) {
            alert('Mark were successful.');
            this.fetchData();
          } else {
            alert(`Unexpected response status: ${response.status}`);
        }
        })
        .catch(error => {
          const errorMessage = extractRequestErrorMessage(error);
          alert(errorMessage);
        });
    }
  };

  handleRefresh = () => {
    if (window.confirm('Are you sure you want to refresh selected items?')) {
      const selectedItemsData = this.state.items
        .filter(item => this.state.selectedItems.includes(item.id))
        .map(({ id, status }) => ({ id, status }));
        const selectedItemData = selectedItemsData[0];
      this.axiosInstance.post('/studentorderindividualitem/refreshItem', selectedItemData)
        .then(response => {
          if (response.status === 200) {
            alert('Refresh were successful.');
            this.fetchData();
          } else {
            alert(`Unexpected response status: ${response.status}`);
        }
        })
        .catch(error => {
          const errorMessage = extractRequestErrorMessage(error);
          alert(errorMessage);
        });
    }
  };

  openFileTab = (fileList) => {
    const newTab = window.open();
    if (!newTab) {
      alert('Popup blocked! Please allow popups for this website.');
      return;
    }

    newTab.document.write('<html><head><title>New Tab</title></head><body>');

    fileList.forEach(file => {
      const binaryStr = atob(file.fileData); // Decode base64 for binary content
      const len = binaryStr.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryStr.charCodeAt(i);
      }

      let contentType = '';
      if (file.fileName.endsWith('.pdf')) {
        contentType = 'application/pdf';
      } else if (file.fileName.endsWith('.jpg') || file.fileName.endsWith('.jpeg')) {
        contentType = 'image/jpeg';
      } else if (file.fileName.endsWith('.png')) {
        contentType = 'image/png';
      }

      const blob = new Blob([bytes], { type: contentType });
      const url = URL.createObjectURL(blob);

      if (contentType === 'application/pdf') {
        newTab.document.write(`<iframe src="${url}" width="100%" height="800px" type="${contentType}"></iframe>`);
      } else if (contentType === 'image/jpeg' || contentType === 'image/png') {
        newTab.document.write(`<img src="${url}" alt="File Image" style="max-width: 100%; height: auto;"/>`);
      }
    });

    newTab.document.write('</body></html>');
    newTab.document.close();
  };


  handleShowFiles = () => {
    if (this.state.selectedItems.length === 1) {
      const selectedItemId = this.state.selectedItems[0];
      const selectedItem = this.state.items.find(item => item.id === selectedItemId);

      if (selectedItem) {
        console.log("kayn selected item:", selectedItem);
        this.axiosInstance.get(`/files/getFilesByStudentOrderId/${selectedItem.idStudentOrder}`)
          .then(response => {
            if (response.status === 200) {
              const fileList = response.data;
              this.openFileTab(fileList);
            } else {
              alert("No files found for this item.");
            }
          })
          .catch(error => {
            console.error("Error fetching files:", error);
            alert("Error fetching files. Please try again.");
          });
      }
    } else {
      alert("Please select exactly one item to show files.");
    }
  };


  errorModalCancelHandler = () => {
    // Logic to hide the error modal and potentially clear error states
    this.setState({ showErrorModal: false, errorItems: [] });
  };

  renderButtons() {
    const { selectedItems } = this.state;
    const isOneSelected = selectedItems.length === 1;
    const isAnySelected = selectedItems.length > 0;

    return (
      <div>
        <button onClick={this.handleDelete} disabled={!isAnySelected}>Delete</button>
        <button onClick={this.handleModify} disabled={!isOneSelected}>Modify</button>
        <button onClick={this.handleMark} disabled={!isOneSelected}>Not Found</button>
        <button onClick={this.handleRefresh} disabled={!isOneSelected}>Refresh</button>
        <button onClick={this.handleShowFiles} disabled={!isOneSelected}>Show Files</button>
        <button onClick={this.handleDuplicate} disabled={!isOneSelected}>Duplicate</button>
        <button onClick={this.handleMoveFullfilmentToStock} disabled={!isAnySelected}>FfmntToStock</button>
        
      </div>
    );
  }

  renderTable() {
    const { items, sortKey, sortOrder, selectedItems } = this.state;
    // const { filteredItems, sortKey, sortOrder, selectedItems } = this.state;

    const handleSortChange = (key) => {
      const newOrder = this.state.sortKey === key && this.state.sortOrder === 'asc' ? 'desc' : 'asc';
      this.setState({
        sortKey: key,
        sortOrder: newOrder,
        page: 1, // Reset to first page after sorting change
      }, this.fetchData);
    };



    const handleFilterChange = (key, value) => {
      this.setState(prevState => {
        // Copy the current filters
        const updatedFilters = { ...prevState.filters };

        if (value) {
          // Set or update the value for the key
          updatedFilters[key] = value;
        } else {
          // If the value is empty, delete the key from the filters
          delete updatedFilters[key];
        }

        // Return the updated state
        return {
          filters: updatedFilters,
          page: 1, // Reset to first page after filter change
          selectedItems: []
        };
      }, this.debouncedFetchData); // Calling the debounced version of fetchData
    };


    const handleSelectItem = (itemId) => {
      const { selectedItems } = this.state;
      const index = selectedItems.indexOf(itemId);
      if (index > -1) {
        // Item is already selected, remove it from the array
        this.setState({ selectedItems: selectedItems.filter(id => id !== itemId) });
      } else {
        // Item is not selected, add it to the array
        this.setState({ selectedItems: [...selectedItems, itemId] });
      }
    };
    const handleSelectAll = (e) => {
      if (e.target.checked) {
        // Select all items
        this.setState({ selectedItems: items.map(item => item.id) });
      } else {
        // Deselect all items
        this.setState({ selectedItems: [] });
      }
    };

    const isAllSelected = selectedItems.length === items.length && selectedItems.length > 0;



    return (
      <table className={classes.table}>
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                onChange={handleSelectAll}
                checked={isAllSelected}
              />
            </th>
            {Object.entries(columnMappings).map(([key, displayName]) => (
              <th key={key}>
                <div onClick={() => handleSortChange(key)}>
                  {displayName} {sortKey === key ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
                </div>
                <input
                  type="text"
                  placeholder={`Filter by ${displayName}`}
                  onChange={(e) => handleFilterChange(key, e.target.value)}
                />
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {items && items.length > 0 && items.map(item => (
            <tr key={item.id}>
              <td><input type="checkbox" checked={selectedItems.includes(item.id)} onChange={() => handleSelectItem(item.id)} /></td>
              {/* Dynamically create table data cells based on columnMappings */}
              {Object.keys(columnMappings).map((key, index) => (
                <td key={index}>{item[key]}</td> // Accessing each property of item using key
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  renderModal() {
    if (!this.state.showErrorModal) {
      return null; // Don't render the modal if it's not needed
    }

    // Prepare content for the error modal
    const errorContent = this.state.errorItems.map((item, index) => (
      <p key={index} style={{ color: 'red' }}>Item with ID {item.id} has been changed to status: {item.newStatus}</p>
    ));

    return (
      <Modal show={this.state.showErrorModal} modalClosed={this.errorModalCancelHandler}>
        <div>
          {/* Displaying individual error messages for each changed item */}
          {errorContent.length > 0 ? errorContent : <p>No items have been changed.</p>}
        </div>
        <div className="ModalButton"> {/* Replace with your actual class name if needed */}
          <Button btnType="Danger" clicked={this.errorModalCancelHandler}>OK</Button>
        </div>
      </Modal>
    );
  }


  render() {
    return (
      <div>
        {/* Buttons: Delete, Modify, Mark */}
        {this.renderButtons()}
        {/* Table with sorting, filtering, and selection */}
        {this.renderTable()}
        {this.renderPagination()}
        {/* Modal for editing */}
        {this.renderModificationModal()}
        {this.renderModal()}

      </div>
    );
  }
}

export default SuiviItems;