import React, { Component } from 'react';

class CartonEtiquette extends Component {
  render() {
    const { etiquetteInfo } = this.props;

    const styles = {
      infoBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '148.5mm', // Half A4 page height
        position: 'relative',
      },
      coveringCircles: {
        position: 'absolute',
        top: '20px',
        left: '50%',
        transform: 'translateX(-50%)',
        display: 'flex',
        gap: '10px', // Adjust the gap between the circles
      },
      circle: {
        width: '55px', // Outer circle size
        height: '55px',
        borderRadius: '50%',
        backgroundColor: 'black',
        position: 'relative',
      },
      innerCircle: {
        width: '45px', // Inner circle size
        height: '45px',
        borderRadius: '50%',
        backgroundColor: 'white',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      },
      boxId: {
        fontSize: '150px', // Much bigger font size
        fontWeight: 'bold', // Bold text
        textAlign: 'center',
      },
      details: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        textAlign: 'right',
        fontSize: '20px', // Small font size
      },
    };

    return (
      <div style={styles.infoBox}>
        {etiquetteInfo.coveringService && (
          <div style={styles.coveringCircles}>
            <div style={styles.circle}>
              <div style={styles.innerCircle}></div>
            </div>
            <div style={styles.circle}>
              <div style={styles.innerCircle}></div>
            </div>
          </div>
        )}
        <div style={styles.boxId}>{etiquetteInfo.cartonId}</div>
        <div style={styles.details}>
          <div>ordId: {etiquetteInfo.orderId}</div>
          <div>studentOrdId: {etiquetteInfo.studentOrderId}</div>
          <div>empl: {etiquetteInfo.emplacement}</div>
          <div>
            date:{' '}
            {new Date(etiquetteInfo.creationDate)
              .toLocaleDateString('en-US', { day: '2-digit', month: '2-digit' })
              .replace(/\/(\d{2})$/, '/$1')}
          </div>
        </div>
      </div>
    );
  }
}

export default CartonEtiquette;
