import React from 'react';
import { NavLink } from 'react-router-dom';
import classes from './NavigationItems.module.css';
import NavigationItem from './NavigationItem/NavigationItem';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
// import RassembleurSolde from '../../../containers/Rassembleur/Solde'


const navigationItems = (props) => {
    const roleBasedItems = (role, sandbox, userId) => {
        // Define items based on role
        // Example for one role, replicate logic for others
        switch (role) {
            case 'admin':
                return (
                    <React.Fragment key="AdminGroup">
                        <NavigationItem key="admin" link="/admin" userId={userId} sandbox={sandbox}>admin</NavigationItem>
                        <NavigationItem key="ordersoverview" link="/ordersoverview" userId={userId} sandbox={sandbox}>Orders Overview</NavigationItem>
                    </React.Fragment>
                );
            case 'preparator':
                return (
                    <React.Fragment key="PreparatorGroup">
                        <NavigationItem key="preparation" link="/preparator" userId={userId} sandbox={sandbox}>preparator</NavigationItem>
                        <NavigationItem key="freeetagere" link="/freeetagere" userId={userId} sandbox={sandbox}>Free Etagere</NavigationItem>
                    </React.Fragment>
                );
                
            case 'quoter':
                return (
                <React.Fragment key="QuoterGroup">
                    <NavigationItem key="quoterFourniture" link="/quoterFourniture" userId={userId} sandbox={sandbox}>Fourniture</NavigationItem>
                    <NavigationItem key="quoterLivre" link="/quoterLivre" userId={userId} sandbox={sandbox}>Livre</NavigationItem>
                </React.Fragment>
            );
            case 'etatcommande':
                return <NavigationItem key="etatcommande" link="/etatcommande" userId={userId} sandbox={sandbox}>etatcommande</NavigationItem>;
            case 'suivi':
                return <NavigationItem key="suivi" link="/suivi" userId={userId} sandbox={sandbox}>suivi</NavigationItem>;
            case 'suiviitems':
                return (
                    <React.Fragment key="SuiviOrdersGroup">
                        <NavigationItem key="suiviitems" link="/suiviitems" userId={userId} sandbox={sandbox}>suiviitems</NavigationItem>
                        <NavigationItem key="searchordeliverchoice" link="/searchordeliverchoice" userId={userId} sandbox={sandbox}>Search Or Deliver</NavigationItem>
                    </React.Fragment>
                );
            case 'ramasseur':
                return <NavigationItem key="ramasseur" link="/ramasseur" userId={userId} sandbox={sandbox}>ramasseur</NavigationItem>;
            case 'recolte_planner':
                return <NavigationItem key="recolte_planner" link="/recolte-planner" userId={userId} sandbox={sandbox}>recolte planner</NavigationItem>;
            case 'reception':
                return <NavigationItem key="reception" link="/reception" userId={userId} sandbox={sandbox}>reception</NavigationItem>;
            case 'rassembleur':
                return <NavigationItem key="rassembleur" link="/rassembleur" userId={userId} sandbox={sandbox}>rassembleur</NavigationItem>;
            case 'cartonmanager':
                return <NavigationItem key="cartonmanager" link="/cartonmanager" userId={userId} sandbox={sandbox}>carton manager</NavigationItem>;
            case 'inventorymanager':
                return <NavigationItem key="inventorymanager" link="/inventorymanager" userId={userId} sandbox={sandbox}>inventory manager</NavigationItem>;
            case 'inventoryuse':
                return <NavigationItem key="inventoryuse" link="/inventoryuse" userId={userId} sandbox={sandbox}>inventory Internal use</NavigationItem>;
            case 'financialmanager':
                return (
                    <React.Fragment key="financialmanagerGroup">
                        <NavigationItem key="financialmanager" link="/financialmanager" userId={userId} sandbox={sandbox}>financial manager</NavigationItem>
                        <NavigationItem key="financialmanagerDeliveryRemittance" link="/financialmanagerdeliveryremittance" userId={userId} sandbox={sandbox}>financial delivery remittance</NavigationItem>
                        <NavigationItem key="financialoverview" link="/financialoverview" userId={userId} sandbox={sandbox}>financial overview</NavigationItem>
                    </React.Fragment>
                );
            case 'deliverymanager':
                return (
                    <React.Fragment key="deliverymanagerGroup">
                        <NavigationItem key="deliveryRequestCollecte" link="/deliveryRequestCollecte" userId={userId} sandbox={sandbox}>Request Collecte</NavigationItem>
                        <NavigationItem key="deliveryRegisterCollection" link="/deliveryRegisterCollection" userId={userId} sandbox={sandbox}>Register Collection</NavigationItem>
                        <NavigationItem key="deliveryRegisterFailure" link="/deliveryRegisterFailure" userId={userId} sandbox={sandbox}>Register failure</NavigationItem>
                    </React.Fragment>
                );


            default:
                return null;

        }
    };

    return (
        <ul className={classes.NavigationItems}>
            {props.userRoles.map(userRole => roleBasedItems(userRole.roleName, userRole.sandbox, props.userId))}
        </ul>
    );
}

export default navigationItems;

