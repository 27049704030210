import React from 'react';
import getAxiosInstance from '../../axios-clicks';
import styles from './SalariesComponent.module.css';
import Spinner from '../../components/UI/Spinner/Spinner';

class SalariesComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            employees: [],
            selectedEmployee: null,
            sourceAccounts: [],
            selectedAccount: '',
            error: null,
            loading: false,
        }; this.axiosInstance = getAxiosInstance(this.props.sandbox);
    }


    handleManageClick = () => {

        this.setState({ loading: true });
        this.axiosInstance.get('/finances/employees?status=ACTIVE')
            .then(response => this.setState({ employees: response.data, error: null, loading: false }))
            .then(() => this.axiosInstance.get('/finances/tresorerie-accounts'))  // Fetch source accounts
            .then(response => this.setState({ sourceAccounts: response.data, loading: false }))
            .catch(error => this.setState({ error: error.message, loading: false }));
    };

    handleEmployeeSelect = (event) => {
        const selectedEmployee = this.state.employees.find(emp => emp.name === event.target.value);
        this.setState({ selectedEmployee });
    };

    handleAccountSelect = (event) => {
        this.setState({ selectedAccount: event.target.value });
    };

    calculateDueAmount = (employee) => {

        const today = new Date();
        console.log(today);
        const lastPaidDate = new Date(employee.lastPaidDate);
        console.log(lastPaidDate);
        const daysSinceLastPaid = Math.floor((today - lastPaidDate) / (1000 * 3600 * 24));
        console.log(daysSinceLastPaid);
        return daysSinceLastPaid * (employee.monthlySalary / 30);
    };



    handlePayEmployee = (id, amount) => {
        if (amount > 0) {
            if (window.confirm("Confirm advance?")) {
                this.setState({ loading: true });
                this.axiosInstance.post(`/finances/payEmployee/${id}`, { amount, sourceAccount: this.state.selectedAccount })
                    .then(() => {
                        this.setState({ loading: false, selectedEmployee: null });
                        alert("payment given successfully!")
                        this.resetState();
                    })
                    .catch(error => {
                        // Access the response data from the error object
                        this.setState({ loading: false });
                        const errorMessage = error.response && error.response.data ? error.response.data : "An unexpected error occurred";
                        alert(`Error: ${errorMessage}`);
                    });
            }
        } else {
            alert("Invalid advance amount.");
        }
    };

    handleRegisterAbsence = (id, days) => {
        if (window.confirm("Confirm registration of absence?")) {
            this.setState({ loading: true });
            this.axiosInstance.post(`/finances/registerAbsence/${id}`, JSON.stringify(days), {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(() => {
                    this.setState({ loading: false, selectedEmployee: null });
                    alert("Absence registered successfully!")
                    this.resetState();
                })
                .catch(error => {
                    // Access the response data from the error object
                    this.setState({ loading: false });
                    const errorMessage = error.response && error.response.data ? error.response.data.message : "An unexpected error occurred";
                    console.log(errorMessage);
                    alert(`Error: ${errorMessage}`);
                });
        }
    };
    resetState = () => {

        this.setState({ 
            employees: [],
            selectedEmployee: null,
            sourceAccounts: [],
            selectedAccount: '',
            error: null,
            loading: false,});
    };


    render() {
        const { employees, selectedEmployee, sourceAccounts, selectedAccount, error } = this.state;

        return (
            <div className={styles.container}>
                {this.state.loading && <Spinner />}
                {!this.state.loading && (
                    <>
                        <h1>Salaries</h1>
                        <button onClick={this.handleManageClick}>Manage</button>
                        {error && <div className={styles.errorLabel}>{error}</div>}
                        {employees && employees.length > 0 && (
                            <select onChange={this.handleEmployeeSelect} defaultValue="">
                                <option value="" disabled>Select an employee</option>
                                {employees.map(emp => (
                                    <option key={emp.id} value={emp.name}>{emp.name}</option>
                                ))}
                            </select>)}

                        {selectedEmployee && (
                            <div>
                                <div>Name: {selectedEmployee.name}</div>
                                <div>Salary: {selectedEmployee.monthlySalary}</div>
                                <div>Due Amount: {this.calculateDueAmount(selectedEmployee)}</div>
                                <select onChange={this.handleAccountSelect} defaultValue="">
                                    <option value="" disabled>Select source account</option>
                                    {sourceAccounts.map(account => (
                                        <option key={account} value={account}>{account}</option>
                                    ))}
                                </select>

                                <div>
                                    <label>Pay Employee</label>
                                    <input type="number" onChange={event => this.setState({ paymentAmount: event.target.value })} />
                                    <button disabled={!selectedAccount} onClick={() => this.handlePayEmployee(selectedEmployee.id, this.state.paymentAmount)}>Pay</button>
                                </div>
                                <div>
                                    <label>Register Absence</label>
                                    <input type="number" onChange={event => this.setState({ absenceDays: event.target.value })} />
                                    <button onClick={() => this.handleRegisterAbsence(selectedEmployee.id, this.state.absenceDays)}>Register</button>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        );
    }
}

export default SalariesComponent;
