import React, { Component } from 'react';
import styles from './RequestModal.module.css';
import Modal from '../../components/UI/Modal/Modal'

class RequestModal extends Component {
    state = {
        deliveryOrders: [],
        selectedBox: null,
        selectedBoxOrderId: null,
        showMoveOptions: false,
        targetOrderIndex: null,
        studentOrderCount: 0
    }

    componentDidMount() {
        const { order } = this.props;
        const deliveryOrder = {
            id: `${order.orderId}00001`,
            boxes: order.boxes || []
        };

        // Add the new deliveryOrder to the deliveryOrders list
        this.setState({ deliveryOrders: [deliveryOrder] }, () => {
            this.updateStudentOrderCount(); // Update student order count after state update
        });
    }

    updateStudentOrderCount = () => {
        const { deliveryOrders } = this.state;
        const studentOrderIds = new Set(); // Use a Set to store unique ids

        // Collect all idStudentOrder values
        deliveryOrders.forEach(order => {
            order.boxes.forEach(box => {
                if (box.idStudentOrder) {
                    studentOrderIds.add(box.idStudentOrder);
                }
            });
        });

        // Update state with the number of distinct student orders
        this.setState({ studentOrderCount: studentOrderIds.size });
    };

    handleAddDeliveryOrder = () => {
        const { deliveryOrders } = this.state;
        const { order } = this.props;
        const newDeliveryOrder = {
            id: `${order.orderId}0000${deliveryOrders.length + 1}`,
            boxes: []
        };

        this.setState({ deliveryOrders: [...deliveryOrders, newDeliveryOrder] });
    };
    selectBox = (box, orderId) => {
        // Only allow selection if it's the first deliveryOrder and there is more than one box
        if (orderId === this.state.deliveryOrders[0].id && this.state.deliveryOrders[0].boxes.length > 1) {
            this.setState({ selectedBox: box, selectedBoxOrderId: orderId });
        }
    };

    handleMoveClick = () => {
        // Check if there is only one target deliveryOrder
        const targetOrders = this.state.deliveryOrders.filter(order => order.id !== this.state.selectedBoxOrderId);
        if (targetOrders.length === 1) {
            // Move directly to this order
            this.moveBox(targetOrders[0].id);
        } else {
            // Show dropdown options
            this.setState({ showMoveOptions: true });
        }
    };

    moveBox = (targetOrderId) => {
        if (this.state.selectedBox && targetOrderId) {
            let newOrders = [...this.state.deliveryOrders];
            const sourceOrderIndex = newOrders.findIndex(order => order.id === this.state.selectedBoxOrderId);
            const targetIndex = newOrders.findIndex(order => order.id === targetOrderId);

            // Remove the box from its current order
            newOrders[sourceOrderIndex].boxes = newOrders[sourceOrderIndex].boxes.filter(box => box.id !== this.state.selectedBox.id);

            // Add the box to the target order
            newOrders[targetIndex].boxes.push(this.state.selectedBox);

            this.setState({ deliveryOrders: newOrders, selectedBox: null, selectedBoxOrderId: null, showMoveOptions: false });
        }
    };

    handleOrderSelection = (event) => {
        const targetOrderId = this.state.deliveryOrders[parseInt(event.target.value)].id;
        this.moveBox(targetOrderId);
    };

    handleOkClick = () => {
        const { onConfirm, order } = this.props;
        const { deliveryOrders } = this.state;

        // Call the parent component's onConfirm with the modified deliveryOrders
        onConfirm(order, deliveryOrders);
    };

    render() {
        const { order, onClose, onConfirm, show } = this.props;
        const { deliveryOrders, studentOrderCount, selectedBox, showMoveOptions } = this.state;
        const canAddMoreOrders = deliveryOrders.length > 0 && deliveryOrders.length < studentOrderCount;
        const hasEmptyOrder = deliveryOrders.some(order => order.boxes.length === 0);
        // Check for boxes with the same idStudentOrder in different deliveryOrders
        const studentOrderMap = new Map();
        let hasDuplicateStudentOrders = false;

        outerLoop: // label for breaking out of nested loops
        for (const order of deliveryOrders) {
            for (const box of order.boxes) {
                if (studentOrderMap.has(box.idStudentOrder)) {
                    // Check if the current box's order ID is the same as the one stored in the map
                    if (studentOrderMap.get(box.idStudentOrder) !== order.id) {
                        hasDuplicateStudentOrders = true;
                        break outerLoop; // Break out of all loops if a duplicate is found in a different order
                    }
                } else {
                    // Map this idStudentOrder to the current order ID
                    studentOrderMap.set(box.idStudentOrder, order.id);
                }
            }
        }
        // Disable OK button if there is an empty order or duplicate student orders across different deliveryOrders
        const disableOkButton = hasEmptyOrder || hasDuplicateStudentOrders;
        return (
            <Modal show={show} modalClosed={onClose}>
                <div className={styles.modal}>
                    <h2>Order ID: {order.orderId}</h2>
                    <div className={styles.deliveryOrdersContainer}>
                        {deliveryOrders.map((deliveryOrder, index) => (
                            <div key={deliveryOrder.id} className={styles.deliveryOrder}>
                                {deliveryOrder.boxes.map(box => (
                                    <div
                                        key={box.id}
                                        className={styles.box}
                                        style={{ border: selectedBox && selectedBox.id === box.id ? '2px solid blue' : 'none' }}
                                        onClick={() => this.selectBox(box, deliveryOrder.id)}
                                    >
                                        {box.id}
                                    </div>
                                ))}
                            </div>
                        ))}
                        {showMoveOptions && (
                            <select onChange={this.handleOrderSelection} defaultValue="">
                                <option value="" disabled>Select a destination</option>
                                {this.state.deliveryOrders.map((order, index) => {
                                    if (index !== 0) { // Exclude the first deliveryOrder
                                        return <option key={order.id} value={index}>{`Move to DO ${index + 1}`}</option>;
                                    }
                                    return null;
                                })}
                            </select>
                        )}
                        <div className={styles.buttons}>
                            <button onClick={this.handleAddDeliveryOrder}
                                disabled={!canAddMoreOrders}
                                className={`${styles.button} ${styles.addButton}`}>+</button>
                            <button
                                className={`${styles.button} ${styles.moveButton}`}
                                disabled={!selectedBox || deliveryOrders.length <= 1}
                                onClick={this.handleMoveClick}
                            >
                                Move
                            </button>
                        </div>
                    </div>
                    <div className={styles.buttonsContainer}>
                        <button onClick={onClose}
                            className={`${styles.button} ${styles.closeButton}`}>Close</button>
                        <button onClick={this.handleOkClick}
                            className={`${styles.button} ${styles.okButton}`}
                            disabled={disableOkButton}>OK</button>
                    </div>
                </div>
            </Modal>

        );
    }
}

export default RequestModal;
