import React, { Component } from 'react';
import getAxiosInstance  from '../../axios-clicks';
import classes from './EtatCommande.module.css';

class TextFetcher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idStudentOrder: '',
      fetchedText: '',
    };
    this.axiosInstance = getAxiosInstance(this.props.sandbox);
  }

  handleIdChange = (event) => {
    this.setState({ idStudentOrder: event.target.value });
  };

  fetchText = () => {
    const { idStudentOrder } = this.state;
    this.axiosInstance.get(`/studentorder/etatcommande/${idStudentOrder}`)
      .then(response => {
        this.setState({ fetchedText: response.data });
      })
      .catch(error => {
        console.error('Error fetching text:', error);
      });
  };

  render() {
    const { idStudentOrder, fetchedText } = this.state;

    return (
      <div>
        <div>
          <label>Enter ID Student Order: </label>
          <input type="text" value={idStudentOrder} onChange={this.handleIdChange} />
          <button onClick={this.fetchText}>Fetch Text</button>
        </div>
        <div>
          <textarea value={fetchedText} rows={5} cols={40} readOnly />
        </div>
      </div>
    );
  }
}

export default TextFetcher;
