import React from 'react';
import getAxiosInstance from '../../axios-clicks';
import Modal from '../../components/UI/Modal/Modal';
import styles from './InventoryUse.module.css'; // CSS Module
import Spinner from '../../components/UI/Spinner/Spinner';

class InventoryUse extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchQuery: '',
            items: [],
            addedItems: [],
            showModifyModal: false,
            showModal: false,
            modalMessage: '',
            currentItem: null,
            usedQuantity: '',
            loading: false,
            inventoryUsage: null, // For storing selected inventory usage type
        };
        this.axiosInstance = getAxiosInstance(this.props.sandbox);
        this.inventoryUsages = { // Declaring usage types
            INTERNAL_USE: 'internal use of inventory',
            TRANSFER: 'transfer from fulfillment to internal',
        };
    }



    handleSearch = () => {

        this.setState({
            loading: true
        });
        this.axiosInstance.get(`/article/search?query=${this.state.searchQuery}&limit=100`)
            .then(response => {
                this.setState({ items: response.data, loading: false });
            })
            .catch(error => {
                this.showModal("Error searching articles. Please try again.");
                this.setState({
                    loading: false
                });
            });
    };
    handleSearchById = () => {

        const { searchQuery } = this.state;

        // Check if searchQuery is a valid number
        if (isNaN(searchQuery) || searchQuery.trim() === "") {
            this.showModal("Invalid search query. Please enter a valid ID.");
            return;
        }

        this.setState({ loading: true });

        this.axiosInstance.get(`/article/searchbyid?query=${searchQuery}`)
            .then(response => {
                this.setState({ items: response.data, loading: false });
            })
            .catch(error => {
                this.showModal("Error searching articles. Please try again.");
                this.setState({ loading: false });
            });
    };

    handleQuantityChange = (e) => {
        const quantity = e.target.value;
        // Allow only numbers and non-negative integers
        if (!quantity || quantity.match(/^\d+$/)) {
            this.setState({ usedQuantity: quantity });
        }
    };

    handleAddQuantity = () => {
        const { currentItem, usedQuantity } = this.state;
        const quantity = parseInt(usedQuantity, 10);
        if (quantity > 0) { // Check if the quantity is a positive integer
            this.setState(prevState => ({
                addedItems: [...prevState.addedItems, { ...currentItem, usedQuantity: quantity }],
                showModifyModal: false,
                usedQuantity: '' // Reset the usedQuantity state
            }));
        } else {
            alert('Please enter a valid quantity (positive integer greater than zero).');
        }
    };

    isDisabled = (id, type) => {
        // Check if the item is present in addedItems
        const isInAddedItems = this.state.addedItems.some(item =>
            item.articleId === id && item.articleType === type
        );
        // Check if prixAchat is not greater than 0 in items
        const hasValidPrixAchat = this.state.items.some(item =>
           item.articleId === id &&
           item.articleType === type &&
              item.prixAchat > 0
        );
           // Return true if either condition is met
           return isInAddedItems || !hasValidPrixAchat;
    };
    handleModalToggle = (item = null) => {
        this.setState({
            showModifyModal: !this.state.showModifyModal,
            currentItem: item
        });
    };



    handleFinish = () => {
        const { addedItems, inventoryUsage } = this.state;
        const url = inventoryUsage === this.inventoryUsages.TRANSFER ?
            '/article/transfer-inventory-from-fulfillment-to-internal' : '/article/use-internal-inventory';
        this.setState({
            loading: true
        });
        this.axiosInstance.post(url, addedItems)
            .then(response => {
                this.resetState(() => {
                    this.showModal("Updated successfully");
                });
            })
            .catch(error => {
                let errorMessage = "An unexpected error occurred";

                // Check if the error response and data are defined
                if (error.response && error.response.data) {
                    // Attempt to use a detailed error message if it exists
                    if (error.response.data.message) {
                        errorMessage = error.response.data.message;
                    } else {
                        // If there's no message, but there is data, stringify it if it's an object
                        // This is helpful in cases where error data might be JSON
                        errorMessage = typeof error.response.data === 'object' ? JSON.stringify(error.response.data) : error.response.data;
                    }
                } else {
                    // If there's no response from the server (network error, server down, etc.)
                    if (error.message) {
                        errorMessage = error.message;
                    }
                }
                this.showModal(errorMessage);
                this.setState({
                    loading: false
                });
            });

    };
    handleInventoryUsageChange = (event) => {
        this.setState({ inventoryUsage: event.target.value });
    };
    showModal = (message) => {
        this.setState({
            showModal: true,
            modalMessage: message
        });
    };
    resetState(callback) {
        this.setState({
            searchQuery: '',
            items: [],
            addedItems: [],
            showModifyModal: false,
            showModal: false,
            modalMessage: '',
            currentItem: null,
            usedQuantity: '',
            loading: false,
        }, callback);  // Execute callback after state is reset
    }

    renderSearchBox() {
        const { searchQuery } = this.state;
        return (
            <div className={styles.container}>
                <input
                    type="text"
                    value={searchQuery}
                    onChange={e => this.setState({ searchQuery: e.target.value })}
                    placeholder="Search items..."
                />
                <button className={styles.Button} onClick={this.handleSearch}>Search</button>
                <button onClick={this.handleSearchById} className={styles.Button}>Search By Id</button>
            </div>
        );
    }

    renderItemTable() {
        const { items, inventoryUsage } = this.state;
        const modifyButtonText = inventoryUsage === this.inventoryUsages.TRANSFER ? 'Transfer' : 'Use';
        return (
            <div className={styles.scrollableContainer}>
                <table className={styles.itemsTable}>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Type</th>
                            <th>Price</th>
                            <th>Stock</th>
                            <th>Modify</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map(item => (
                            <tr key={item.id}>
                                <td>{item.id}</td>
                                <td>{item.name}</td>
                                <td>{item.articleType}</td>
                                <td>{item.prixAchat}</td>
                                <td>{item.stock}</td>
                                <td>
                                    <button
                                        onClick={() => this.handleModalToggle(item)}
                                        className={styles.Button}
                                        disabled={this.isDisabled(item.articleId, item.articleType)}
                                    >
                                        {modifyButtonText}
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }
    renderUsedItems() {
        const { addedItems } = this.state;
        return (
            <div className={styles.scrollableContainer}>
                <ul>
                    {addedItems.map((item, index) => (
                        <li key={`${item.id}_${index}`} className={styles.Lis}>{`${item.name} --> ${item.usedQuantity}`}</li>
                    ))}
                </ul>
            </div>
        );
    }
    renderModal() {
        const { showModal, modalMessage } = this.state;
        if (!showModal) return null;
        return (
            <Modal show={showModal} modalClosed={() => this.setState({ showModal: false })}>
                <p>{modalMessage}</p>
                <button onClick={() => this.setState({ showModal: false })}>OK</button>
            </Modal>
        );
    }
    renderInventoryUsageSelect() {
        const { inventoryUsage } = this.state;

        return (
            <div>
                <select
                    value={inventoryUsage || ''}
                    onChange={this.handleInventoryUsageChange}
                    placeholder="Select inventory usage">
                    <option value="" disabled>Select usage</option>
                    <option value={this.inventoryUsages.INTERNAL_USE}>Internal Use</option>
                    {/* <option value={this.inventoryUsages.TRANSFER}>Transfer</option> */}
                </select>
            </div>
        );
    }

    renderComponentTitle() {
        const { inventoryUsage } = this.state;
        const title = inventoryUsage === this.inventoryUsages.TRANSFER ? 'TRANSFER FULFILLMENT -> INTERNAL' : 'USE INTERNAL INVENTORY';

        return (
            <div>
                <h1>{title}</h1>
            </div>
        );
    }

    renderModifyModal() {
        const { showModifyModal, currentItem, usedQuantity } = this.state;
        if (!showModifyModal) return null;
        return (
            <Modal show={showModifyModal} modalClosed={() => this.handleModalToggle()}>
                <div>
                    <p>ID: {currentItem?.id}</p>
                    <p>Name: {currentItem?.name}</p>
                    <input
                        type="text"
                        value={usedQuantity}
                        onChange={this.handleQuantityChange}
                        placeholder="Used quantity"
                    />
                    <button
                        onClick={this.handleAddQuantity}
                        className={styles.Button}
                        disabled={usedQuantity <= 0 || !usedQuantity || usedQuantity > currentItem.stock}
                    >
                        OK
                    </button>
                </div>
            </Modal>
        );
    }



    render() {
        const { addedItems, inventoryUsage } = this.state;
        const isFinishDisabled = addedItems.length === 0;

        return (
            <div className={styles.inventoryManagement}>
                {this.state.loading ? (
                    <Spinner />
                ) : (<>
                    {this.renderInventoryUsageSelect()}
                    {inventoryUsage && <>
                        {this.renderComponentTitle()}
                        {this.renderSearchBox()}
                        {this.renderItemTable()}
                        {this.renderUsedItems()}
                        {this.renderModal()}
                        {this.renderModifyModal()}
                        <button className={styles.Button} onClick={this.handleFinish} disabled={isFinishDisabled}>Finish</button>
                    </>}
                </>)}

            </div>
        );
    }
}

export default InventoryUse;