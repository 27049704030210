// ModalContent.js
import React, { useState } from 'react';
import styles from './ModalContent.module.css'; // Assuming you have this CSS module file

const ModalContent = ({ libraryId, invoices, addInvoice, removeInvoice, saveInvoices }) => {
    const [localInvoices, setLocalInvoices] = useState(invoices);

    const handleAddInvoice = () => {
        addInvoice(libraryId);
        setLocalInvoices([...localInvoices, { total: '', credit: '', paymentMethod: 'en compte' }]);
    };
    const handleRemoveInvoice = () => {
        removeInvoice(libraryId);
        const newInvoices = localInvoices.slice(0, -1); // This removes the last element from the array
        setLocalInvoices(newInvoices);
    };

    const handleInputChange = (index, field, value) => {
        const newInvoices = [...localInvoices];
        newInvoices[index][field] = value;
        setLocalInvoices(newInvoices);
    };

    const handleSaveInvoices = () => {
        // Here you should implement the logic to save the invoices in your backend or state management
        // This example assumes the function saveInvoices will handle it.
        saveInvoices(libraryId, localInvoices);
    };

    return (
        <div className={styles.modalContent}>
            {localInvoices.map((invoice, index) => (
                <div key={index} className={styles.invoice}>
                    <label>{`Invoice ${index + 1}`}</label>
                    <input
                        type="text"
                        placeholder="Total (mandatory)"
                        value={invoice.total}
                        onChange={(e) => handleInputChange(index, 'total', e.target.value)}
                        className={styles.input}
                    />
                    <input
                        type="text"
                        placeholder="Credit"
                        value={invoice.credit}
                        onChange={(e) => handleInputChange(index, 'credit', e.target.value)}
                        className={styles.input}
                    />
                    <select
                        value={invoice.paymentMethod}
                        onChange={(e) => handleInputChange(index, 'paymentMethod', e.target.value)}
                        className={styles.select}
                    >
                        <option value="EN COMPTE">en compte</option>
                        <option value="RAMASSEUR PREPAID">Ramasseur</option>
                        <option value="BANK">Bank</option>
                        <option value="ESPECE">cash</option>
                    </select>
                </div>
            ))}
            <div className={styles.buttonContainer}>
                <button
                    onClick={handleRemoveInvoice}
                    className={styles.removeButton}
                    disabled={localInvoices.length <= 1}
                >
                    -
                </button>
                <button onClick={handleAddInvoice} className={styles.addButton}>+</button>

            </div>
            <button onClick={handleSaveInvoices} className={styles.saveButton}>Save</button>
        </div>
    );
};

export default ModalContent;
