import React, { Component } from 'react';
import getAxiosInstance from '../../axios-clicks';
import Spinner from '../../components/UI/Spinner/Spinner';
import styles from './FreeEtagere.module.css';

class FreeEtagere extends Component {
    constructor(props) {
        super(props);
        this.state = {
            etagereId: '',
            message: '',
            loading: false
        };
        this.axiosInstance = getAxiosInstance(this.props.sandbox);
    }

    handleInputChange = (event) => {
        this.setState({ etagereId: event.target.value });
    };

    handleSubmit = () => {
        const { etagereId } = this.state;
        this.setState({ loading: true });
        this.axiosInstance.post('/free-etagere', { etagereId })
            .then(response => {
                if (response.data) {
                    this.setState({ message: 'Successfully freed the etagere.', loading: false });
                } else {
                    this.setState({ message: 'Failed to free the etagere.', loading: false });
                }
            })
            .catch(error => {
                this.setState({ message: 'An error occurred. Please try again.', loading: false });
            });
    };

    render() {
        const { etagereId, message, loading } = this.state;
        if (loading) {
            return <Spinner />;
        }
        return (
            <div className={styles.container}>
                <label className={styles.label}>Free Etagere</label>
                <input
                    type="text"
                    value={etagereId}
                    onChange={this.handleInputChange}
                    className={styles.input}
                />
                <button onClick={this.handleSubmit} className={styles.button}>Free</button>
                {message && <p className={styles.message}>{message}</p>}
            </div>
        );
    }
}

export default FreeEtagere;
