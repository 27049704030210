import React, { Component } from 'react';
import getAxiosInstance  from '../../../axios-clicks';

class OrderIdsByPhoneNumber extends Component {
  constructor(props) {
    super(props);
    this.state = {
    phoneNumber: '',
    orderIds: ''
  };
  this.axiosInstance = getAxiosInstance(this.props.sandbox);
  }

  fetchOrderIds = () => {
    this.axiosInstance.get(`/suivi/orderidsbyphonenumber/${this.state.phoneNumber}`)
      .then(response => {
        const orderIds = response.data;
        this.setState({ orderIds });
      })
      .catch(error => {
        console.error('Error fetching order ids:', error);
      });
  };

  render() {
    return (
      <div>
        <p>Tel ----&gt; order id</p>
        <input
          value={this.state.phoneNumber}
          onChange={e => this.setState({ phoneNumber: e.target.value })}
          placeholder="Enter Phone Number"
        />
        <button onClick={this.fetchOrderIds}>Fetch Orders</button>
        <textarea value={this.state.orderIds}></textarea>
      </div>
    );
  }
}

class StudentOrderIdsByPhoneNumber extends Component {
  constructor(props) {
    super(props);
    this.state = {
    phoneNumber: '',
    studentOrderIds: ''
  };
  this.axiosInstance = getAxiosInstance(this.props.sandbox);
  }

  fetchStudentOrderIds = () => {
    this.axiosInstance.get(`/suivi/studentorderidsbyphonenumber/${this.state.phoneNumber}`)
      .then(response => {
        const studentOrderIds = response.data;
        this.setState({ studentOrderIds });
      })
      .catch(error => {
        console.error('Error fetching student order ids:', error);
      });
  };

  render() {
    return (
      <div>
        <p>tel ---&gt; student order ids</p>
        <input
          value={this.state.phoneNumber}
          onChange={e => this.setState({ phoneNumber: e.target.value })}
          placeholder="Enter Phone Number"
        />
        <button onClick={this.fetchStudentOrderIds}>Fetch Student Orders</button>
        <textarea value={this.state.studentOrderIds}></textarea>
      </div>
    );
  }
}

class PhoneNumberByStudentOrderId extends Component {
  constructor(props) {
    super(props);
    this.state = {
    studentOrderId: '',
    phoneNumber: ''
  };
  this.axiosInstance = getAxiosInstance(this.props.sandbox);
  }

  fetchPhoneNumber = () => {
    this.axiosInstance.get(`/suivi/phonenumberbystudentorderid/${this.state.studentOrderId}`)
      .then(response => {
        const phoneNumber = response.data;
        this.setState({ phoneNumber });
      })
      .catch(error => {
        console.error('Error fetching phone number:', error);
      });
  };

  render() {
    return (
      <div>
        <p>student order id ----&gt; tel</p>
        <input
          value={this.state.studentOrderId}
          onChange={e => this.setState({ studentOrderId: e.target.value })}
          placeholder="Enter Student Order ID"
        />
        <button onClick={this.fetchPhoneNumber}>Fetch Phone Number</button>
        <textarea value={this.state.phoneNumber}></textarea>
      </div>
    );
  }
}

// class StudentOrdersByNameAndLibraryIds extends Component {
//   state = {
//     name: '',
//     libraryOrderIds: '',
//     studentOrderIds: ''
//   };

//   fetchStudentOrders = () => {
    
//     axios.get('suivi/studentorderidsbynameandlibraryids/', {
//                 params: {
//                     name: this.state.name,
//                     libraryOrderIds: this.state.libraryOrderIds.split(' ')
//                 }
//             }).then(response => {
//                 const studentOrders = response.data;
//                 this.setState({ studentOrders });
//               })
//               .catch(error => {
//                 console.error('Error fetching phone number:', error);
//               });
//   };

//   render() {
//     return (
//       <div>
//         <input
//           value={this.state.name}
//           onChange={e => this.setState({ name: e.target.value })}
//           placeholder="Enter Name"
//         />
//         <input
//           value={this.state.libraryIds}
//           onChange={e => this.setState({ libraryIds: e.target.value })}
//           placeholder="Enter Library IDs separated by space"
//         />
//         <button onClick={this.fetchStudentOrderIds}>Fetch Student Orders</button>
//         <textarea value={this.state.studentOrderIds}></textarea>
//       </div>
//     );
//   }
// }
class QuotationUrlByOrderId extends Component {
  constructor(props) {
    super(props);
    this.state = {
    orderId: '',
    url: ''
  };
  this.axiosInstance = getAxiosInstance(this.props.sandbox);
  }

  fetchUrl = () => {
    this.axiosInstance.get(`/suivi/quotationurlbyorderid/${this.state.orderId}`)
      .then(response => {
        const url = response.data;
        this.setState({ url });
      })
      .catch(error => {
        console.error('Error fetching url:', error);
      });
  };

  render() {
    return (
      <div>
        <p>order id ----&gt; url</p>
        <input
          value={this.state.orderId}
          onChange={e => this.setState({ orderId: e.target.value })}
          placeholder="Enter Order ID"
        />
        <button onClick={this.fetchUrl}>Fetch Url</button>
        <textarea value={this.state.url}></textarea>
      </div>
    );
  }
}
class OrderIdByQuotationUrl extends Component {
  constructor(props) {
    super(props);
    this.state = {
    url: '',
    orderId: ''
  };
  this.axiosInstance = getAxiosInstance(this.props.sandbox);
  }
  
  fetchOrderId = () => {
    this.axiosInstance.get(`/suivi/orderidbyquotationurl/${this.state.url}`)
      .then(response => {
        const orderId = response.data;
        this.setState({ orderId });
      })
      .catch(error => {
        console.error('Error fetching order id:', error);
      });
  };

  render() {
    return (
      <div>
        <p>url ----&gt; orderId</p>
        <input
          value={this.state.url}
          onChange={e => this.setState({ url: e.target.value })}
          placeholder="Enter Url"
        />
        <button onClick={this.fetchOrderId}>Fetch Order Id</button>
        <textarea value={this.state.orderId}></textarea>
      </div>
    );
  }
}

export { OrderIdsByPhoneNumber, StudentOrderIdsByPhoneNumber, PhoneNumberByStudentOrderId, QuotationUrlByOrderId, OrderIdByQuotationUrl };
