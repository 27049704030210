import React, { Component } from 'react';
import getAxiosInstance from '../../axios-clicks';
import Modal from '../../components/UI/Modal/Modal';
import Button from '../../components/UI/Button/Button';
import styles from './UserCrud.module.css';
import Spinner from '../../components/UI/Spinner/Spinner';
import { extractRequestErrorMessage } from '../../components/Utils/ExtractRequestErrorMessage';

class UserCrud extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            showCreateModal: false,
            showEditModal: false,
            showDeleteModal: false,
            currentUser: { id: null, username: '', password: '', name: '', cin: '' },
            loading: false,
        }; this.axiosInstance = getAxiosInstance(this.props.sandbox);
    }

    componentDidMount() {
        this.fetchUsers();
    }

    fetchUsers = () => {
        this.setState({ loading: true });
        this.axiosInstance.get('/user')
            .then(response => {
                this.setState({ users: response.data, loading: false });
            })
            .catch(error => {
                const errorMessage = extractRequestErrorMessage(error);
                alert(errorMessage);
                this.setState({ loading: false });
            });
    };

    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState(prevState => ({
            currentUser: { ...prevState.currentUser, [name]: value }
        }));
    };

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({ loading: true });
        const user = this.state.currentUser;

        if (user.id) {
            this.axiosInstance.put(`/user/${user.id}`, user)
                .then(this.fetchUsers)
                .catch(error => {
                    const errorMessage = extractRequestErrorMessage(error);
                    alert(errorMessage);
                    this.setState({ loading: false });
                });
        } else {
            this.axiosInstance.post('/user', user)
                .then(this.fetchUsers)
                .catch(error => {
                    const errorMessage = extractRequestErrorMessage(error);
                    alert(errorMessage);
                    this.setState({ loading: false });
                });
        }
        this.closeModal();
    };

    openModal = (type, user = { id: null, username: '', password: '', name: '', cin: '' }) => {
        this.setState({
            [type]: true,
            currentUser: user
        });
    };

    closeModal = () => {
        this.setState({
            showCreateModal: false,
            showEditModal: false,
            showDeleteModal: false
        });
    };

    toggleUserStatus = () => {
        const { id, enabled } = this.state.currentUser;
        this.setState({ loading: true });
        const endpoint = `/user/${enabled ? 'disable' : 'enable'}/${id}`;
        this.axiosInstance.put(endpoint)
            .then(this.fetchUsers)
            .catch(error => {
                const errorMessage = extractRequestErrorMessage(error);
                alert(errorMessage);
                this.setState({ loading: false });
            });
        this.closeModal();
    };

    renderCreateModal = () => {
        const { showCreateModal, currentUser } = this.state;
        return (
            <Modal show={showCreateModal} modalClosed={this.closeModal}>
                <div>
                    <div>
                        <label htmlFor="username">Username:</label>
                        <input
                            type="text"
                            id="username"
                            name="username"
                            value={currentUser.username}
                            onChange={this.handleInputChange}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="password">Password:</label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            value={currentUser.password}
                            onChange={this.handleInputChange}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="name">Name:</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={currentUser.name}
                            onChange={this.handleInputChange}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="cin">CIN:</label>
                        <input
                            type="text"
                            id="cin"
                            name="cin"
                            value={currentUser.cin}
                            onChange={this.handleInputChange}
                            required
                        />
                    </div>
                    <div className={styles.modalButtonContainer}>
                        <Button btnType="Success" clicked={this.handleSubmit}>Save</Button>
                        <Button btnType="Danger" clicked={this.closeModal} >Cancel</Button>
                    </div>
                </div>
            </Modal>
        );
    };

    renderUpdateModal = () => {
        const { showEditModal, currentUser } = this.state;
        return (
            <Modal show={showEditModal} modalClosed={this.closeModal}>
                <div>
                    <div>
                        <label htmlFor="username">Username:</label>
                        <input
                            type="text"
                            id="username"
                            name="username"
                            value={currentUser.username}
                            onChange={this.handleInputChange}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="password">Password:</label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            value={currentUser.password}
                            onChange={this.handleInputChange}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="name">Name:</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={currentUser.name}
                            onChange={this.handleInputChange}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="cin">CIN:</label>
                        <input
                            type="text"
                            id="cin"
                            name="cin"
                            value={currentUser.cin}
                            onChange={this.handleInputChange}
                            required
                        />
                    </div>
                    <div className={styles.modalButtonContainer}>
                        <Button btnType="Success" clicked={this.handleSubmit}>Update</Button>
                        <Button btnType="Danger" clicked={this.closeModal} >Cancel</Button>
                    </div>
                </div>
            </Modal>
        );
    };

    renderToggleStatusModal = () => {
        const { showDeleteModal, currentUser } = this.state;
        const action = currentUser.enabled ? 'Disable' : 'Enable';
        return (
            <Modal show={showDeleteModal} modalClosed={this.closeModal}>
                <div>
                    <p>Are you sure you want to {action.toLowerCase()} the user "{currentUser.name}"?</p>
                    <div className={styles.modalButtonContainer}>
                        <Button btnType="Success" clicked={this.toggleUserStatus}>{action}</Button>
                        <Button btnType="Danger" clicked={this.closeModal}>Cancel</Button>
                    </div>
                </div>
            </Modal>
        );
    };

    

    render() {
        const { users, loading } = this.state;
        if (loading) {
            return <Spinner />;
        }
        return (
            <div>
                <button className={`${styles.button} ${styles.newButton}`} onClick={() => this.openModal('showCreateModal')}>New User</button>
                <table className={styles.userTable}>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Username</th>
                            <th>Name</th>
                            <th>CIN</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map(user => (
                            <tr key={user.id}>
                                <td>{user.id}</td>
                                <td>{user.username}</td>
                                <td>{user.name}</td>
                                <td>{user.cin}</td>
                                <td className={styles.buttonContainer}>
                                    <button className={`${styles.button} ${styles.modifyButton}`} onClick={() => this.openModal('showEditModal', user)}>Modify</button>
                                    <button className={`${styles.button} ${styles.deleteButton}`} onClick={() => this.openModal('showDeleteModal', user)}>
                                        {user.enabled ? 'Disable' : 'Enable'}
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                {this.renderCreateModal()}
                {this.renderUpdateModal()}
                {this.renderToggleStatusModal ()}
            </div>
        );
    }
}

export default UserCrud;
