import React from 'react';
import getAxiosInstance from '../../axios-clicks';
import styles from './PrepaidChangeReturn.module.css';
import Spinner from '../../components/UI/Spinner/Spinner';

class PrepaidChangeReturn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            prepaidAccounts: [],
            selectedPrepaidAccount: null,
            error: null,
            loading: false,
        }; this.axiosInstance = getAxiosInstance(this.props.sandbox);
    }


    handleManageClick = () => {

        this.setState({ loading: true });
        this.axiosInstance.get('/finances/prepaid-account-names')
            .then(response => this.setState({ prepaidAccounts: response.data, error: null, loading: false }))
            .catch(error => this.setState({ error: error.message, loading: false }));
    };

    handlePrepaidAccountSelect = (event) => {
        const selectedPrepaidAccount = this.state.prepaidAccounts.find(ppa => ppa.name === event.target.value);
        this.setState({ selectedPrepaidAccount });
    };

    handleChangeReturn = (id, amount) => {
        if (amount > 0) {
            if (window.confirm("Confirm return?")) {
                this.setState({ loading: true });
                this.axiosInstance.post(`/finances/prepaidChangeReturn/${id}`, { amount })
                    .then(() => {
                        this.setState({ loading: false, selectedPrepaidAccount: null });
                        alert("return registred successfully!")
                        this.resetState();
                    })
                    .catch(error => {
                        // Access the response data from the error object
                        this.setState({ loading: false });
                        const errorMessage = error.response && error.response.data ? error.response.data : "An unexpected error occurred";
                        alert(`Error: ${errorMessage}`);
                    });
            }
        } else {
            alert("Invalid advance amount.");
        }
    };

    
    resetState = () => {

        this.setState({ 
            prepaidAccounts: [],
            selectedPrepaidAccount: null,
            error: null,
            loading: false,});
    };


    render() {
        const { prepaidAccounts, selectedPrepaidAccount, error } = this.state;

        return (
            <div className={styles.container}>
                {this.state.loading && <Spinner />}
                {!this.state.loading && (
                    <>
                        <h1>Prepaid Change return</h1>
                        <button onClick={this.handleManageClick}>Manage</button>
                        {error && <div className={styles.errorLabel}>{error}</div>}
                        {prepaidAccounts && prepaidAccounts.length > 0 && (
                            <select onChange={this.handlePrepaidAccountSelect} defaultValue="">
                                <option value="" disabled>Select a prepaid account</option>
                                {prepaidAccounts.map(ppa => (
                                    <option key={ppa.id} value={ppa.name}>{ppa.name}</option>
                                ))}
                            </select>)}

                        {selectedPrepaidAccount && (
                            <div>
                                <div>Name: {selectedPrepaidAccount.name}</div>
                                <div>Due Amount: {selectedPrepaidAccount.amount}</div>
                                
                                <div>
                                    <label>return change</label>
                                    <input type="number" onChange={event => this.setState({ paymentAmount: event.target.value })} />
                                    <button disabled={!(this.state.paymentAmount>0)} onClick={() => this.handleChangeReturn(selectedPrepaidAccount.id, this.state.paymentAmount)}>Return change</button>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        );
    }
}

export default PrepaidChangeReturn;
