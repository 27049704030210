import React from 'react';
import getAxiosInstance from '../../axios-clicks';
import Modal from '../../components/UI/Modal/Modal';
import styles from './InternalInventoryPurchase.module.css'; // CSS Module
import Spinner from '../../components/UI/Spinner/Spinner';

class InternalInventoryPurchase extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchQuery: '',
            items: [],
            addedItems: [],
            sourceName: '',
            sources: [],
            showModifyModal: false,
            showModal: false,
            modalMessage: '',
            currentItem: null,
            purchaseQuantity: '',
            prixAchat: '',
            loading: false,
        };
        this.axiosInstance = getAxiosInstance(this.props.sandbox);
    }

    componentDidMount() {
        this.fetchSourceNames();
    }

    fetchSourceNames = () => {
        this.setState({
            loading: true
        });
        this.axiosInstance.get('/finances/internal-inventory-purchase-sources-accounts')
            .then(response => {
                console.log(response.data);
                this.setState({ sources: response.data, loading: false });
            })
            .catch(error => {
                this.showModal("Error retrieving sources. Please try again.");
                this.setState({
                    loading: false
                });
            });
    };

    handleSearch = () => {

        this.setState({
            loading: true
        });
        this.axiosInstance.get(`/article/search?query=${this.state.searchQuery}&limit=300`)
            .then(response => {
                this.setState({ items: response.data, loading: false });
            })
            .catch(error => {
                this.showModal("Error searching articles. Please try again.");
                this.setState({
                    loading: false
                });
            });
    };

    handleSearchById = () => {

        const { searchQuery } = this.state;

        // Check if searchQuery is a valid number
        if (isNaN(searchQuery) || searchQuery.trim() === "") {
            this.showModal("Invalid search query. Please enter a valid ID.");
            return;
        }

        this.setState({ loading: true });

        this.axiosInstance.get(`/article/searchbyid?query=${searchQuery}`)
            .then(response => {
                this.setState({ items: response.data, loading: false });
            })
            .catch(error => {
                this.showModal("Error searching articles. Please try again.");
                this.setState({ loading: false });
            });
    };

    handleQuantityChange = (e) => {
        const quantity = e.target.value;
        // Allow only numbers and non-negative integers
        if (!quantity || quantity.match(/^\d+$/)) {
            this.setState({ purchaseQuantity: quantity });
        }
    };

    handlePrixAchatChange = (e) => {
        const prixAchat = e.target.value;
        // Allow only valid positive numbers
        if (!prixAchat || (!isNaN(prixAchat) && parseFloat(prixAchat) > 0)) {
            this.setState({ prixAchat });
        }
    };

    handleAddQuantity = () => {
        const { currentItem, purchaseQuantity, prixAchat } = this.state;
        const quantity = parseInt(purchaseQuantity, 10);
        const prix = parseFloat(prixAchat);

        if (quantity > 0 && prix > 0) { // Check if both quantity and prixAchat are valid
            this.setState(prevState => ({
                addedItems: [...prevState.addedItems, { ...currentItem, purchasedQuantity: quantity, prixAchat: prix }],
                showModifyModal: false,
                purchaseQuantity: '', // Reset the purchaseQuantity state
                prixAchat: '' // Reset the prixAchat state
            }));
        } else {
            alert('Please enter a valid quantity (positive integer greater than zero) and a valid prix achat (positive number).');
        }
    };

    isDisabled = (id, type) => {
        // Check if the item is present in addedItems
        const isInAddedItems = this.state.addedItems.some(item =>
            item.articleId === id && item.articleType === type
        );

        // Return true if either condition is met
        return isInAddedItems;
    };
    handleModalToggle = (item = null) => {
        this.setState({
            showModifyModal: !this.state.showModifyModal,
            currentItem: item,
            purchaseQuantity: '', // Reset the state
            prixAchat: '' // Reset the state
        });
    };

    handleSelectSource = (e) => {
        this.setState({ sourceName: e.target.value });
    };

    handleFinish = () => {
        const { addedItems, sourceName } = this.state;
        this.setState({
            loading: true
        });
        this.axiosInstance.post('/article/purchase-internal-inventory', { items: addedItems, sourceName })
            .then(response => {
                this.resetState(() => {
                    this.showModal("Updated successfully");
                });
            })
            .catch(error => {
                this.showModal("Error completing purchase. Please try again.");
                this.setState({
                    loading: false
                });
            });

    };
    showModal = (message) => {
        this.setState({
            showModal: true,
            modalMessage: message
        });
    };
    resetState(callback) {
        this.setState({
            searchQuery: '',
            items: [],
            addedItems: [],
            sourceName: '',
            showModifyModal: false,
            showModal: false,
            modalMessage: '',
            currentItem: null,
            purchaseQuantity: '',
            prixAchat: '',
            loading: false,
        }, callback);  // Execute callback after state is reset
    }

    renderSearchBox() {
        const { searchQuery } = this.state;
        return (
            <div className={styles.container}>
                <input
                    type="text"
                    value={searchQuery}
                    onChange={e => this.setState({ searchQuery: e.target.value })}
                    placeholder="Search items..."
                />
                <button onClick={this.handleSearch} className={styles.Button}>Search</button>
                <button onClick={this.handleSearchById} className={styles.Button}>Search By Id</button>
            </div>
        );
    }

    renderItemTable() {
        const { items } = this.state;
        return (
            <div className={styles.scrollableContainer}>
                <table className={styles.itemsTable}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Type</th>
                            <th>PrixAchat</th>
                            <th>Stock</th>
                            <th>Modify</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map(item => (
                            <tr key={item.id}>
                                <td>{item.name}</td>
                                <td>{item.articleType}</td>
                                <td>{item.prixAchat}</td>
                                <td>{item.stock}</td>
                                <td>
                                    <button
                                        onClick={() => this.handleModalToggle(item)}
                                        disabled={this.isDisabled(item.articleId, item.articleType)}
                                        className={styles.Button}
                                    >
                                        Buy
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }
    renderPurchasedItems() {
        const { addedItems } = this.state;
        return (
            <div className={styles.scrollableContainer}>
                <ul className={styles.Ul}>
                    {addedItems.map((item, index) => (
                        <li key={`${item.id}_${index}`} className={styles.Li}>{`${item.name} --> ${item.purchasedQuantity}`}</li>
                    ))}
                </ul>
            </div>
        );
    }
    renderModal() {
        const { showModal, modalMessage } = this.state;
        if (!showModal) return null;
        return (
            <Modal show={showModal} modalClosed={() => this.setState({ showModal: false })}>
                <p>{modalMessage}</p>
                <button onClick={() => this.setState({ showModal: false })}>OK</button>
            </Modal>
        );
    }

    renderModifyModal() {
        const { showModifyModal, currentItem, purchaseQuantity, prixAchat } = this.state;
        const isOkButtonDisabled = purchaseQuantity <= 0 || !purchaseQuantity || prixAchat <= 0 || !prixAchat || !currentItem.prixVente || prixAchat >= currentItem.prixVente;
        if (!showModifyModal) return null;
        return (
            <Modal show={showModifyModal} modalClosed={() => this.handleModalToggle()}>
                <div>
                    <p>ID: {currentItem?.id}</p>
                    <p>Name: {currentItem?.name}</p>
                    <p>Prix Vente: {currentItem?.prixVente}</p>
                    <input
                        type="text"
                        value={purchaseQuantity}
                        onChange={this.handleQuantityChange}
                        placeholder="Purchased quantity"
                    />
                    <input
                        type="text"
                        value={prixAchat}
                        onChange={this.handlePrixAchatChange}
                        placeholder="Prix Achat(< PV)"
                    />
                    <button
                        onClick={this.handleAddQuantity}
                        disabled={isOkButtonDisabled}
                        className={styles.Button}
                    >
                        OK
                    </button>
                </div>
            </Modal>
        );
    }


    renderSourceSelector() {
        const { sources, sourceName } = this.state;
        return (
            <div>
                <label>Source Name:</label>
                <select
                    value={sourceName}
                    onChange={this.handleSelectSource}
                >
                    <option value="">Select source</option>
                    {sources.map(source => (
                        <option key={source.id} value={source.name}>{source.name}</option>
                    ))}
                </select>
            </div>
        );
    }

    render() {
        const { addedItems, sourceName } = this.state;
        const isFinishDisabled = addedItems.length === 0 || !sourceName;

        return (
            <div className={styles.inventoryManagement}>
                {this.state.loading ? (
                    <Spinner />
                ) : (
                    <>{this.renderSearchBox()}
                        {this.renderItemTable()}
                        {this.renderPurchasedItems()}
                        {this.renderModal()}
                        {this.renderModifyModal()}
                        {this.renderSourceSelector()}
                        <button onClick={this.handleFinish} disabled={isFinishDisabled} className={styles.Button}>Finish</button></>)}

            </div>
        );
    }
}

export default InternalInventoryPurchase;