// Import necessary modules
import React, { Component } from 'react';
import axios from 'axios';
import styles from './PrepaidExpense.module.css'; // Import CSS module
import getAxiosInstance from '../../axios-clicks';
import Spinner from '../../components/UI/Spinner/Spinner';

class PrepaidExpense extends Component {
    constructor(props) {
        super(props);
        this.state = {
            prepaidAccounts: [],
            selectedPrepaidAccount: '',
            description: '',
            amount: '',
            isFormValid: false,
            successMessage: '',
            errorMessage: '',
            showForm: false,
            loading: false,
        };
        this.axiosInstance = getAxiosInstance(this.props.sandbox);
    }

    fetchOptions = () => {
        this.setState({ loading: true });
            this.axiosInstance.get('/finances/prepaid-account-names')
            .then(prepaidAccountsRes => {
                const transformedprepaidAccounts = prepaidAccountsRes.data.map((name, index) => ({
                    id: index, // or any other unique identifier logic
                    name: name
                }));
                this.setState({
                    prepaidAccounts: transformedprepaidAccounts,
                    showForm: true,
                    loading: false,
                });
            })
            .catch(error => {
                const errorMessage = error.response && error.response.data ? error.response.data : 'Error fetching options.';
                this.setState({ errorMessage: errorMessage, successMessage: '', loading: false });
            });
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value }, this.validateForm);
    }

    validateForm = () => {
        const {  selectedPrepaidAccount, description, amount } = this.state;
        const isAmountValid = parseFloat(amount) > 0;
        this.setState({
            isFormValid: selectedPrepaidAccount && description && isAmountValid
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({ loading: true });
        const { selectedPrepaidAccount, description, amount } = this.state;
        this.axiosInstance.post('/finances/prepaid-expense', {
            prepaidAccount: selectedPrepaidAccount,
            description,
            amount: parseFloat(amount),
        })
            .then(response => {
                this.setState({ successMessage: 'Expense registered successfully!', errorMessage: '', loading: false });
            })
            .catch(error => {
                const errorMessage = error.response && error.response.data ? error.response.data : 'Error registering expense.';
                this.setState({ errorMessage: errorMessage, successMessage: '', loading: false });
            });
    }

    render() {
        const {  prepaidAccounts, isFormValid, successMessage, errorMessage, showForm } = this.state;
        return (
            <div className={styles.expenseForm}>
                {this.state.loading && <Spinner />}
                {!this.state.loading && (
                    <>
                        <h1>New Prepaid Expense</h1>
                        <button onClick={this.fetchOptions}>Prepay Expense</button>
                        {showForm && (
                            <form onSubmit={this.handleSubmit}>
                                <select name="selectedPrepaidAccount" onChange={this.handleChange} value={this.state.selectedPrepaidAccount}>
                                    <option value="">Select prepaid Account</option>
                                    {prepaidAccounts.map((account, index) => <option key={index} value={account.name}>{account.name}</option>)}
                                </select>
                                <input type="text" name="description" placeholder="Description" onChange={this.handleChange} />
                                <input type="number" name="amount" placeholder="Amount" step="0.01" onChange={this.handleChange} />
                                <button type="submit" disabled={!isFormValid}>Done</button>
                            </form>
                        )}
                        {successMessage && <p className={styles.success}>{successMessage}</p>}
                        {errorMessage && <p className={styles.error}>{errorMessage}</p>}
                    </>
                )}
            </div>
        );
    }
}

export default PrepaidExpense;
