import React, { Component } from 'react';
import getAxiosInstance from '../../axios-clicks';
import Modal from '../../components/UI/Modal/Modal';
import Button from '../../components/UI/Button/Button';
import styles from './PersonCrud.module.css';
import Spinner from '../../components/UI/Spinner/Spinner';
import { extractRequestErrorMessage } from '../../components/Utils/ExtractRequestErrorMessage';

class DeliveryCompanyCrud extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deliveryCompanies: [],
            showCreateModal: false,
            showEditModal: false,
            showDeleteModal: false,
            currentDeliveryCompany: { id: null, name: '' },
            loading: false,
        }; this.axiosInstance = getAxiosInstance(this.props.sandbox);
    }

    componentDidMount() {
        this.fetchDeliveryCompanies();
    }

    fetchDeliveryCompanies = () => {
        this.setState({ loading: true });
        this.axiosInstance.get('/deliveryCompany')
            .then(response => {
                this.setState({ deliveryCompanies: response.data, loading: false });
            })
            .catch(error => {
                const errorMessage = extractRequestErrorMessage(error);
                alert(errorMessage);
                this.setState({ loading: false });
            });
    };

    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState(prevState => ({
            currentDeliveryCompany: { ...prevState.currentDeliveryCompany, [name]: value }
        }));
    };

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({ loading: true });
        const deliveryCompany = this.state.currentDeliveryCompany;

            this.axiosInstance.post('/deliveryCompany', deliveryCompany)
                .then(this.fetchDeliveryCompanies)
                .catch(error => {
                    const errorMessage = extractRequestErrorMessage(error);
                    alert(errorMessage);
                    this.setState({ loading: false });
                });
        
        this.closeModal();
    };

    openModal = (type, deliveryCompany = { id: null, name: '' }) => {
        this.setState({
            [type]: true,
            currentDeliveryCompany: deliveryCompany
        });
    };

    closeModal = () => {
        this.setState({
            showCreateModal: false,
            showEditModal: false,
            showDeleteModal: false
        });
    };

    renderCreateModal = () => {
        const { showCreateModal, currentDeliveryCompany } = this.state;
        return (
            <Modal show={showCreateModal} modalClosed={this.closeModal}>
                <div>
                    <div>
                        <label htmlFor="name">DeliveryCompany Name:</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={currentDeliveryCompany.name}
                            onChange={this.handleInputChange}
                            required
                        />
                    </div>
                    <div className={styles.modalButtonContainer}>
                        <Button btnType="Success" clicked={this.handleSubmit}>Save</Button>
                        <Button btnType="Danger" clicked={this.closeModal}>Cancel</Button>
                    </div>
                </div>
            </Modal>
        );
    };


    render() {
        const { deliveryCompanies, loading } = this.state;
        if (loading) {
            return <Spinner />;
        }
        return (
            <div>
                <button className={`${styles.button} ${styles.newButton}`} onClick={() => this.openModal('showCreateModal')}>New DeliveryCompany</button>
                <table className={styles.personTable}>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        {deliveryCompanies.map(deliveryCompany => (
                            <tr key={deliveryCompany.id}>
                                <td>{deliveryCompany.id}</td>
                                <td>{deliveryCompany.name}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                {this.renderCreateModal()}
            </div>
        );
    }
}

export default DeliveryCompanyCrud;
