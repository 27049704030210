import React, { Component } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import styles from './RegisterCollection.module.css';
import RequestModal from './RequestModal';
import getAxiosInstance from '../../axios-clicks';
import Spinner from '../../components/UI/Spinner/Spinner'

class RegisterCollection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deliveryCompanies: [],
            requestedOrders: [],
            collectedOrders: [],
            selectedDeliveryCompany: '',
            selectedOrder: null,
            selectedOrders: [],
            loading: false
        }; this.axiosInstance = getAxiosInstance(this.props.sandbox);
    }

    componentDidMount() {
        this.fetchDeliveryCompanies();
    }

    fetchDeliveryCompanies = async () => {
        this.setState({ loading: true });
        this.axiosInstance.get('/delivery/deliveryCompanies')
            .then(response => {

                this.setState({ deliveryCompanies: response.data, loading: false });
            })
            .catch(error => {
                let errorMessage = "An unexpected error occurred";

                // Check if the error response and data are defined
                if (error.response && error.response.data) {
                    // Attempt to use a detailed error message if it exists
                    if (error.response.data.message) {
                        errorMessage = error.response.data.message;
                    } else {
                        // If there's no message, but there is data, stringify it if it's an object
                        // This is helpful in cases where error data might be JSON
                        errorMessage = typeof error.response.data === 'object' ? JSON.stringify(error.response.data) : error.response.data;
                    }
                } else {
                    // If there's no response from the server (network error, server down, etc.)
                    if (error.message) {
                        errorMessage = error.message;
                    }
                }
                alert(errorMessage);
                this.setState({ loading: false });
            });
    };

    fetchOrdersToCollect = async () => {
        this.setState({ loading: true });
        const { selectedDeliveryCompany, deliveryCompanies } = this.state;
        const company = deliveryCompanies.find(c => c.name === selectedDeliveryCompany);
        const companyId = company ? company.id : null;

        if (!companyId) {
            this.setState({ loading: false });
            return;
        }
        this.axiosInstance.get(`/delivery/ordersToCollecte/${companyId}`)
            .then(response => {

                this.setState({ requestedOrders: response.data, loading: false });
            })
            .catch(error => {
                let errorMessage = "An unexpected error occurred";

                // Check if the error response and data are defined
                if (error.response && error.response.data) {
                    // Attempt to use a detailed error message if it exists
                    if (error.response.data.message) {
                        errorMessage = error.response.data.message;
                    } else {
                        // If there's no message, but there is data, stringify it if it's an object
                        // This is helpful in cases where error data might be JSON
                        errorMessage = typeof error.response.data === 'object' ? JSON.stringify(error.response.data) : error.response.data;
                    }
                } else {
                    // If there's no response from the server (network error, server down, etc.)
                    if (error.message) {
                        errorMessage = error.message;
                    }
                }
                alert(errorMessage);
                this.setState({ loading: false });
            });
    };



    handleDeliveryCompanyChange = (event) => {
        this.setState({ selectedDeliveryCompany: event.target.value }, () => {
            this.fetchOrdersToCollect();
        });
    };




    handleMoveOrders = () => {
        this.setState(prevState => {
            const { requestedOrders, collectedOrders, selectedOrders } = prevState;

            // Filter out the selected orders from requestedOrders
            const remainingOrdersToCollect = requestedOrders.filter(order =>
                !selectedOrders.includes(order.id)
            );
            // Combine the new requested orders with the existing ones
            return {
                requestedOrders: remainingOrdersToCollect,
                collectedOrders: [...collectedOrders, ...selectedOrders]
            };
        });
    };


    handleCheckboxChange = (order, isChecked) => {
        this.setState(prevState => {
            const newSelectedOrders = isChecked
                ? [...prevState.selectedOrders, order.id]
                : prevState.selectedOrders.filter(id => id !== order.id);

            return { selectedOrders: newSelectedOrders };
        });
    };



    handleFinish = () => {
        console.log(this.state.collectedOrders);
        this.setState({ loading: true });
        const { selectedDeliveryCompany, collectedOrders } = this.state;
        this.axiosInstance.post('/delivery/register-collection', {
            collectedOrders: collectedOrders,
        })
            .then(response => {
                alert('Successfully requested');
                this.resetState();
            })
            .catch(error => {
                let errorMessage = "An unexpected error occurred";

                // Check if the error response and data are defined
                if (error.response && error.response.data) {
                    // Attempt to use a detailed error message if it exists
                    if (error.response.data.message) {
                        errorMessage = error.response.data.message;
                    } else {
                        // If there's no message, but there is data, stringify it if it's an object
                        // This is helpful in cases where error data might be JSON
                        errorMessage = typeof error.response.data === 'object' ? JSON.stringify(error.response.data) : error.response.data;
                    }
                } else {
                    // If there's no response from the server (network error, server down, etc.)
                    if (error.message) {
                        errorMessage = error.message;
                    }
                }
                alert(errorMessage);
                this.setState({
                    loading: false
                });
            });
    }
    resetState() {
        this.setState({
            collectedOrders: [],
            showModal: false,
            selectedOrder: null,
            selectedOrders: [],
            loading: false
        });
    }
    renderDeliveryCompanySelection() {
        const { deliveryCompanies, selectedDeliveryCompany } = this.state;
        return (
            <select value={selectedDeliveryCompany} onChange={this.handleDeliveryCompanyChange} className={styles.dropdown}>
                <option value="" disabled={selectedDeliveryCompany !== ''}>Choose a delivery company</option>
                {deliveryCompanies.map((company) => (
                    <option key={company.id} value={company.name}>{company.name}</option>
                ))}
            </select>
        );
    }

    renderOrdersToCollect() {
        const { requestedOrders, selectedOrders } = this.state;
        return (
            <div className={styles.ordersList}>
                {requestedOrders.map((order) => (
                    <div key={order.id} className={styles.orderLine}>
                        <input
                            type="checkbox"
                            checked={this.state.selectedOrders.includes(order.id)}
                            onChange={e => this.handleCheckboxChange(order, e.target.checked)}
                        />
                        <span>order: {order.id}</span>
                        <span>{order.boxes.length} cartons</span>
                    </div>
                ))}
            </div>
        );
    }

    renderCollectedOrders() {
        const { collectedOrders, selectedDeliveryCompany } = this.state;
        return (
            <div className={styles.collectedOrdersList}>
                {collectedOrders.map(order => (
                    <div key={order.idd} className={styles.requestedOrder}>
                        <div className={styles.requestedOrderID}>Order: {order.id}</div>
                        <div className={styles.separator}></div>
                        <div className={styles.deliveryOrdersContainer}>

                            <div key={order.id} className={styles.deliveryOrder}>
                                {order.boxes.map(box => (
                                    <div key={box.id} className={styles.box}>
                                        {box.id}
                                    </div>
                                ))}
                            </div>

                        </div>
                    </div>
                ))}
            </div>
        );
    }

    render() {
        const { deliveryCompanies, requestedOrders, collectedOrders, selectedDeliveryCompany, showModal, selectedOrder, loading } = this.state;
        if (loading) {
            return <Spinner />;
        }
        return (
            <div className={styles.container}>
                {this.renderDeliveryCompanySelection()}
                {requestedOrders && requestedOrders.length > 0 &&
                    <div>
                        {this.renderOrdersToCollect()}

                        <button
                            onClick={this.handleMoveOrders}
                            disabled={this.state.selectedOrders.length === 0}
                            className={styles.moveButton}
                        >
                            <FontAwesomeIcon icon={faArrowDown} />
                        </button>
                        {this.renderCollectedOrders()}
                        <button
                            className={styles.finishButton}
                            disabled={collectedOrders.length === 0 || selectedDeliveryCompany === ''}
                            onClick={this.handleFinish}
                        >
                            Finish
                        </button>
                    </div>}

            </div>
        );
    }
}

export default RegisterCollection;
