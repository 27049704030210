import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faStarHalfAlt } from '@fortawesome/free-solid-svg-icons'
import { faStar as faStarRegular} from '@fortawesome/free-regular-svg-icons';

class LibraryOrderPrintComponent extends Component {
  getIconForGamme(gamme) {
    const iconStyle = {
      width: '15px',
    };
    switch (gamme) {
      case 'premium':
        return <FontAwesomeIcon icon={faStar} style={iconStyle}/>;

      case 'classique':
        return <FontAwesomeIcon icon={faStarHalfAlt} style={iconStyle}/>;
      case 'economique':
        return <FontAwesomeIcon icon={faStarRegular} style={iconStyle}/>;
      default:
        return null;
    }
  }
    render() {
      const { recap } = this.props;
      const styles = {
        libraryOrderIdLabel: {
          fontWeight: 'bold',
        },
        card: {
          border: '2px solid #ddd',
          marginBottom: '10px',
          padding: '10px',
        },
        cardHeader: {
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '10px',
          borderBottom: '2px solid #ddd',
        },
        cardBody: {
          paddingLeft: '20px',
        },
        newItemLabel: {
          fontWeight: 'bold',
        },
        quantity: {
          fontWeight: 'bold',
        },
      };
  
      // Sort recapItems by emplacement.id (assuming emplacement is an object with an id property)
      const sortedRecapItems = recap.recapItems.sort((a, b) => a.emplacement.id - b.emplacement.id);
  
      return (
        <div >
          <div style={styles.libraryOrderIdLabel}>LibraryOrderId: {recap.libraryOrderId}</div>
          {sortedRecapItems.map((recapItem, index) => (
            <div key={index} style={styles.card}>
              <div style={styles.cardHeader}>
              <span style={styles.newItemLabel}>
                  {recapItem.newCarton ? "New" : " "}
                </span>
                <span>{recapItem.idCarton}</span>
                <span>{recapItem.emplacement.name}</span>
              </div>
              <div style={styles.cardBody}>
                {recapItem.studentOrderItems.map((item, itemIndex) => (
                  <div key={itemIndex}>
                    <span style={styles.quantity}>{item.quantity}</span> 
                    {item.articleType !== 'Livre' && item.gamme ? this.getIconForGamme(item.gamme) : null}
                    {item.fournitureName}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      );
    }
  }

export default LibraryOrderPrintComponent;