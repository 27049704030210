// Import necessary modules
import React, { Component } from 'react';
import axios from 'axios';
import styles from './RegisterExpense.module.css'; // Import CSS module
import getAxiosInstance from '../../axios-clicks';
import Spinner from '../../components/UI/Spinner/Spinner';

class RegisterExpense extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expenseTypes: [],
            sourceAccounts: [],
            selectedExpenseType: '',
            selectedSourceAccount: '',
            description: '',
            amount: '',
            isFormValid: false,
            successMessage: '',
            errorMessage: '',
            showForm: false,
            loading: false,
        };
        this.axiosInstance = getAxiosInstance(this.props.sandbox);
    }

    fetchOptions = () => {
        this.setState({ loading: true });
        axios.all([
            this.axiosInstance.get('/finances/register-expense-expense-types'),
            this.axiosInstance.get('/finances/register-expense-account-names')
        ])
            .then(axios.spread((expenseTypesRes, sourceAccountsRes) => {
                const transformedExpenseTypes = expenseTypesRes.data.map((name, index) => ({
                    id: index, // or any other unique identifier logic
                    name: name
                }));
                const transformedSourceAccounts = sourceAccountsRes.data.map((name, index) => ({
                    id: index, // or any other unique identifier logic
                    name: name
                }));
                this.setState({
                    expenseTypes: transformedExpenseTypes,
                    sourceAccounts: transformedSourceAccounts,
                    showForm: true,
                    loading: false,
                });
            }))
            .catch(error => {
                const errorMessage = error.response && error.response.data ? error.response.data : 'Error fetching options.';
                this.setState({ errorMessage: errorMessage, successMessage: '', loading: false });
            });
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value }, this.validateForm);
    }

    validateForm = () => {
        const { selectedExpenseType, selectedSourceAccount, description, amount } = this.state;
        const isAmountValid = parseFloat(amount) > 0;
        this.setState({
            isFormValid: selectedExpenseType && selectedSourceAccount && description && isAmountValid
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({ loading: true });
        const { selectedExpenseType, selectedSourceAccount, description, amount } = this.state;
        this.axiosInstance.post('/finances/register-expense', {
            expenseType: selectedExpenseType,
            sourceAccount: selectedSourceAccount,
            description,
            amount: parseFloat(amount),
        })
            .then(response => {
                this.setState({ successMessage: 'Expense registered successfully!', errorMessage: '', loading: false });
            })
            .catch(error => {
                const errorMessage = error.response && error.response.data ? error.response.data : 'Error registering expense.';
                this.setState({ errorMessage: errorMessage, successMessage: '', loading: false });
            });
    }

    render() {
        const { expenseTypes, sourceAccounts, isFormValid, successMessage, errorMessage, showForm } = this.state;
        return (
            <div className={styles.expenseForm}>
                {this.state.loading && <Spinner />}
                {!this.state.loading && (
                    <>
                        <h1>Register New Expense</h1>
                        <button onClick={this.fetchOptions}>New Expense</button>
                        {showForm && (
                            <form onSubmit={this.handleSubmit}>
                                <select name="selectedExpenseType" onChange={this.handleChange} value={this.state.selectedExpenseType}>
                                    <option value="">Select Expense Type</option>
                                    {expenseTypes.map((type, index) => <option key={index} value={type.name}>{type.name}</option>)}
                                </select>
                                <select name="selectedSourceAccount" onChange={this.handleChange} value={this.state.selectedSourceAccount}>
                                    <option value="">Select Source Account</option>
                                    {sourceAccounts.map((account, index) => <option key={index} value={account.name}>{account.name}</option>)}
                                </select>
                                <input type="text" name="description" placeholder="Description" onChange={this.handleChange} />
                                <input type="number" name="amount" placeholder="Amount" step="0.01" onChange={this.handleChange} />
                                <button type="submit" disabled={!isFormValid}>Done</button>
                            </form>
                        )}
                        {successMessage && <p className={styles.success}>{successMessage}</p>}
                        {errorMessage && <p className={styles.error}>{errorMessage}</p>}
                    </>
                )}
            </div>
        );
    }
}

export default RegisterExpense;
