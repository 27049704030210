import React, { Component } from 'react';
import classes from './Preparation.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faHouse, faThumbsUp, faThumbsDown, faTag, faStar, faStarHalfAlt  } from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarRegular} from '@fortawesome/free-regular-svg-icons';
import Modal from '../../components/UI/Modal/Modal';
import getAxiosInstance from '../../axios-clicks';
import Spinner from '../../components/UI/Spinner/Spinner';
import ReactDOM from 'react-dom';
import PreparationPrintComponent from './PreparationPrintComponent';
class Preparation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            initiated: false,
            searchTerm: "",
            scannedValue: "",
            showNotFoundLabel: false,
            isPreparing: false,
            libraryOrdersIds: [""],
            etageresIds: [],
            studentOrderCount: 0,
            selectedItems: [],
            currentTab: 'original',
            tabs: {
                original: [],
                found: [],
                notFound: []
            },
            preparationId: null,
            showItemFoundModal: false,
            modalStudentOrderItemId: null,
            modalLibraryOrderId: null,
            modalItemName: '',
            modalItemMatchName: '',
            modalItemMatchType: '',
            modalItemSchoolLevelDesignation: '',
            modalItemQuantity: 0,
            modalItemMaxQuantity: 0,
            modalItemOriginalPriced: false,
            modalItemPriced: false,
            modalItemTempBin: null,
            modalItemCodeBarres: [],
            modalInputBarcode: '',
            modalItemCorrectArticleMatching: null,
            modalItemCheckPrice: false,
            modalItemPrice: '',
            successMessage: '',
            loading: false,
            isDoneClicked: false,
            recap: null,
            showPrintRecapButton: false,

        };
        this.axiosInstance = getAxiosInstance(this.props.sandbox);
        this.scannerBuffer = ""; // Temporary buffer to store scanned input
        this.scanTimeout = null;
    }
    componentDidMount() {
        document.addEventListener('keyup', this.handleGlobalKeyUp);
    }

    componentWillUnmount() {
        document.removeEventListener('keyup', this.handleGlobalKeyUp);
    }
    handleGlobalKeyUp = (e) => {
        if (this.state.showItemFoundModal) {
            return;  // If the modal is shown, do nothing
        }
        // Ignore non-numeric and special keys except Enter.
        if (/^[0-9]$/.test(e.key) || e.key === 'Enter') {
            if (e.key === 'Enter') {
                // When Enter key is detected, set the scanned value as the search term.
                this.setState({ searchTerm: this.scannerBuffer }, () => {
                    this.checkForSingleItemMatch();
                });
                this.scannerBuffer = ""; // Clear the buffer after a scan is complete
                clearTimeout(this.scanTimeout);  // Cancel the timeout to avoid double-clearing
            } else {
                // Append only numbers to the scannerBuffer
                this.scannerBuffer += e.key;

                // Use a timeout to detect the end of the barcode input.
                clearTimeout(this.scanTimeout);
                this.scanTimeout = setTimeout(() => {
                    this.setState({ searchTerm: this.scannerBuffer });
                    this.scannerBuffer = "";
                }, 100);
            }
        }
    }
    checkForSingleItemMatch = () => {
        const { searchTerm, tabs, currentTab } = this.state;

        // Filter items based on the scanned barcode.
        const matchingItems = tabs[currentTab].filter(item =>
            item.fournitureName.includes(searchTerm)
        );

        // If only one item matches, automatically open the modal.
        if (matchingItems.length >= 1) {
            this.handleOriginalItemClick(matchingItems[0]);
        }
        // If no items match, display the "Not Found" label.
        else if (matchingItems.length === 0) {
            this.setState({ showNotFoundLabel: true }, () => {
                setTimeout(() => {
                    this.setState({ showNotFoundLabel: false, searchTerm: '' });
                }, 1000); // remove label and clear search bar after 1 second
            });
        }
    }


    handleStartClick = () => {

        if (this.props.userId) {
            this.setState({
                initiated: true,
                isPreparing: false,
                loading: false
            });
        }
    };




    handleCheckboxChange = (itemStudentOrderItemId) => {
        const { selectedItems } = this.state;
        if (selectedItems.includes(itemStudentOrderItemId)) {
            this.setState({
                selectedItems: selectedItems.filter((studentOrderItemId) => studentOrderItemId !== itemStudentOrderItemId),
            });
        } else {
            this.setState({
                selectedItems: [...selectedItems, itemStudentOrderItemId],
            });
        }
    };

    handleMoveButtonClick = () => {
        const { selectedItems, currentTab } = this.state;
        const tabs = Object.keys(this.state.tabs);
        // Exclude both currentTab and "found" tab
        const otherTabs = tabs.filter(tab => tab !== currentTab && tab !== "found");
        if (selectedItems.length !== 0) {
            this.setState({
                showMoveModal: true,
                moveModalButtons: otherTabs,
                moveModalSelectedTab: otherTabs[0], // Select the first tab as the default
            });
        }

    };

    handleMoveModalTabButtonClick = (tab) => {
        const { selectedItems, currentTab, tabs } = this.state;

        // Get the actual items from the current tab
        const movingItems = tabs[currentTab].filter(item => selectedItems.includes(item.studentOrderItemId));

        // Existing items in the target tab are not modified in terms of merging or checking for duplicates
        const existingItems = tabs[tab];


        // Simply add movingItems to the target tab without any checks
        const updatedItems = [...existingItems, ...movingItems];


        this.setState(prevState => ({
            tabs: {
                ...prevState.tabs,
                [tab]: updatedItems,
                [currentTab]: prevState.tabs[currentTab].filter(item => !selectedItems.includes(item.studentOrderItemId)),
            },
            showMoveModal: false,
            selectedItems: [],
        }));
    };



    handleMoveModalCloseClick = () => {
        this.setState({ showMoveModal: false });
    };

    handleOriginalItemClick = (item) => {
        const { tabs, currentTab } = this.state;

        // Only proceed if the current tab is 'original'
        if (currentTab === 'original') {
            // Calculate the quantity based on the number of matching items in the current tab
            const quantity = tabs[currentTab].filter(tabItem => tabItem.studentOrderItemId === item.studentOrderItemId).length;

            // Update the state with the modal details, including the calculated quantity
            this.setState({
                showItemFoundModal: true,
                modalStudentOrderItemId: item.studentOrderItemId,
                modalLibraryOrderId: item.LibraryOrderId,
                modalItemName: item.fournitureName,
                modalItemMatchName: item.matchName,
                modalItemMatchType: item.matchType,
                modalItemSchoolLevelDesignation: item.schoolLevelDesignation,
                modalItemPriced: item.priced,
                modalItemOriginalPriced: item.priced,
                modalItemQuantity: quantity, // Use the calculated quantity
                modalItemMaxQuantity: quantity, // Assuming max quantity is also based on the count
                modalItemTempBin: item.tempBin,
                modalItemCheckPrice: item.checkPrice,
                modalItemPrice: '',
            });
        }
    };


    handleModalQuantityChange = (quantity) => {
        this.setState({ modalItemQuantity: quantity });
    };

    handleItemFoundModalFoundClick = () => {
        console.log("handleItemFoundModalFoundClick");
        const {
            modalStudentOrderItemId,
            modalItemOriginalPriced,
            modalItemPriced,
            modalItemCorrectArticleMatching,
            modalItemQuantity,
            tabs,
            currentTab,
            modalItemCheckPrice,
            modalItemPrice,
        } = this.state;
        if ((modalItemCheckPrice || !modalItemCheckPrice && (modalItemPriced !== modalItemOriginalPriced) && modalItemPriced) && ((!modalItemPrice || modalItemPrice <= 0) || modalItemCorrectArticleMatching === null)) {
            return;
        }
        // Filter items by studentOrderItemId in the current tab
        const itemsToMove = tabs[currentTab].filter(item => item.studentOrderItemId === modalStudentOrderItemId);

        // Split items based on modalItemQuantity
        const foundItems = itemsToMove.slice(0, modalItemQuantity);
        const notFoundItems = itemsToMove.slice(modalItemQuantity);


        // update price of each item in foundItems
        
        foundItems.forEach(item => {
            item.priced = modalItemPriced;
            if(modalItemCheckPrice || !modalItemCheckPrice && (modalItemPriced !== modalItemOriginalPriced) && modalItemPriced){
                item.price = modalItemPrice; // Set item price to modalItemPrice
                
                item.correctArticleMatching = modalItemCorrectArticleMatching;
            }
            
        });
        

        // Update tabs directly without preparing items since they keep the same attributes
        const updatedTabs = {
            ...tabs,
            found: [...tabs.found, ...foundItems],
            [currentTab]: tabs[currentTab].filter(item => item.studentOrderItemId !== modalStudentOrderItemId),
        };

        // Only add to notFound tab if there are items to add
        if (notFoundItems.length > 0) {
            updatedTabs.notFound = [...tabs.notFound, ...notFoundItems];
        }

        // Update the state with the new tabs and reset the modal state
        this.setState({
            tabs: updatedTabs,
            showItemFoundModal: false,
            modalItemCorrectArticleMatching: null,
            
        });
    };


    handleOptionDownloadClick = () => {
        // Simulating HTTP request to download a file
        // Replace with your actual download code
        console.log('Downloading file...');
    };

    handleOptionDoneClick = () => {
        this.setState({ loading: true });
        const { preparationId, tabs, libraryOrdersIds } = this.state;
        // Check if the original tab has items
        if (tabs.original.length > 0) {
            // Show the error message
            alert('Original contient encore des articles');
            this.setState({ loading: false });
            return; // Exit the function to avoid proceeding with the request
        }
        const payload = {
            preparationId,
            libraryOrdersIds,
            preparationItemsFound: tabs.found,
            preparationItemsNotFound: tabs.notFound,
        };

        this.axiosInstance.post('/preparation/processPreparation', payload)
            .then(response => {

                this.setState({
                    isDoneClicked: true,
                    recap: response.data,
                    showPrintRecapButton: true,
                    loading: false,
                });
            })
            .catch(error => {
                console.error("Error processing order: ", error);
                this.setState({ loading: false });
            });
    };
    addLibraryOrderInput = () => {
        this.setState(prevState => ({
            libraryOrdersIds: [...prevState.libraryOrdersIds, ""]
        }));
    }

    handleLibraryOrderInputChange = (index, event) => {
        const newlibraryOrdersIds = [...this.state.libraryOrdersIds];
        newlibraryOrdersIds[index] = event.target.value;
        this.setState({ libraryOrdersIds: newlibraryOrdersIds });
    }

    onNextClick = () => {
        this.setState({ loading: true });
        const libraryOrders = this.state.libraryOrdersIds;
        this.axiosInstance.get('/libraryOrders/get-student-order-count', { params: { libraryOrders: libraryOrders.join(",") } })
            .then(response => {
                const count = response.data.count;
                const shelvesRequired = Math.ceil(count / 12);
                this.setState({
                    studentOrderCount: count,
                    etageresIds: new Array(shelvesRequired).fill(""),
                    loading: false,
                });
            })
            .catch(error => {
                let errorMessage = "An unexpected error occurred";

                // Check if the error response and data are defined
                if (error.response && error.response.data) {
                    // Attempt to use a detailed error message if it exists
                    if (error.response.data.message) {
                        errorMessage = error.response.data.message;
                    } else {
                        // If there's no message, but there is data, stringify it if it's an object
                        // This is helpful in cases where error data might be JSON
                        errorMessage = typeof error.response.data === 'object' ? JSON.stringify(error.response.data) : error.response.data;
                    }
                } else {
                    // If there's no response from the server (network error, server down, etc.)
                    if (error.message) {
                        errorMessage = error.message;
                    }
                }
                this.setState({ loading: false });
                alert(errorMessage);
                // Handle error
            });
    }

    handleShelfInputChange = (index, event) => {
        const newetageresIds = [...this.state.etageresIds];
        newetageresIds[index] = event.target.value;
        this.setState({ etageresIds: newetageresIds });
    }

    onStartPreparation = () => {
        const { libraryOrdersIds, etageresIds } = this.state;

        this.setState({ loading: true });
        const userId = this.props.userId;

        // Prepare the data to be sent
        const params = new URLSearchParams();
        libraryOrdersIds.forEach(id => params.append('libraryOrdersIds', id));
        etageresIds.forEach(id => params.append('etageresIds', id));

        this.axiosInstance.post(`/preparation/fetch-preparation-items/${userId}?${params.toString()}`)
            .then((response) => {
                const { preparationId, preparationItemsRequest } = response.data;

                if (preparationItemsRequest.length === 0) { // if no articles
                    alert('No preparation items fetched');
                    this.setState({ loading: false });
                    return;
                } else {
                    this.setState({
                        preparationId,
                        isPreparing: true,
                        tabs: {
                            ...this.state.tabs,
                            original: preparationItemsRequest,
                        },
                        searchTerm: "",
                    });
                }
                this.setState({ loading: false });
            })
            .catch((error) => {
                let errorMessage = "An unexpected error occurred";

                // Check if the error response and data are defined
                if (error.response && error.response.data) {
                    // Attempt to use a detailed error message if it exists
                    if (error.response.data.message) {
                        errorMessage = error.response.data.message;
                    } else {
                        // If there's no message, but there is data, stringify it if it's an object
                        // This is helpful in cases where error data might be JSON
                        errorMessage = typeof error.response.data === 'object' ? JSON.stringify(error.response.data) : error.response.data;
                    }
                } else {
                    // If there's no response from the server (network error, server down, etc.)
                    if (error.message) {
                        errorMessage = error.message;
                    }
                }
                this.setState({ loading: false });
                alert(errorMessage);
            });
    }

    handleSearchChange = (e) => {
        this.setState({ searchTerm: e.target.value });
    }
    searchForBarcode(barcode) {
        // Here, you can add any logic specific to searching for a barcode.t  
        // For now, we'll just update the searchTerm in the state.
        this.setState({ searchTerm: barcode });
    }
    handleKeyUp = (e) => {
        if (e.key === 'Enter') {
            // If Enter key is pressed, assume that a barcode is scanned
            // and initiate a search. You can extend this logic if necessary.
            this.searchForBarcode(e.target.value);
        }
    }
    handleModalInputChange = (e) => {
        this.setState({ modalInputBarcode: e.target.value });
    }

    handleSaveBarcode = () => {
        this.setState({ loading: true });
        const { modalItemId, modalItemName, modalInputBarcode, currentTab } = this.state;

        if (!modalInputBarcode.trim()) {
            alert('Please enter or scan a barcode first.');
            return; // Exit the function
        }

        // Ensure you change the URL and method accordingly to match your API requirements.
        this.axiosInstance.post('/article/save-barcode', {
            itemId: modalItemId,
            barcode: modalInputBarcode
        })
            .then(response => {

                this.setState(prevState => {
                    // Get the current tab's items
                    const currentItems = [...prevState.tabs[currentTab]];



                    return {
                        tabs: {
                            ...prevState.tabs,
                            [currentTab]: currentItems
                        },
                        successMessage: 'Barcode saved successfully!',
                        modalInputBarcode: '', // Clear the modal input after saving
                        loading: false,
                    };
                });

                // Clear the success message after 3 seconds
                setTimeout(() => {
                    this.setState({ successMessage: '' });
                }, 3000);
            })
            .catch(error => {
                this.setState({ loading: false });
                console.error(error);
            });
    };
    handleFinishClick = () => {

        this.setState({
            initiated: false,
            searchTerm: "",
            scannedValue: "",
            showNotFoundLabel: false,
            isPreparing: false,
            libraryOrdersIds: [""],
            etageresIds: [],
            studentOrderCount: 0,
            selectedItems: [],
            currentTab: 'original',
            tabs: {
                original: [],
                found: [],
                notFound: []
            },
            preparationId: null,
            showItemFoundModal: false,
            modalStudentOrderItemId: null,
            modalLibraryOrderId: null,
            modalItemName: '',
            modalItemMatchName: '',
            modalItemMatchType: '',
            modalItemSchoolLevelDesignation: '',
            modalItemOriginalPriced: false,
            modalItemPriced: false,
            modalItemCorrectArticleMatching: null,
            modalItemQuantity: 0,
            modalItemMaxQuantity: 0,
            modalItemTempBin: null,
            modalItemCheckPrice: false,
            modalItemPrice: '',
            modalItemCodeBarres: [],
            modalInputBarcode: '',
            successMessage: '',
            isDoneClicked: false,
            recap: null,
            showPrintRecapButton: false,
            loading: false,
        });
    }
    handleSelectAllCheckboxChange = () => {
        const { selectedItems, tabs, currentTab } = this.state;
        let newSelectedItems;

        if (selectedItems.length < tabs[currentTab].length) {
            // If not all items are selected, select all
            newSelectedItems = tabs[currentTab].map(item => item.studentOrderItemId);
        } else {
            // If all items are already selected, deselect all
            newSelectedItems = [];
        }

        this.setState({ selectedItems: newSelectedItems });
    }

    openFileTab = (fileList) => {
        const newTab = window.open();
        if (!newTab) {
            alert('Popup blocked! Please allow popups for this website.');
            return;
        }

        newTab.document.write('<html><head><title>New Tab</title></head><body>');

        fileList.forEach(file => {
            const binaryStr = atob(file.fileData); // Decode base64 for binary content
            const len = binaryStr.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
                bytes[i] = binaryStr.charCodeAt(i);
            }

            let contentType = '';
            if (file.fileName.endsWith('.pdf')) {
                contentType = 'application/pdf';
            } else if (file.fileName.endsWith('.jpg') || file.fileName.endsWith('.jpeg')) {
                contentType = 'image/jpeg';
            } else if (file.fileName.endsWith('.png')) {
                contentType = 'image/png';
            }

            const blob = new Blob([bytes], { type: contentType });
            const url = URL.createObjectURL(blob);

            if (contentType === 'application/pdf') {
                newTab.document.write(`<iframe src="${url}" width="100%" height="800px" type="${contentType}"></iframe>`);
            } else if (contentType === 'image/jpeg' || contentType === 'image/png') {
                newTab.document.write(`<img src="${url}" alt="File Image" style="max-width: 100%; height: auto;"/>`);
            }
        });

        newTab.document.write('</body></html>');
        newTab.document.close();
    };
    handlePrintRecap = () => {
        const printWindow = window.open('', '_blank');
        if (!printWindow) {
          alert("Please allow popups for this site.");
          return;
        }
        printWindow.document.write("<h2>Loading...</h2>");
        this.printRecap(printWindow);
      }
      printRecap = (printWindow) => {
        console.log("inside printRecap");
    
        ReactDOM.render(<PreparationPrintComponent recap={this.state.recap} />, printWindow.document.body);
        printWindow.document.title = "Print Items";
    
        // Delay the print command slightly to ensure the content has rendered
        printWindow.setTimeout(() => {
          printWindow.print();
          // printWindow.close();
        }, 250); // Adjust delay as necessary
      };

    

    handleShowFiles = () => {
        const { modalStudentOrderItemId } = this.state;
        this.axiosInstance.get(`/files/getFilesByStudentOrderItemId/${modalStudentOrderItemId}`)
            .then(response => {
                if (response.status === 200) {
                    const fileList = response.data;
                    this.openFileTab(fileList);
                } else {
                    alert("No files found for this item.");
                }
            })
            .catch(error => {
                console.error("Error fetching files:", error);
                alert("Error fetching files. Please try again.");
            });


    };

    handleTogglePriced = () => {
        this.setState({ modalItemPriced: !this.state.modalItemPriced });
    };

    handleCorrectMatchingRadioChange = (event) => {
        this.setState({ modalItemCorrectArticleMatching: event.target.value === 'yes' });
    };

    getIconForGamme(gamme) {
        switch (gamme) {
          case 'premium':
            return <FontAwesomeIcon icon={faStar} />;
    
          case 'classique':
            return <FontAwesomeIcon icon={faStarHalfAlt} />;
          case 'economique':
            return <FontAwesomeIcon icon={faStarRegular} />;
          default:
            return null;
        }
      }

    renderToolbar() {
        const { currentTab } = this.state;

        const tabs = ['original', 'found', 'notFound', 'option'];

        return (


            <ul className={classes.ScrollableStudentsMenu}>
                {tabs.map((tab, index) => (
                    <li
                        key={tab}
                        className={`${classes.ScrollableStudentsMenuItem} ${currentTab === tab ? classes.ActiveStudentItem : ""}`}
                        onClick={() => this.setState({ currentTab: tab })}
                    >
                        {tab === 'option' ? <FontAwesomeIcon icon={faCog} /> : tab}
                    </li>
                ))}
            </ul>
        );
    }
    renderSearchBar() {
        return (
            <input
                type="text"
                placeholder="Search or Scan Barcode..."
                value={this.state.searchTerm}
                onChange={this.handleSearchChange}
            />
        );
    }
    renderSelectedItems() {
        const { selectedItems, tabs, currentTab } = this.state;

        if (currentTab === "option") {
            return null;
        }

        // Determine if the "Select All" checkbox should be checked
        const isAllSelected = tabs[currentTab].length === selectedItems.length;

        return (
            <div>
                <input
                    type="checkbox"
                    checked={isAllSelected}
                    onChange={this.handleSelectAllCheckboxChange}
                />
                <label>Selected items: {selectedItems.length}</label>
                <button className={classes.NeutreButton}
                    onClick={this.handleMoveButtonClick}
                    disabled={selectedItems.length === 0}>
                    Move</button>
            </div>
        );
    }



    renderOrderItem(item, quantity) {
        return (
            <div key={item.studentOrderItemId}>
                <input
                    type="checkbox"
                    checked={this.state.selectedItems.includes(item.studentOrderItemId)}
                    onChange={() => this.handleCheckboxChange(item.studentOrderItemId)}
                />
                <span onClick={() => this.handleOriginalItemClick(item)}>
                    <strong>{quantity}</strong> - 
                    {item.matchType !== 'Livre' && item.gamme ? this.getIconForGamme(item.gamme) : null}
                    {item.fournitureName}  
                    {item.schoolLevelDesignation && item.matchType === "Livre" && `(${item.schoolLevelDesignation})`}
                </span>
            </div>
        );
    }

    renderOrderItems() {
        const { currentTab, tabs, searchTerm } = this.state;
        const items = tabs[currentTab];

        let filteredItems = items;
        if (searchTerm && searchTerm.trim() !== '') {
            filteredItems = items.filter(item =>
                item.fournitureName.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }

        // Group items by studentOrderItemId and count them
        const groupedItems = filteredItems.reduce((acc, item) => {
            if (!acc[item.studentOrderItemId]) {
                acc[item.studentOrderItemId] = { ...item, quantity: 1 };
            } else {
                acc[item.studentOrderItemId].quantity += 1;
            }
            return acc;
        }, {});

        const groupedItemsArray = Object.values(groupedItems);
        if (groupedItemsArray.length) {
            return (
                <div>
                    {groupedItemsArray.map((groupedItem) =>
                        this.renderOrderItem(groupedItem, groupedItem.quantity)
                    )}
                </div>
            );
        } else {
            return null;
        }
    }

    renderMoveModal() {
        const { showMoveModal, moveModalButtons } = this.state;

        return (
            showMoveModal && (
                <Modal show={showMoveModal}
                    modalClosed={this.handleMoveModalCloseClick}>
                    <div className={classes.MoveModalButtonsContainer}>
                        {/* <h3>Select a tab to move the items:</h3> */}
                        {moveModalButtons.map(tab => (
                            <button
                                key={tab}
                                onClick={() => this.handleMoveModalTabButtonClick(tab)}
                                className={classes.MoveIconButton}

                            >
                                {tab === 'original' && <FontAwesomeIcon icon={faHouse} className={classes.OriginalTab} />}
                                {tab === 'found' && <FontAwesomeIcon icon={faThumbsUp} className={classes.ThumbUp} />}
                                {tab === 'notFound' && <FontAwesomeIcon icon={faThumbsDown} className={classes.ThumbDown} />}
                            </button>
                        ))}
                    </div>
                </Modal>
            )
        );
    }
    renderItemFoundModal() {
        const { showItemFoundModal, modalItemName, modalItemMatchName, modalItemMatchType, modalItemSchoolLevelDesignation, modalItemQuantity, modalItemMaxQuantity, modalItemTempBin, modalItemPriced, modalItemOriginalPriced, modalItemCheckPrice, modalItemCorrectArticleMatching, modalItemPrice } = this.state;

        return (
            showItemFoundModal && (
                <Modal show={showItemFoundModal} modalClosed={() => this.setState({ showItemFoundModal: false, modalInputBarcode: '' })}>
                    
                        <div className={classes.PartialModalContent}>
                            <div className={classes.TopButtonsContainer}>

                                <button className={classes.ShowFilesButton} onClick={this.handleShowFiles} >Show Files</button>
                                <div className={classes.PricedButtonsContainer} onClick={this.handleTogglePriced}>
                                    <div className={classes.PricedIconContainer}>
                                        <FontAwesomeIcon icon={faTag} className={`${classes.PricedIcon} ${modalItemPriced ? classes.PricedIconActive : ''}`} />
                                    </div>
                                    <div className={classes.PricedIconContainer}>
                                        <FontAwesomeIcon icon={faTag} className={`${classes.PricedIcon} ${!modalItemPriced ? classes.PricedIconActive : ''}`} />
                                        {<div className={classes.strikethrough} />}
                                    </div>
                                </div>
                                </div>
                                <label className={classes.ModalItemName}>
                                    <strong>{modalItemName} {modalItemSchoolLevelDesignation && modalItemMatchType === 'Livre' && `(${modalItemSchoolLevelDesignation})`}</strong>
                                </label>
                                {(modalItemMaxQuantity > 1 &&
                                <div className={classes.QuantityContainer}>
                                    <button
                                        className={classes.DicreaseQuantityButton}
                                        onClick={() => this.handleModalQuantityChange(modalItemQuantity - 1)}
                                        disabled={modalItemQuantity <= 1}
                                    >
                                        -
                                    </button>
                                    <span className={classes.PartialModalQuantity}>{modalItemQuantity}</span>
                                    <button
                                        className={classes.IncreaseQuantityButton}
                                        onClick={() => this.handleModalQuantityChange(modalItemQuantity + 1)}
                                        disabled={modalItemQuantity >= modalItemMaxQuantity}
                                    >
                                        +
                                    </button>
                                </div>)}
                                <div className={classes.QuantityContainer}>

                                    {/* Conditionally rendering span based on etageresIds length */}
                                    {this.state.etageresIds && this.state.etageresIds.length > 1 && (
                                        <span className={classes.PartialModalQuantity}>
                                            {modalItemTempBin ? modalItemTempBin.idEtagere : 'N/A'}
                                        </span>
                                    )}

                                    <span className={`${classes.PartialModalQuantity} ${modalItemTempBin && modalItemTempBin.color === 'Rouge' ? classes.redColor :
                                        modalItemTempBin && modalItemTempBin.color === 'Vert' ? classes.greenColor :
                                            modalItemTempBin && modalItemTempBin.color === 'Noir' ? classes.blackColor :
                                                modalItemTempBin && modalItemTempBin.color === 'Bleu' ? classes.blueColor : ''
                                        }`}>
                                        {modalItemTempBin ? modalItemTempBin.name : 'N/A'}
                                    </span>

                                </div>


                            
                            {
                                (modalItemCheckPrice || !modalItemCheckPrice && (modalItemPriced !== modalItemOriginalPriced) && modalItemPriced) && (
                                    <>
                                        <div className={classes.CorrectMatchingContainer}>
                                            <div className={classes.CorrectMatchingContent}>
                                                <p className={classes.CorrectMatchingArticleName}>{modalItemMatchName}</p>
                                                <div>
                                                    <label className={classes.CorrectMatchingLabel}>
                                                        <input
                                                            className={classes.CorrectMatchingInput}
                                                            type="radio"
                                                            name="correctMatch"
                                                            value="yes"
                                                            checked={modalItemCorrectArticleMatching === true}
                                                            onChange={this.handleCorrectMatchingRadioChange}
                                                        />
                                                        Yes
                                                    </label>
                                                    <label className={classes.CorrectMatchingLabel}>
                                                        <input
                                                            className={classes.CorrectMatchingInput}
                                                            type="radio"
                                                            name="correctMatch"
                                                            value="no"
                                                            checked={modalItemCorrectArticleMatching === false}
                                                            onChange={this.handleCorrectMatchingRadioChange}
                                                        />
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={classes.ModalPrice}>
                                            <input
                                                type="number"
                                                value={modalItemPrice}
                                                onChange={(e) => this.setState({ modalItemPrice: e.target.value })}
                                                placeholder="Enter price"
                                                style={{ border: modalItemPrice > 0 ? "" : "2px solid red" }}
                                                autoFocus
                                            />
                                        </div>
                                    </>
                                )
                            }
                            <button onClick={this.handleItemFoundModalFoundClick}>
                                <FontAwesomeIcon icon={faThumbsUp} className={classes.ThumbUp} />
                            </button>
                        </div>
                    
                </Modal>
            )
        );
    }

    renderOptionTab() {
        return (
            <div className="option-tab">
                <div className="library-order-id">
                    ID: {this.state.preparationId}
                </div>
                {/* <button className={classes.ValidateButton} onClick={this.handleOptionDownloadClick}>Download</button> */}
                {!this.state.isDoneClicked && (<button
                    className={classes.ValidateButton}
                    onClick={this.handleOptionDoneClick}
                    disabled={this.state.isDoneClicked}
                >
                    Done
                </button>)}
                {this.state.showPrintRecapButton && (<button
                    className={classes.FinishButton}
                    onClick={this.handlePrintRecap}
                >
                    Print Recap
                </button>)
                }

                {(this.state.isDoneClicked && <button
                    className={classes.FinishButton}
                    onClick={this.handleFinishClick}
                    disabled={!this.state.isDoneClicked}
                >
                    Finish
                </button>)}
            </div>
        );
    }

    render() {

        return (
            <div >
                {!this.state.initiated ? (
                    <div className={classes.GoContainer} >
                        {this.state.loading ? (
                            <Spinner />
                        ) : (
                            <>
                                <button className={classes.GoButton} onClick={this.handleStartClick}>Start</button>
                                {this.state.fetchAttempted && !this.state.hasArticles && <div>No articles</div>}
                            </>
                        )}
                    </div>
                ) : (
                    <div>
                        {this.state.loading ? (
                            <Spinner />
                        ) : (
                            <>
                                {!this.state.isPreparing ? (
                                    <div className={classes.InitPreparation}>
                                        {this.state.libraryOrdersIds.map((input, index) => (
                                            <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                                <label style={{ marginRight: '10px' }}>Enter library order id:</label>
                                                <input
                                                    type="text"
                                                    value={input}
                                                    onChange={(e) => this.handleLibraryOrderInputChange(index, e)}
                                                    style={{ marginRight: '10px' }}
                                                />

                                            </div>
                                        ))}
                                        <button onClick={this.addLibraryOrderInput}>+</button>

                                        {this.state.studentOrderCount > 0 && this.state.etageresIds.map((input, index) => (
                                            <div key={index}>
                                                <label>Enter Etagere id:</label>
                                                <input
                                                    type="text"
                                                    value={input}
                                                    onChange={e => this.handleShelfInputChange(index, e)}
                                                />
                                            </div>
                                        ))}

                                        {this.state.libraryOrdersIds.length > 0 && <button onClick={this.onNextClick}>Next</button>}
                                        {this.state.studentOrderCount > 0 && <button onClick={this.onStartPreparation}>Start Preparation</button>}
                                    </div>
                                ) : (
                                    <div>
                                        {this.renderToolbar()}
                                        {this.state.currentTab !== 'option' && this.renderSearchBar()}
                                        {this.state.showNotFoundLabel && <span style={{ color: 'red' }}>Not Found</span>}
                                        {this.renderSelectedItems()}
                                        {this.state.currentTab !== 'option' && this.renderOrderItems()}
                                        {this.state.currentTab === 'option' && this.renderOptionTab()}
                                        {this.renderItemFoundModal()}
                                    </div>
                                )}
                                {this.renderMoveModal()}
                            </>
                        )}</div>
                )}
            </div>
        );
    }
}

export default Preparation;




