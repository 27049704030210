import React, { Component } from 'react';
import styles from './RegisterDeliveryFailure.module.css';
import getAxiosInstance from '../../axios-clicks';
import Spinner from '../../components/UI/Spinner/Spinner'
import Modal from '../../components/UI/Modal/Modal';

class RegisterDeliveryFailure extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deliveryCompanies: [],
            ordersOutForDelivery: [],
            searchRef: '',
            ordersFailed: [],
            selectedDeliveryCompany: '',
            showFailedOrderModal: false,
            showFinishModal: false,
            selectedOrder: null,
            ordersFetched: false,
            loading: false
        }; this.axiosInstance = getAxiosInstance(this.props.sandbox);
    }

    componentDidMount() {
        this.fetchDeliveryCompanies();
    }

    fetchDeliveryCompanies = async () => {
        this.setState({ loading: true });
        this.axiosInstance.get('/delivery/deliveryCompanies')
            .then(response => {

                this.setState({ deliveryCompanies: response.data, loading: false });
            })
            .catch(error => {
                let errorMessage = "An unexpected error occurred";

                // Check if the error response and data are defined
                if (error.response && error.response.data) {
                    // Attempt to use a detailed error message if it exists
                    if (error.response.data.message) {
                        errorMessage = error.response.data.message;
                    } else {
                        // If there's no message, but there is data, stringify it if it's an object
                        // This is helpful in cases where error data might be JSON
                        errorMessage = typeof error.response.data === 'object' ? JSON.stringify(error.response.data) : error.response.data;
                    }
                } else {
                    // If there's no response from the server (network error, server down, etc.)
                    if (error.message) {
                        errorMessage = error.message;
                    }
                }
                alert(errorMessage);
                this.setState({ loading: false });
            });
    };

    fetchOrdersOutForDelivery = async () => {
        this.setState({ loading: true });
        const { selectedDeliveryCompany, deliveryCompanies } = this.state;
        const company = deliveryCompanies.find(c => c.name === selectedDeliveryCompany);
        const companyId = company ? company.id : null;

        if (!companyId) {
            this.setState({ loading: false });
            return;
        }
        this.axiosInstance.get(`/delivery/ordersOutForDelivery/${companyId}`)
            .then(response => {

                this.setState({ ordersOutForDelivery: response.data, loading: false, ordersFetched: true });
            })
            .catch(error => {
                let errorMessage = "An unexpected error occurred";

                // Check if the error response and data are defined
                if (error.response && error.response.data) {
                    // Attempt to use a detailed error message if it exists
                    if (error.response.data.message) {
                        errorMessage = error.response.data.message;
                    } else {
                        // If there's no message, but there is data, stringify it if it's an object
                        // This is helpful in cases where error data might be JSON
                        errorMessage = typeof error.response.data === 'object' ? JSON.stringify(error.response.data) : error.response.data;
                    }
                } else {
                    // If there's no response from the server (network error, server down, etc.)
                    if (error.message) {
                        errorMessage = error.message;
                    }
                }
                alert(errorMessage);
                this.setState({ loading: false, selectedDeliveryCompany: '' });
            });
    };




    handleDeliveryCompanyChange = (event) => {
        this.setState({ selectedDeliveryCompany: event.target.value }, () => {
            this.fetchOrdersOutForDelivery();
        });
    };
    handleOrderFailedModal = (order) => {
        // Set the state to show the modal and set the selected order
        this.setState({
            showFailedOrderModal: true,
            selectedOrder: order
        });
        console.log('Open modal for order:', order);
    };

    renderFailedOrderModal() {
        const { showFailedOrderModal, selectedOrder} = this.state;
        if (!showFailedOrderModal || !selectedOrder) return null;
        return (
            <Modal show={showFailedOrderModal} modalClosed={this.handleCloseModal}>
                <div>
                    <p>Ref: {selectedOrder?.reference}</p>
                    
                    <p>Total: {selectedOrder.totalSellingPrice + selectedOrder.deliveryFee}</p>
                    
                    <button
                        onClick={this.handleSetOrderFailed}
                        className={styles.modalYesButton}
                    >
                        OK
                    </button>
                </div>
            </Modal>
        );
    }

    handleSetOrderFailed = () => {
        const { selectedOrder, ordersOutForDelivery, ordersFailed } = this.state;

        // Remove selectedOrder from ordersOutForDelivery
        const updatedOrdersOutForDelivery = ordersOutForDelivery.filter(order => order.id !== selectedOrder.id);

        // Add updatedOrder to ordersFailed
        const updatedOrdersFailed = [...ordersFailed, selectedOrder];

        // Reset state
        this.setState({
            ordersOutForDelivery: updatedOrdersOutForDelivery,
            ordersFailed: updatedOrdersFailed,
            showFailedOrderModal: false,
            selectedOrder: null,
        });
    };

    handleCloseModal = () => {
        this.setState({
            showFailedOrderModal: false,
            selectedOrder: null,
        });
    };



    renderDeliveryCompanySelection() {
        const { deliveryCompanies, selectedDeliveryCompany } = this.state;
        return (
            <select value={selectedDeliveryCompany} onChange={this.handleDeliveryCompanyChange} className={styles.dropdown}>
                <option value="" disabled={selectedDeliveryCompany !== ''}>Choose a delivery company</option>
                {deliveryCompanies.map((company) => (
                    <option key={company.id} value={company.name}>{company.name}</option>
                ))}
            </select>
        );
    }

    renderOrdersOutForDelivery() {
        const { ordersOutForDelivery, searchRef, ordersFetched } = this.state;
        if (!ordersFetched) {
            return;
        }
        const filteredOrders = ordersOutForDelivery.filter(order => order.reference.toLowerCase().includes(searchRef.toLowerCase()));

        return (
            <div>
                <input
                    type="text"
                    placeholder="Search ref"
                    value={searchRef}
                    onChange={(e) => this.setState({ searchRef: e.target.value })}
                />
                <div className={styles.ordersList}>
                    {filteredOrders.map((order) => (
                        <div key={order.id} className={styles.orderLine}>
                            <span>order: {order.id}</span>
                            <span>ref: {order.reference}</span>
                            <span>Total: {order.totalSellingPrice + order.deliveryFee}</span>
                            <span>{order.status}</span>
                            <button onClick={() => this.handleOrderFailedModal(order)}>click</button>
                        </div>
                    ))}
                </div>
            </div>
        );
    }

   
    renderFailedOrders() {
        const { ordersFailed, selectedDeliveryCompany } = this.state;
        return (
            <div className={styles.ordersList}>
                {ordersFailed.map(order => (
                    <div key={order.id} className={styles.deliveredOrderLine}>
                        <div className={styles.requestedOrderID}>Order: {order.id}</div>
                        <span>ref: {order.reference}</span>
                        <span>Total: {order.totalSellingPrice + order.deliveryFee}</span>
                        <span>{order.status}</span>
                        <button onClick={() => this.handleCancelOrderDelivered(order)}>Cancel</button>
                    </div>
                ))}
            </div>
        );
    }
    handleCancelOrderDelivered = (orderToCancel) => {
        const { ordersOutForDelivery, ordersFailed } = this.state;

        const updatedOrdersOutForDelivery = [...ordersOutForDelivery, orderToCancel];
        const updatedOrdersDelivered = ordersFailed.filter(order => order.id !== orderToCancel.id);

        // Reset state
        this.setState({
            ordersOutForDelivery: updatedOrdersOutForDelivery,
            ordersFailed: updatedOrdersDelivered
        });
    };
    handleFinish = () => {
        // Set the state to show the modal and set the selected order
        this.setState({
            showFinishModal: true
        });
    };
    handleFinishConfirmed = () => {
        console.log(this.state.ordersFailed);
        this.setState({ loading: true });
        const { selectedDeliveryCompany, ordersFailed } = this.state;
        this.axiosInstance.post('/delivery/register-orders-failed-to-deliver', {
            deliveryCompanyName: selectedDeliveryCompany,
            deliveredOrders: ordersFailed,
        })
            .then(response => {
                alert('Successfully requested');
                this.resetState();
            })
            .catch(error => {
                let errorMessage = "An unexpected error occurred";

                // Check if the error response and data are defined
                if (error.response && error.response.data) {
                    // Attempt to use a detailed error message if it exists
                    if (error.response.data.message) {
                        errorMessage = error.response.data.message;
                    } else {
                        // If there's no message, but there is data, stringify it if it's an object
                        // This is helpful in cases where error data might be JSON
                        errorMessage = typeof error.response.data === 'object' ? JSON.stringify(error.response.data) : error.response.data;
                    }
                } else {
                    // If there's no response from the server (network error, server down, etc.)
                    if (error.message) {
                        errorMessage = error.message;
                    }
                }
                alert(errorMessage);
                this.setState({
                    loading: false
                });
            });
    }
    handleFinishAborted = () => {
        this.setState({
            showFinishModal: false
        });
    };
    renderFinishModal() {
        const { showFinishModal} = this.state;
        if (!showFinishModal) return null;

        return (
            <Modal show={showFinishModal} modalClosed={this.handleFinishAborted}>
                <div>
                    <p>Are you sure ?</p>

                    <div className={styles.modalButtonContainer}>
                    <button
                        onClick={this.handleFinishConfirmed}
                        className={styles.modalYesButton}
                    >
                        YES
                    </button>
                    <button
                        onClick={this.handleFinishAborted}
                        className={styles.modalNoButton}
                    >
                        NO
                    </button>
                    </div>
                </div>
            </Modal>
        );
    }
    resetState() {
        this.setState({
            ordersOutForDelivery: [],
            searchRef: '',
            ordersFailed: [],
            selectedDeliveryCompany: '',
            showFailedOrderModal: false,
            showFinishModal: false,
            selectedOrder: null,
            ordersFetched: false,
            loading: false
        });
    }

    renderFinishButton() {
        const { ordersFailed, selectedDeliveryCompany, ordersFetched } = this.state;

        if (!ordersFetched) {
            return;
        }
        return (
            <button
                className={styles.finishButton}
                disabled={ordersFailed.length === 0 || selectedDeliveryCompany === '' }
                onClick={this.handleFinish}
            >
                Finish
            </button>
        );
    }

    render() {
        const {loading } = this.state;
        if (loading) {
            return <Spinner />;
        }
        return (
            <div className={styles.container}>
                {this.renderDeliveryCompanySelection()}
                {this.renderOrdersOutForDelivery()}
                {this.renderFailedOrderModal()}
                {this.renderFailedOrders()}
                {this.renderFinishModal()}
                {this.renderFinishButton()}

            </div>
        );
    }
}

export default RegisterDeliveryFailure;
