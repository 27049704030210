import React, { Component } from 'react';
import getAxiosInstance from '../../axios-clicks';
import Modal from '../../components/UI/Modal/Modal';
import Button from '../../components/UI/Button/Button';
import styles from './RoleCrud.module.css';
import Spinner from '../../components/UI/Spinner/Spinner';
import { extractRequestErrorMessage } from '../../components/Utils/ExtractRequestErrorMessage';

class RoleCrud extends Component {
    constructor(props) {
        super(props);
        this.state = {
            roles: [],
            showCreateModal: false,
            showEditModal: false,
            showDeleteModal: false,
            currentRole: { id: null, roleName: '' },
            loading: false,
        }; this.axiosInstance = getAxiosInstance(this.props.sandbox);
    }

    componentDidMount() {
        this.fetchRoles();
    }

    fetchRoles = () => {
        this.setState({ loading: true });
        this.axiosInstance.get('/role')
            .then(response => {
                this.setState({ roles: response.data, loading: false });
            })
            .catch(error => {
                const errorMessage = extractRequestErrorMessage(error);
                alert(errorMessage);
                this.setState({ loading: false });
            });
    };

    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState(prevState => ({
            currentRole: { ...prevState.currentRole, [name]: value }
        }));
    };

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({ loading: true });
        const role = this.state.currentRole;

        if (role.id) {
            this.axiosInstance.put(`/role/${role.id}`, role)
                .then(this.fetchRoles)
                .catch(error => {
                    const errorMessage = extractRequestErrorMessage(error);
                    alert(errorMessage);
                    this.setState({ loading: false });
                });
        } else {
            this.axiosInstance.post('/role', role)
                .then(this.fetchRoles)
                .catch(error => {
                    const errorMessage = extractRequestErrorMessage(error);
                    alert(errorMessage);
                    this.setState({ loading: false });
                });
        }
        this.closeModal();
    };

    openModal = (type, role = { id: null, roleName: '' }) => {
        this.setState({
            [type]: true,
            currentRole: role
        });
    };

    closeModal = () => {
        this.setState({
            showCreateModal: false,
            showEditModal: false,
            showDeleteModal: false
        });
    };


    renderCreateModal = () => {
        const { showCreateModal, currentRole } = this.state;
        return (
            <Modal show={showCreateModal} modalClosed={this.closeModal}>
                <div>
                    <div>
                        <label htmlFor="roleName">Role Name:</label>
                        <input
                            type="text"
                            id="roleName"
                            name="roleName"
                            value={currentRole.roleName}
                            onChange={this.handleInputChange}
                            required
                        />
                    </div>
                    <div className={styles.modalButtonContainer}>
                        <Button btnType="Success" clicked={this.handleSubmit}>Save</Button>
                        <Button btnType="Danger" clicked={this.closeModal}>Cancel</Button>
                    </div>
                </div>
            </Modal>
        );
    };

    
    


    render() {
        const { roles, loading } = this.state;
        if (loading) {
            return <Spinner />;
        }
        return (
            <div>
                <button className={`${styles.button} ${styles.newButton}`} onClick={() => this.openModal('showCreateModal')}>New Role</button>
                <table className={styles.roleTable}>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Role Name</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {roles.map(role => (
                            <tr key={role.id}>
                                <td>{role.id}</td>
                                <td>{role.roleName}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                {this.renderCreateModal()}
            </div>
        );
    }
}

export default RoleCrud;
