import React, { Component } from 'react';
import getAxiosInstance from '../../axios-clicks';
import styles from './Reception.module.css'; // Import CSS module
import Modal from '../../components/UI/Modal/Modal';
import ModalContent from './utils/ModalContent';
import Spinner from '../../components/UI/Spinner/Spinner';

class Reception extends Component {

  constructor(props) {
    super(props);
    // Initialize state
    this.state = {
      recolteId: '',
      libraries: null,
      error: '',
      showModal: false,
      selectedLibraryId: null,
      invoices: {},
      loading: false,
    };

    this.axiosInstance = getAxiosInstance(this.props.sandbox);
  }

  handleInputChange = (event) => {
    this.setState({ recolteId: event.target.value });
  };

  fetchLibraries = () => {
    this.setState({ loading: true });
    this.axiosInstance.get(`/recolte/libraries/${this.state.recolteId}`)
      .then(response => {
        console.log("fetched libraries:");
        console.log(response.data);
        const libraries = response.data;
        const invoices = {};
        libraries.forEach(library => {
          invoices[library.libraryId] = [{ total: '', credit: '', paymentMethod: 'en compte' }];
        });
        this.setState({ libraries, invoices, error: '', loading: false });
      })
      .catch(error => {
        let errorMessage = "An unexpected error occurred";

        // Check if the error response and data are defined
        if (error.response && error.response.data) {
          // Attempt to use a detailed error message if it exists
          if (error.response.data.message) {
            errorMessage = error.response.data.message;
          } else {
            // If there's no message, but there is data, stringify it if it's an object
            // This is helpful in cases where error data might be JSON
            errorMessage = typeof error.response.data === 'object' ? JSON.stringify(error.response.data) : error.response.data;
          }
        } else {
          // If there's no response from the server (network error, server down, etc.)
          if (error.message) {
            errorMessage = error.message;
          }
        }
        this.setState({ error: errorMessage, libraries: null, loading: false });
      });
  };


  openModal = (libraryId) => {
    this.setState({ showModal: true, selectedLibraryId: libraryId });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  addInvoice = (libraryId) => {
    const { invoices } = this.state;
    const currentInvoices = invoices[libraryId] || [];

    if (currentInvoices.length < 5) { // Enforce the maximum limit
      const newInvoice = { total: '', credit: '', paymentMethod: 'en compte' };
      this.setState({
        invoices: {
          ...invoices,
          [libraryId]: [...currentInvoices, newInvoice],
        },
      });
    }
  };

  removeInvoice = (libraryId) => {
    const { invoices } = this.state;
    if (invoices[libraryId] && invoices[libraryId].length > 1) {
      const updatedInvoices = [...invoices[libraryId]]; // Clone the array for immutability
      updatedInvoices.pop(); // Remove the last invoice
      this.setState({
        invoices: {
          ...invoices,
          [libraryId]: updatedInvoices,
        },
      });
    }
  };

  // Checks if the invoices of a single library are valid
  isLibraryInvoicesValid = (libraryId) => {
    const libraryInvoices = this.state.invoices[libraryId];
    const totalFormat = /^-?\d+(\.\d{1,2})?$/; // Matches numbers with up to 2 decimal places, can be negative
    const creditFormat = /^\d+(\.\d{1,2})?$/; // Matches positive numbers with up to 2 decimal places
    return libraryInvoices.every(invoice =>
      invoice.total !== '' && totalFormat.test(invoice.total) &&
      invoice.credit !== '' && creditFormat.test(invoice.credit));
  };
  checkInvoicesValidity = () => {
    const { invoices } = this.state;
    const totalFormat = /^-?\d+(\.\d{1,2})?$/; // Allows negative numbers
    const creditFormat = /^\d+(\.\d{1,2})?$/; // Only positive numbers or zero
    return Object.values(invoices).every(libraryInvoices => libraryInvoices.every(invoice =>
      invoice.total !== '' && totalFormat.test(invoice.total) &&
      invoice.credit !== '' && creditFormat.test(invoice.credit)));
  };



  saveInvoices = (libraryId) => {
    // Placeholder for save logic
    console.log(this.state.invoices[libraryId]);
    this.closeModal();
  };

  finishProcess = () => {
    const { recolteId, libraries, invoices } = this.state;
    console.log("invoices");
    console.log(invoices);
    // Check for null or "null" library IDs
    const hasNullId = Object.keys(invoices).some(key => key === "null" || key == null);

    if (hasNullId) {
      alert('Error: libraryId is null');
      return; // Prevent the request from being sent
    }
    // Transform invoices into the format expected by the backend
    const transformedInvoices = Object.entries(invoices).reduce((acc, [libraryId, libraryInvoices]) => {
      // Convert each invoice to InvoiceDto format
      const invoiceDtos = libraryInvoices.map(invoice => ({
        libraryId: Number(libraryId), // Ensure libraryId is a Long
        libraryName: libraries.find(lib => lib.libraryId === libraryId)?.libraryName || '', // Match library name by ID
        total: Number(invoice.total),
        credit: Number(invoice.credit),
        paymentMethod: invoice.paymentMethod,
      }));

      acc[libraryId] = invoiceDtos; // Assign the transformed list to the corresponding library ID
      return acc;
    }, {});

    const payload = {
      recolteId,
      invoices: transformedInvoices,
    };


    this.setState({ loading: true });
    this.axiosInstance.post('/recolte/finishReception', payload)
      .then(response => {
        alert('Successful');
        this.setState({
          recolteId: '',
          libraries: null,
          error: '',
          showModal: false,
          selectedLibraryId: null,
          invoices: {},
          loading: false
        });
      })
      .catch(error => {
        let errorMessage = "An unexpected error occurred";

        // Check if the error response and data are defined
        if (error.response && error.response.data) {
          // Attempt to use a detailed error message if it exists
          if (error.response.data.message) {
            errorMessage = error.response.data.message;
          } else {
            // If there's no message, but there is data, stringify it if it's an object
            // This is helpful in cases where error data might be JSON
            errorMessage = typeof error.response.data === 'object' ? JSON.stringify(error.response.data) : error.response.data;
          }
        } else {
          // If there's no response from the server (network error, server down, etc.)
          if (error.message) {
            errorMessage = error.message;
          }
        }
        this.setState({ loading: false });
        alert(errorMessage);
      });
  };


  render() {
    const { recolteId, libraries, error, showModal, selectedLibraryId, invoices } = this.state;

    return (
      <div className={styles.reception}>
        {this.state.loading && <Spinner />}
        {!this.state.loading && (
          <>
            <label>Enter the recolte ID:</label>
            <input
              type="text"
              value={recolteId}
              onChange={this.handleInputChange}
              className={styles.input}
            />
            <button onClick={this.fetchLibraries} className={`${styles.button} ${styles.submitButton}`}>Submit</button>
            {error && <div className={styles.error}>{error}</div>}
            {libraries && (
              <div className={styles.libraries}>
                {libraries.map(library => (
                  <div key={library.libraryId} className={styles.library}>
                    {library.libraryName} ({this.state.invoices[library.libraryId].length})
                    <span className={styles.icon}>
                      {this.isLibraryInvoicesValid(library.libraryId) ? (
                        <span>✅</span> // This represents a valid icon, replace with your icon as needed
                      ) : (
                        <span>⚠️</span> // This represents a warning icon, replace with your icon as needed
                      )}
                    </span>
                    <button onClick={() => this.openModal(library.libraryId)} className={`${styles.button} ${styles.manageButton}`}>Manage</button>
                  </div>
                ))}
              </div>
            )}

            {showModal && (
              <Modal show={showModal} modalClosed={this.closeModal}>
                <ModalContent
                  libraryId={selectedLibraryId}
                  invoices={invoices[selectedLibraryId] || []}
                  addInvoice={this.addInvoice}
                  removeInvoice={this.removeInvoice}
                  saveInvoices={() => this.saveInvoices(selectedLibraryId)}
                />
              </Modal>
            )}
            {
              this.checkInvoicesValidity() && <button onClick={this.finishProcess} className={`${styles.button} ${styles.finishButton}`}>Finish</button>
            }
          </>
        )}


      </div>
    );
  }
}

export default Reception;
