import React, { Component } from 'react';
import RegisterExpense from './RegisterExpense';
import getAxiosInstance from '../../axios-clicks';
import SalariesComponent from './SalariesComponent';
import TakeLoan from './TakeLoan';
import PayLoan from './PayLoan';
import Investment from './Investment';
import RetreiveCashFromBank from './RetreiveCashFromBank';
import TransferCashToBank from './TransferCashToBank';
import PrepaidExpense from './PrepaidExpense';
import PrepaidChangeReturn from './PrepaidChangeReturn';

class Finances extends Component {
    constructor(props) {
        super(props);
        this.axiosInstance = getAxiosInstance(this.props.sandbox);
  }

  render() {
    return (
      <div>
        <RegisterExpense userId={this.props.userId} sandbox={this.props.sandbox}/>
        <SalariesComponent userId={this.props.userId} sandbox={this.props.sandbox}/>
        <TakeLoan userId={this.props.userId} sandbox={this.props.sandbox}/>
        <PayLoan userId={this.props.userId} sandbox={this.props.sandbox}/>
        <Investment userId={this.props.userId} sandbox={this.props.sandbox}/>
        <RetreiveCashFromBank userId={this.props.userId} sandbox={this.props.sandbox}/>
        <TransferCashToBank userId={this.props.userId} sandbox={this.props.sandbox}/>
        <PrepaidExpense userId={this.props.userId} sandbox={this.props.sandbox}/>
        <PrepaidChangeReturn userId={this.props.userId} sandbox={this.props.sandbox}/>

      </div>
    );
  }
}

export default Finances;