import React from 'react';
import getAxiosInstance from '../../axios-clicks';
import styles from './OrdersOverviewComponent.module.css';
import Spinner from '../../components/UI/Spinner/Spinner';


class OrdersOverviewComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ordersOverview: null,
            loading: true,
            error: null,
        };
        this.axiosInstance = getAxiosInstance(this.props.sandbox);
    }

    componentDidMount() {
        this.axiosInstance.get('/orders/orders-overview')
            .then(response => {
                this.setState({
                    ordersOverview: response.data,
                    loading: false
                });
            })
            .catch(error => {
                let errorMessage = "An unexpected error occurred";

                // Check if the error response and data are defined
                if (error.response && error.response.data) {
                    // Attempt to use a detailed error message if it exists
                    if (error.response.data.message) {
                        errorMessage = error.response.data.message;
                    } else {
                        // If there's no message, but there is data, stringify it if it's an object
                        // This is helpful in cases where error data might be JSON
                        errorMessage = typeof error.response.data === 'object' ? JSON.stringify(error.response.data) : error.response.data;
                    }
                } else {
                    // If there's no response from the server (network error, server down, etc.)
                    if (error.message) {
                        errorMessage = error.message;
                    }
                }
                this.setState({
                    loading: false,
                    error: errorMessage
                });
            });
    }

    render() {
        const { loading, ordersOverview, error } = this.state;
        if (loading) {
            return <Spinner />;
        }

        if (error) {
            return <div className={styles.error}>Error: {error}</div>;
        }

        return (
            <div className={styles.ordersOverview}>
                <h1>Orders Overview</h1>
                <div className={styles.section}>
                    <h2>New Orders</h2>
                    <p>{ordersOverview.newOrdersCount}</p>
                </div>
                <div className={styles.section}>
                    <h2>Grouping Orders</h2>
                    <p>{ordersOverview.groupingOrdersCount}</p>
                </div>
                <div className={styles.section}>
                    <h2>Quoting Orders</h2>
                    <p>{ordersOverview.quotingOrdersCount}</p>
                </div>
                <div className={styles.section}>
                    <h2>Waiting For Validation Orders</h2>
                    <p>{ordersOverview.waitingForValidationOrdersCount}</p>
                </div>
                <div className={styles.section}>
                    <h2>Being Prepared Orders</h2>
                    <p>{ordersOverview.beingPreparedOrdersCount}</p>
                </div>
                <div className={styles.section}>
                    <h2>Pending Search/Deliver Choice</h2>
                    <p>{ordersOverview.pendingSearchDeliverChoiceOrdersCount}</p>
                </div>
                <div className={styles.section}>
                    <h2>Waiting Guarantee Payment Response</h2>
                    <p>{ordersOverview.waitingGuaranteePaymentResponseOrdersCount}</p>
                </div>
                <div className={styles.section}>
                    <h2>Being Delivered Orders</h2>
                    <p>{ordersOverview.beingDeliveredOrdersCount}</p>
                </div>
                <div className={styles.section}>
                    <h2>Delivered Orders</h2>
                    <p>{ordersOverview.deliveredOrdersCount}</p>
                </div>
                <div className={styles.section}>
                    <h2>Cancelled Orders</h2>
                    <p>{ordersOverview.cancelledOrdersCount}</p>
                </div>
            </div>
        );
    }
}

export default OrdersOverviewComponent;
