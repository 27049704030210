import React, { Component } from 'react';
import getAxiosInstance from '../../axios-clicks';
import Modal from '../../components/UI/Modal/Modal';
import classes from './OptionTabComponent.module.css';
import Button from '../../components/UI/Button/Button';
import Spinner from '../../components/UI/Spinner/Spinner';

class OptionTabComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
        recolteId: null,
        errorMessage: '',
        showModal: false,
        modalContent: null,
        loading: false,
    };this.axiosInstance = getAxiosInstance(this.props.sandbox);
}

    componentDidMount() {
        this.fetchRecolte();
    }

    fetchRecolte = () => {
        const { userId } = this.props;
        this.setState({ loading: true });
        this.axiosInstance.get(`/recolte/new/${userId}`)
            .then(response => {
                const { data } = response;
                if (data.recolteId) {
                    this.setState({ recolteId: data.recolteId });
                } else {
                    this.setState({ errorMessage: data.message });
                }
                this.setState({ loading: false });
            })
            .catch(error => {
                console.error('Fetching recolte failed:', error);
                this.setState({ loading: false });});
    };

    handleDoneClick = () => {
        this.setState({
            showModal: true,
            modalContent: (
                <div>
                  <p >Voulez vous continuer ?</p>
                  <Button btnType="Danger" clicked={this.handleCloseModal}>NO</Button>
                  <Button btnType="Success" clicked={this.handleConfirmEndRecolte}>YES</Button>
                </div>
              )
        });
    };

    handleCloseModal = () => {
        this.setState({ showModal: false });
    };

    handleConfirmEndRecolte = () => {
        const { recolteId } = this.state;
        this.setState({ loading: true });
        this.axiosInstance.post(`/recolte/end/${recolteId}`)
            .then(() => {
                this.setState({
                    showModal: true,
                    modalContent: (`Recolte ${recolteId} ended successfully`),
                    recolteId: null,
                    loading: false,
                });
            })
            .catch(error => {
                console.error('Ending recolte failed:', error);
                this.setState({ errorMessage: 'Error ending recolte', showModal: false , loading:false});
            });
    };

    render() {
        const { recolteId, errorMessage, showModal, modalContent } = this.state;
        return (
            <div>
                 {this.state.loading && <Spinner />}
        {!this.state.loading && (
          <>
            {recolteId && <div>{`Recolte ID: ${recolteId}`} <button className={classes.RecolteButton} onClick={this.handleDoneClick}>Done</button></div>}
                {errorMessage && <div className={classes.errorLabel}>{errorMessage}</div>}
                {!recolteId && !errorMessage && <div>No ongoing recoltes.</div>}

                {showModal && (
                    <Modal show={showModal} modalClosed={this.handleCloseModal}>
                    {modalContent}
                  </Modal>
                )}
          </>
        )}
                
            </div>
        );
    }
}

export default OptionTabComponent;
