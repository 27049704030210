import React, { Component } from 'react';
import styles from './DeliveryRemittance.module.css';
import getAxiosInstance from '../../axios-clicks';
import Spinner from '../../components/UI/Spinner/Spinner'
import Modal from '../../components/UI/Modal/Modal';

class DeliveryRemittance extends Component {
    static COLLECTED_STATUS = "Collected";
    static RETURNED_BY_DELIVERY_COMPANY_STATUS = "Returned by delivery company";
    constructor(props) {
        super(props);
        this.state = {
            deliveryCompanies: [],
            ordersOutForDelivery: [],
            searchRef: '',
            ordersDelivered: [],
            selectedDeliveryCompany: '',
            showDeliveredOrderModal: false,
            showFinishModal: false,
            selectedOrder: null,
            collectionFees: null,
            deliveryCost: null,
            paymentMethod: null,
            ordersFetched: false,
            loading: false
        }; this.axiosInstance = getAxiosInstance(this.props.sandbox);
    }

    componentDidMount() {
        this.fetchDeliveryCompanies();
    }

    fetchDeliveryCompanies = async () => {
        this.setState({ loading: true });
        this.axiosInstance.get('/delivery/deliveryCompanies')
            .then(response => {

                this.setState({ deliveryCompanies: response.data, loading: false });
            })
            .catch(error => {
                let errorMessage = "An unexpected error occurred";

                // Check if the error response and data are defined
                if (error.response && error.response.data) {
                    // Attempt to use a detailed error message if it exists
                    if (error.response.data.message) {
                        errorMessage = error.response.data.message;
                    } else {
                        // If there's no message, but there is data, stringify it if it's an object
                        // This is helpful in cases where error data might be JSON
                        errorMessage = typeof error.response.data === 'object' ? JSON.stringify(error.response.data) : error.response.data;
                    }
                } else {
                    // If there's no response from the server (network error, server down, etc.)
                    if (error.message) {
                        errorMessage = error.message;
                    }
                }
                alert(errorMessage);
                this.setState({ loading: false });
            });
    };

    fetchOrdersOutForDelivery = async () => {
        this.setState({ loading: true });
        const { selectedDeliveryCompany, deliveryCompanies } = this.state;
        const company = deliveryCompanies.find(c => c.name === selectedDeliveryCompany);
        const companyId = company ? company.id : null;

        if (!companyId) {
            this.setState({ loading: false });
            return;
        }
        this.axiosInstance.get(`/delivery/ordersOutForAndCancelledAtDelivery/${companyId}`)
            .then(response => {

                this.setState({ ordersOutForDelivery: response.data, loading: false, ordersFetched: true });
            })
            .catch(error => {
                let errorMessage = "An unexpected error occurred";

                // Check if the error response and data are defined
                if (error.response && error.response.data) {
                    // Attempt to use a detailed error message if it exists
                    if (error.response.data.message) {
                        errorMessage = error.response.data.message;
                    } else {
                        // If there's no message, but there is data, stringify it if it's an object
                        // This is helpful in cases where error data might be JSON
                        errorMessage = typeof error.response.data === 'object' ? JSON.stringify(error.response.data) : error.response.data;
                    }
                } else {
                    // If there's no response from the server (network error, server down, etc.)
                    if (error.message) {
                        errorMessage = error.message;
                    }
                }
                alert(errorMessage);
                this.setState({ loading: false, selectedDeliveryCompany: '' });
            });
    };




    handleDeliveryCompanyChange = (event) => {
        this.setState({ selectedDeliveryCompany: event.target.value }, () => {
            this.fetchOrdersOutForDelivery();
        });
    };
    handleOrderDeliveredModal = (order) => {
        // Set the state to show the modal and set the selected order
        this.setState({
            showDeliveredOrderModal: true,
            selectedOrder: order
        });
        console.log('Open modal for order:', order);
    };

    renderDeliveredOrderModal() {
        const { showDeliveredOrderModal, selectedOrder, deliveryCost, paymentMethod } = this.state;
        if (!showDeliveredOrderModal || !selectedOrder) return null;
        const isPaymentMethodRequired = selectedOrder.status === this.COLLECTED_STATUS;
        return (
            <Modal show={showDeliveredOrderModal} modalClosed={this.handleCloseModal}>
                <div>
                    <p>Ref: {selectedOrder?.reference}</p>
                    {selectedOrder.status ===this.RETURNED_BY_DELIVERY_COMPANY_STATUS && (
                        <p style={{ color: "red" }}>Cancelled</p>
                    )}
                    <p>Total: {selectedOrder.status === this.COLLECTED_STATUS ? (selectedOrder.totalSellingPrice + selectedOrder.deliveryFee) : "-"}</p>
                    <input
                        type="text"
                        value={deliveryCost}
                        onChange={this.handleCostChange}
                        placeholder="delivery cost"
                    />
                    <select
                        value={paymentMethod}
                        onChange={this.handlePaymentMethodChange}
                    >
                        <option value="">Payment Method</option>
                        <option value="Bank">Bank</option>
                        <option value="Cash">Cash</option>
                        <option value="Cheque">Cheque</option>
                    </select>
                    <button
                        onClick={this.handleSetOrderDelivered}
                        disabled={isPaymentMethodRequired ? (!paymentMethod || deliveryCost < 0 || !deliveryCost) : (deliveryCost < 0 || !deliveryCost)}
                        className={styles.modalYesButton}
                    >
                        OK
                    </button>
                </div>
            </Modal>
        );
    }

    handleCostChange = (e) => {
        const cost = e.target.value;
        // Allow only numbers and non-negative integers
        if (!cost || cost.match(/^\d+$/)) {
            this.setState({ deliveryCost: cost });
        }
    };

    handlePaymentMethodChange = (event) => {
        this.setState({ paymentMethod: event.target.value });
    }

    handleSetOrderDelivered = () => {
        const { selectedOrder, deliveryCost, paymentMethod, ordersOutForDelivery, ordersDelivered } = this.state;

        // Update selectedOrder's deliveryCost
        const updatedOrder = { ...selectedOrder, deliveryCost: parseFloat(deliveryCost), paymentMethod: paymentMethod };

        // Remove selectedOrder from ordersOutForDelivery
        const updatedOrdersOutForDelivery = ordersOutForDelivery.filter(order => order.id !== selectedOrder.id);

        // Add updatedOrder to ordersDelivered
        const updatedOrdersDelivered = [...ordersDelivered, updatedOrder];

        // Reset state
        this.setState({
            ordersOutForDelivery: updatedOrdersOutForDelivery,
            ordersDelivered: updatedOrdersDelivered,
            showDeliveredOrderModal: false,
            selectedOrder: null,
            deliveryCost: '',
            paymentMethod: ''
        });
    };

    handleCloseModal = () => {
        this.setState({
            showDeliveredOrderModal: false,
            selectedOrder: null,
            deliveryCost: null
        });
    };



    renderDeliveryCompanySelection() {
        const { deliveryCompanies, selectedDeliveryCompany } = this.state;
        return (
            <select value={selectedDeliveryCompany} onChange={this.handleDeliveryCompanyChange} className={styles.dropdown}>
                <option value="" disabled={selectedDeliveryCompany !== ''}>Choose a delivery company</option>
                {deliveryCompanies.map((company) => (
                    <option key={company.id} value={company.name}>{company.name}</option>
                ))}
            </select>
        );
    }

    renderOrdersOutForDelivery() {
        const { ordersOutForDelivery, searchRef, ordersFetched } = this.state;
        if (!ordersFetched) {
            return;
        }
        const filteredOrders = ordersOutForDelivery.filter(order => order.reference.toLowerCase().includes(searchRef.toLowerCase()));

        return (
            <div>
                <input
                    type="text"
                    placeholder="Search ref"
                    value={searchRef}
                    onChange={(e) => this.setState({ searchRef: e.target.value })}
                />
                <div className={styles.ordersList}>
                    {filteredOrders.map((order) => (
                        <div key={order.id} className={styles.orderLine}>
                            <span>order: {order.id}</span>
                            <span>ref: {order.reference}</span>
                            <span>Total: {order.status === this.COLLECTED_STATUS ? (order.totalSellingPrice + order.deliveryFee) : "-"}</span>
                            <span>{order.status}</span>
                            <button onClick={() => this.handleOrderDeliveredModal(order)}>click</button>
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    renderCollectionFeesInput() {
        const { collectionFees, ordersFetched } = this.state;
    
        if (!ordersFetched) {
            return;
        }
    
        return (
            <div>
                <input
                    type="text"
                    placeholder="Collection fees"
                    value={collectionFees === null ? '' : collectionFees} // Handle null value to prevent issues
                    onChange={this.handleCollectionFeesChange}
                />
            </div>
        );
    }
    
    handleCollectionFeesChange = (e) => {
        const value = e.target.value.trim(); // Remove leading and trailing whitespace
        if (value === '' || (!isNaN(value) && parseFloat(value) >= 0)) {
            this.setState({ collectionFees: value === '' ? null : parseFloat(value) }); // Set null if input is empty
        }
    }
    renderDeliveredOrders() {
        const { ordersDelivered, selectedDeliveryCompany } = this.state;
        return (
            <div className={styles.ordersList}>
                {ordersDelivered.map(order => (
                    <div key={order.id} className={styles.deliveredOrderLine}>
                        <div className={styles.requestedOrderID}>Order: {order.id}</div>
                        <span>ref: {order.reference}</span>
                        <span>Total: {order.status === this.COLLECTED_STATUS ? (order.totalSellingPrice + order.deliveryFee) : "-"}</span>
                        <span>{order.deliveryCost}</span>
                        <span>{order.paymentMethod}</span>
                        <span>{order.status}</span>
                        <button onClick={() => this.handleCancelOrderDelivered(order)}>Cancel</button>
                    </div>
                ))}
            </div>
        );
    }
    handleCancelOrderDelivered = (orderToCancel) => {
        const { ordersOutForDelivery, ordersDelivered } = this.state;

        const updatedOrdersOutForDelivery = [...ordersOutForDelivery, orderToCancel];
        const updatedOrdersDelivered = ordersDelivered.filter(order => order.id !== orderToCancel.id);

        // Reset state
        this.setState({
            ordersOutForDelivery: updatedOrdersOutForDelivery,
            ordersDelivered: updatedOrdersDelivered
        });
    };
    handleFinish = () => {
        // Set the state to show the modal and set the selected order
        this.setState({
            showFinishModal: true
        });
    };
    handleFinishConfirmed = () => {
        console.log(this.state.ordersDelivered);
        this.setState({ loading: true });
        const { selectedDeliveryCompany, ordersDelivered, collectionFees } = this.state;
        this.axiosInstance.post('/delivery/register-delivered-orders', {
            collectionFees: collectionFees,
            deliveryCompanyName: selectedDeliveryCompany,
            deliveredOrders: ordersDelivered,
        })
            .then(response => {
                alert('Successfully requested');
                this.resetState();
            })
            .catch(error => {
                let errorMessage = "An unexpected error occurred";

                // Check if the error response and data are defined
                if (error.response && error.response.data) {
                    // Attempt to use a detailed error message if it exists
                    if (error.response.data.message) {
                        errorMessage = error.response.data.message;
                    } else {
                        // If there's no message, but there is data, stringify it if it's an object
                        // This is helpful in cases where error data might be JSON
                        errorMessage = typeof error.response.data === 'object' ? JSON.stringify(error.response.data) : error.response.data;
                    }
                } else {
                    // If there's no response from the server (network error, server down, etc.)
                    if (error.message) {
                        errorMessage = error.message;
                    }
                }
                alert(errorMessage);
                this.setState({
                    loading: false
                });
            });
    }
    handleFinishAborted = () => {
        this.setState({
            showFinishModal: false
        });
    };
    renderFinishModal() {
        const { showFinishModal, ordersDelivered, collectionFees } = this.state;
        if (!showFinishModal) return null;

        const collectedOrders = ordersDelivered.filter(order => order.status === this.COLLECTED_STATUS);

        // Calculate total collected
        const totalSelled = collectedOrders.reduce((acc, order) => acc + order.totalSellingPrice, 0);

        const totalDeliveryFees = collectedOrders.reduce((acc, order) => acc + order.deliveryFee, 0);

        // Calculate total delivery cost
        const totalDeliveryCost = ordersDelivered.reduce((acc, order) => acc + order.deliveryCost, 0);

        const totalCollected = totalSelled + totalDeliveryFees
        // Calculate total payout
        const totalPayout = totalCollected - totalDeliveryCost - collectionFees;
        return (
            <Modal show={showFinishModal} modalClosed={this.handleFinishAborted}>
                <div>
                    <p>Are you sure this is correct ?</p>
                    <p>Total Payout: {totalPayout}</p>
                    <p>Total collected: {totalCollected}</p>
                    <p>Total delivery cost: {totalDeliveryCost}</p>
                    <p>Total Collection cost: {collectionFees}</p>

                    <div className={styles.modalButtonContainer}>
                    <button
                        onClick={this.handleFinishConfirmed}
                        className={styles.modalYesButton}
                    >
                        YES
                    </button>
                    <button
                        onClick={this.handleFinishAborted}
                        className={styles.modalNoButton}
                    >
                        NO
                    </button>
                    </div>
                </div>
            </Modal>
        );
    }
    resetState() {
        this.setState({
            ordersOutForDelivery: [],
            searchRef: '',
            ordersDelivered: [],
            selectedDeliveryCompany: '',
            showDeliveredOrderModal: false,
            showFinishModal: false,
            selectedOrder: null,
            collectionFees: null,
            deliveryCost: null,
            ordersFetched: false,
            loading: false
        });
    }

    renderFinishButton() {
        const { collectionFees, ordersDelivered, selectedDeliveryCompany, ordersFetched } = this.state;

        if (!ordersFetched) {
            return;
        }
        return (
            <button
                className={styles.finishButton}
                disabled={ordersDelivered.length === 0 || selectedDeliveryCompany === '' || !collectionFees}
                onClick={this.handleFinish}
            >
                Finish
            </button>
        );
    }

    render() {
        const { collectionFees, ordersDelivered, selectedDeliveryCompany, loading, ordersFetched } = this.state;
        if (loading) {
            return <Spinner />;
        }
        return (
            <div className={styles.container}>
                {this.renderDeliveryCompanySelection()}
                {this.renderOrdersOutForDelivery()}
                {this.renderDeliveredOrderModal()}
                {this.renderDeliveredOrders()}
                {this.renderCollectionFeesInput()}
                {this.renderFinishModal()}
                {this.renderFinishButton()}

            </div>
        );
    }
}

export default DeliveryRemittance;
