import React, { Component } from 'react';
import getAxiosInstance from '../../axios-clicks';
import LibraryCrud from '../Crud/LibraryCrud';
import LibraryGammeCrud from '../Crud/LibraryGammeCrud';
import UserCrud from '../Crud/UserCrud';
import RoleCrud from '../Crud/RoleCrud';
import UserRoleCrud from '../Crud/UserRoleCrud';
import EmployeeCrud from '../Crud/EmployeeCrud';
import RassembleurCrud from '../Crud/RassembleurCrud';
import PersonCrud from '../Crud/PersonCrud';
import DeliveryCompanyCrud from '../Crud/DeliveryCompanyCrud';
import classes from './Admin.module.css';
import FournitureManager from '../Crud/FournitureCrud';
import LivreCrud from '../Crud/LivreCrud';

class Admin extends Component {
    constructor(props) {
        super(props);
        this.state = {
          currentTab: '',
          
        };
        this.axiosInstance = getAxiosInstance(this.props.sandbox);
  }
  renderToolbar() {
    const { currentTab } = this.state;

    const tabs = ['Library', 'LibraryGamme', 'User', 'Role', 'UserRole', 'Employee', 'Rassembleur', 'Person', 'DeliveryCompany', 'Fourniture', 'Livre'];

    return (


      <ul className={classes.ScrollableStudentsMenu}>
        {tabs.map((tab, index) => (
          <li
            key={tab}
            className={`${classes.ScrollableStudentsMenuItem} ${currentTab === tab ? classes.ActiveStudentItem : ""}`}
            onClick={() => this.setState({ currentTab: tab })}
          >
            {tab}
          </li>
        ))}
      </ul>
    );
  }

  renderCrudComponent() {
    const { currentTab } = this.state;
    const { userId, sandbox } = this.props;

    switch (currentTab) {
        case 'Library':
            return <LibraryCrud userId={userId} sandbox={sandbox} />;
        case 'LibraryGamme':
            return <LibraryGammeCrud userId={userId} sandbox={sandbox} />;
        case 'User':
            return <UserCrud userId={userId} sandbox={sandbox} />;
        case 'Role':
            return <RoleCrud userId={userId} sandbox={sandbox} />;
        case 'UserRole':
            return <UserRoleCrud userId={userId} sandbox={sandbox} />;
        case 'Employee':
            return <EmployeeCrud userId={userId} sandbox={sandbox} />;
        case 'Rassembleur':
            return <RassembleurCrud userId={userId} sandbox={sandbox} />;
        case 'Person':
            return <PersonCrud userId={userId} sandbox={sandbox} />;
        case 'DeliveryCompany':
            return <DeliveryCompanyCrud userId={userId} sandbox={sandbox} />;
        case 'Fourniture':
            return <FournitureManager userId={userId} sandbox={sandbox} />;
        case 'Livre':
            return <LivreCrud userId={userId} sandbox={sandbox} />;
        default:
            return null;
    }
}

render() {
    return (
        <div>
            {this.renderToolbar()}
            {this.renderCrudComponent()}
        </div>
    );
}
}

export default Admin;