import React from 'react';
import getAxiosInstance from '../../axios-clicks';
import styles from './SalariesComponent.module.css';
import Spinner from '../../components/UI/Spinner/Spinner';

class PayLoan extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            persons: [],
            selectedPerson: null,
            sourceAccounts: [],
            selectedAccount: '',
            error: null,
            loading: false,
        }; this.axiosInstance = getAxiosInstance(this.props.sandbox);
    }


    handleManageClick = () => {

        this.setState({ loading: true });
        this.axiosInstance.get('/finances/persons')
            .then(response => this.setState({ persons: response.data, error: null, loading: false }))
            .then(() => this.axiosInstance.get('/finances/tresorerie-accounts'))  // Fetch source accounts
            .then(response => this.setState({ sourceAccounts: response.data, loading: false }))
            .catch(error => this.setState({ error: error.message, loading: false }));
    };

    handlePersonSelect = (event) => {
        const selectedPerson = this.state.persons.find(prs => prs.name === event.target.value);
        this.setState({ selectedPerson });
    };

    handleAccountSelect = (event) => {
        this.setState({ selectedAccount: event.target.value });
    };

    handlePay = (id, amount) => {
        if (amount > 0) {
            if (window.confirm("Confirm advance?")) {
                this.setState({ loading: true });
                this.axiosInstance.post(`/finances/payPerson/${id}`, { amount, sourceAccount: this.state.selectedAccount })
                    .then(() => {
                        this.setState({ loading: false, selectedPerson: null });
                        alert("payment given successfully!")
                        this.resetState();
                    })
                    .catch(error => {
                        // Access the response data from the error object
                        this.setState({ loading: false });
                        const errorMessage = error.response && error.response.data ? error.response.data : "An unexpected error occurred";
                        alert(`Error: ${errorMessage}`);
                    });
            }
        } else {
            alert("Invalid advance amount.");
        }
    };

    resetState = () => {

        this.setState({ 
            persons: [],
            selectedPerson: null,
            sourceAccounts: [],
            selectedAccount: '',
            error: null,
            loading: false,});
    };


    render() {
        const { persons, selectedPerson, sourceAccounts, selectedAccount, error } = this.state;

        return (
            <div className={styles.container}>
                {this.state.loading && <Spinner />}
                {!this.state.loading && (
                    <>
                        <h1>Pay Loan</h1>
                        <button onClick={this.handleManageClick}>Manage</button>
                        {error && <div className={styles.errorLabel}>{error}</div>}
                        {persons && persons.length > 0 && (
                            <select onChange={this.handlePersonSelect} defaultValue="">
                                <option value="" disabled>Select a person</option>
                                {persons.map(emp => (
                                    <option key={emp.id} value={emp.name}>{emp.name}</option>
                                ))}
                            </select>)}

                        {selectedPerson && (
                            <div>
                                <div>Name: {selectedPerson.name}</div>
                                <select onChange={this.handleAccountSelect} defaultValue="">
                                    <option value="" disabled>Select source account</option>
                                    {sourceAccounts.map(account => (
                                        <option key={account} value={account}>{account}</option>
                                    ))}
                                </select>

                                <div>
                                    <label>Pay</label>
                                    <input type="number" onChange={event => this.setState({ paymentAmount: event.target.value })} />
                                    <button disabled={!selectedAccount} onClick={() => this.handlePay(selectedPerson.id, this.state.paymentAmount)}>Pay</button>
                                </div>
                            
                            </div>
                        )}
                    </>
                )}
            </div>
        );
    }
}

export default PayLoan;
