import React, { Component } from 'react';

class PreparationPrintComponent extends Component {
  render() {
      const { recap } = this.props;
      const styles = {
          container: {
              fontFamily: 'Arial, sans-serif',
              padding: '20px',
          },
          header: {
              textAlign: 'center',
              marginBottom: '30px',
          },
          etagereIdLabel: {
              fontWeight: 'bold',
              fontSize: '18px',
              marginBottom: '10px',
          },
          card: {
              border: '2px solid #ddd',
              marginBottom: '20px',
              borderRadius: '5px',
              padding: '15px',
              boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
          },
          cardHeader: {
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '10px',
              borderBottom: '2px solid #ddd',
              paddingBottom: '5px',
          },
          cardBody: {
              paddingLeft: '20px',
          },
          newItemLabel: {
              fontWeight: 'bold',
              fontWeight: 'bold',
              display: 'block',
              marginBottom: '10px',
          },
          listItem: {
              border: '1px solid #ddd',
              padding: '10px',
              borderRadius: '5px',
              marginBottom: '10px',
              position: 'relative',
          },
          listItemContent: {
              display: 'flex',
              flexDirection: 'column',
          }
      };

      return (
          <div style={styles.container}>
              <h1 style={styles.header}>Preparation Recap</h1>
              {recap.etageresRecap.map((etagereRecap, etagereIndex) => (
                  <div key={etagereIndex} style={styles.card}>
                      <div style={styles.etagereIdLabel}>Etagere ID: {etagereRecap.etagereId}</div>
                      <ul>
                          {etagereRecap.etagereItemsRecap.map((item, itemIndex) => (
                              <li key={itemIndex} style={styles.listItem}>
                                  {item.newCarton && <span style={styles.newItemLabel}>New Carton</span>}
                                  <div style={styles.listItemContent}>
                                      <div>Carton ID: {item.cartonId}</div>
                                      <div>TempBin: {item.tempBin.name}</div>
                                      <div>Emplacement: {item.emplacement}</div>
                                      
                                  </div>
                              </li>
                          ))}
                      </ul>
                  </div>
              ))}
          </div>
      );
  }
}

export default PreparationPrintComponent;