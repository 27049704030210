import React, { Component } from 'react';
import getAxiosInstance  from '../../axios-clicks';
import ReactDOM from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import styles from './CartonManager.module.css';
import Modal from '../../components/UI/Modal/Modal';
import CartonEtiquette from './CartonEtiquette';

class CartonManager extends Component {
  constructor(props) {
    super(props);
    // Initialize state
    this.state = {
    cartonId: '',
    etiquetteInfo: null,
    found: null,
    cartonIds: [],
    showModal: false,
    modalContent: '',
    loading: false,
  };
  this.axiosInstance = getAxiosInstance(this.props.sandbox);
}

  handleInputChange = (event) => {
    this.setState({ cartonId: event.target.value });
  };

  searchCarton = () => {
    if (!this.state.cartonId) {
        return; // Exit the function early if cartonId is falsy
      }
    this.setState({loading:true});
    this.axiosInstance.get(`carton/get-related-cartons/${this.state.cartonId}`)
      .then(response => {
        const { found, cartonIds, etiquetteInfo  } = response.data;
        this.setState({ found, cartonIds, etiquetteInfo, loading:false });
      })
      .catch(error => {
        this.setState({showModal:true, modalContent:'Error searching', loading:false});
        console.error('Error fetching carton data:', error);
      });
  };

  handlePrintEtiquetteClick = () => {
    const printWindow = window.open('', '_blank');
          if (!printWindow) {
            alert("Please allow popups for this site.");
            return;
          }
          printWindow.document.write("<h2>Loading...</h2>");
          this.printEtiquette(this.state.etiquetteInfo, printWindow);
  };
  printEtiquette = (etiquetteInfo, printWindow) => {

    ReactDOM.render(<CartonEtiquette etiquetteInfo={etiquetteInfo} />, printWindow.document.body);
    printWindow.document.title = "Print Etiquette";

    // Delay the print command slightly to ensure the content has rendered
    printWindow.setTimeout(() => {
      printWindow.print();
      // printWindow.close();
    }, 250); // Adjust delay as necessary
  };

  handleCreateBox = () => {
    this.setState({
      showModal: true,
      modalContent: (
        <div>
          <p>Do you really want to create a box?</p>
          <button onClick={this.confirmCreateBox}>Yes</button>
          <button onClick={this.handleCloseModal}>No</button>
        </div>
      )
    });
  };

  handleCreateActiveBox = () => {
    this.setState({
      showModal: true,
      modalContent: (
        <div>
          <p>Do you really want to create an active box?</p>
          <button onClick={this.confirmCreateActiveBox}>Yes</button>
          <button onClick={this.handleCloseModal}>No</button>
        </div>
      )
    });
  };

  confirmCreateBox = () => {
    this.setState({loading:true});
    this.axiosInstance.get(`carton/create-carton/${this.state.cartonId}`)
      .then(response => {
        this.setState({showModal:true, modalContent:'Created successfully !', loading:false });
      })
      .catch(error => {
        this.setState({showModal:true, modalContent:'Error searching',loading:false});
        console.error('Error fetching carton data:', error);
      });
    this.handleCloseModal();
  };

  confirmCreateActiveBox = () => {
    this.setState({loading:true});
    this.axiosInstance.get(`carton/create-active-carton/${this.state.cartonId}`)
      .then(response => {
        this.setState({showModal:true, modalContent:'Created successfully !', loading:false });
      })
      .catch(error => {
        this.setState({showModal:true, modalContent:'Error searching',loading:false});
        console.error('Error fetching carton data:', error);
      });
    this.handleCloseModal();
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  renderModal = () => {
    const { showModal, modalContent } = this.state;
    return showModal && (
      <Modal show={showModal} modalClosed={this.handleCloseModal}>
        {modalContent}
      </Modal>
    );
  };

  render() {
    const { cartonId, found, cartonIds } = this.state;
    return (
      <div className={styles.container}>
        <label className={styles.label}>Enter carton ID:</label>
        <div className={styles.inputButtonContainer}>
            <input
            type="text"
            value={cartonId}
            onChange={this.handleInputChange}
            className={styles.input}
            />
            <button onClick={this.searchCarton} className={styles.button}>
            <FontAwesomeIcon icon={faSearch} />
            </button>
        </div>
        {found === false && <div className={styles.notFound}>Not found</div>}
        {found && <div className={styles.found}>{cartonIds.join(', ')}</div>}
        {found && (
          <div>
            <button onClick={this.handlePrintEtiquetteClick} className={styles.actionButton}>Print Etiquette</button>
            <button onClick={this.handleCreateActiveBox} className={styles.actionButton}>Create Active Box</button>
            <button onClick={this.handleCreateBox} className={styles.actionButton}>Create Box</button>
          </div>
        )}
        {this.renderModal()}
      </div>
    );
  }
}

export default CartonManager;
