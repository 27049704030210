import React, { Component } from 'react';
import getAxiosInstance from '../../axios-clicks';
import Modal from '../../components/UI/Modal/Modal';
import Button from '../../components/UI/Button/Button';
import styles from './UserRoleCrud.module.css';
import Spinner from '../../components/UI/Spinner/Spinner';
import { extractRequestErrorMessage } from '../../components/Utils/ExtractRequestErrorMessage';

class UserRoleCrud extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userRoles: [],
            users: [],
            roles: [],
            showCreateModal: false,
            showDeleteModal: false,
            currentUserRole: { userId: '', userName: '', roleId: '', roleName: '', sandBox: false },
            loading: false,
        };
        this.axiosInstance = getAxiosInstance(this.props.sandbox);
    }

    componentDidMount() {
        this.fetchUserRoles();
        this.fetchUsers();
        this.fetchRoles();
    }

    fetchUserRoles = () => {
        this.setState({ loading: true });
        this.axiosInstance.get('/userRole')
            .then(response => {
                this.setState({ userRoles: response.data, loading: false });
            })
            .catch(error => {
                alert('Failed to fetch user roles');
                this.setState({ loading: false });
            });
    };

    fetchUsers = () => {
        this.axiosInstance.get('/user')
            .then(response => {
                this.setState({ users: response.data });
            })
            .catch(error => {
                alert('Failed to fetch users');
            });
    };

    fetchRoles = () => {
        this.axiosInstance.get('/role')
            .then(response => {
                this.setState({ roles: response.data });
            })
            .catch(error => {
                alert('Failed to fetch roles');
            });
    };

    handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        let val = type === "checkbox" ? checked : value;
        if (name === "sandBox") {
            val = value === "true"; // Convert string "true"/"false" to boolean
        }
        this.setState(prevState => ({
            currentUserRole: { ...prevState.currentUserRole, [name]: val }
        }));
    };
    

    handleSubmit = (event) => {
        event.preventDefault();
        const { userId, roleId } = this.state.currentUserRole;
        const user = this.state.users.find(user => user.id === userId);
        const role = this.state.roles.find(role => role.id === roleId);
        
        const updatedUserRole = {
            ...this.state.currentUserRole,
            userName: user ? user.username : '',
            roleName: role ? role.roleName : ''
        };

        this.setState({ loading: true });
        this.axiosInstance.post('/userRole', updatedUserRole)
            .then(this.fetchUserRoles)
            .catch(error => {
                const errorMessage = extractRequestErrorMessage(error);
                    alert(errorMessage);
                this.setState({ loading: false });
            });
        this.closeModal();
    };
 
    openModal = (type, userRole = { userId: '', userName: '', roleId: '', roleName: '', sandBox: false }) => {
        this.setState({
            [type]: true,
            currentUserRole: { ...userRole }
        });
    };

    closeModal = () => {
        this.setState({
            showCreateModal: false,
            showDeleteModal: false
        });
    };

    deleteUserRole = () => {
        const { userId, roleId } = this.state.currentUserRole;
        this.setState({ loading: true });
        this.axiosInstance.delete(`/userRole/${userId}/${roleId}`)
            .then(this.fetchUserRoles)
            .catch(error => {
                alert('Failed to delete user role');
                this.setState({ loading: false });
            });
        this.closeModal();
    };

    renderCreateModal = () => {
        const { showCreateModal, currentUserRole, users, roles } = this.state;
        return (
            <Modal show={showCreateModal} modalClosed={this.closeModal}>
                <div>
                    <div>
                        <label htmlFor="userId">User:</label>
                        <select
                            id="userId"
                            name="userId"
                            value={currentUserRole.userId}
                            onChange={this.handleInputChange}
                            required
                        >
                            <option value="">Select User</option>
                            {users.map(user => (
                                <option key={user.id} value={user.id}>{user.username}</option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label htmlFor="roleId">Role:</label>
                        <select
                            id="roleId"
                            name="roleId"
                            value={currentUserRole.roleId}
                            onChange={this.handleInputChange}
                            required
                        >
                            <option value="">Select Role</option>
                            {roles.map(role => (
                                <option key={role.id} value={role.id}>{role.roleName}</option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label>Sandbox:</label>
                        <div onChange={this.handleInputChange}>
                            <input
                                type="radio"
                                value="true"
                                name="sandBox"
                                checked={currentUserRole.sandBox === true}
                                onChange={this.handleInputChange}
                            /> True
                            <input
                                type="radio"
                                value="false"
                                name="sandBox"
                                checked={currentUserRole.sandBox === false}
                                onChange={this.handleInputChange}
                            /> False
                        </div>
                    </div>
                    <div className={styles.modalButtonContainer}>
                        <Button btnType="Success" clicked={this.handleSubmit}>Add UserRole</Button>
                        <Button btnType="Danger" clicked={this.closeModal}>Cancel</Button>
                    </div>
                </div>
            </Modal>
        );
    };
    

    renderDeleteModal = () => {
        const { showDeleteModal, currentUserRole } = this.state;
        return (
            <Modal show={showDeleteModal} modalClosed={this.closeModal}>
                <div>
                    <p>Are you sure you want to delete the userRole "{currentUserRole.roleName}"?</p>
                    <div className={styles.modalButtonContainer}>
                        <Button btnType="Success" clicked={this.deleteUserRole}>Delete</Button>
                        <Button btnType="Danger" clicked={this.closeModal}>Cancel</Button>
                    </div>
                </div>
            </Modal>
        );
    };

    render() {
        const { userRoles, loading } = this.state;
        if (loading) {
            return <Spinner />;
        }
        return (
            <div>
                <button className={`${styles.button} ${styles.newButton}`} onClick={() => this.openModal('showCreateModal')}>New UserRole</button>
                <table className={styles.userRoleTable}>
                    <thead>
                        <tr>
                            <th>User ID</th>
                            <th>Username</th>
                            <th>Role ID</th>
                            <th>Role Name</th>
                            <th>Sandbox</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userRoles.map(userRole => (
                            <tr key={`${userRole.userId}-${userRole.roleId}`}>
                                <td>{userRole.userId}</td>
                                <td>{userRole.userName}</td>
                                <td>{userRole.roleId}</td>
                                <td>{userRole.roleName}</td>
                                <td>{userRole.sandBox ? 'True' : 'False'}</td>
                                <td className={styles.buttonContainer}>
                                    <button className={`${styles.button} ${styles.deleteButton}`} onClick={() => this.openModal('showDeleteModal', userRole)}>
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {this.renderCreateModal()}
                {this.renderDeleteModal()}
            </div>
        );
    }
}

export default UserRoleCrud;