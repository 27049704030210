import React, { useEffect, useState } from 'react';

import classes from './Modal.module.css';
import Aux from '../../../hoc/Auxil';
import Backdrop from '../Backdrop/Backdrop';

// const modal = ( props ) => (
//     <Aux>
//         <Backdrop show={props.show} clicked={props.modalClosed} />
//         <div
//             className={classes.Modal}
//             style={{
//                 transform: props.show ? 'translateY(0)' : 'translateY(-100vh)',
//                 opacity: props.show ? '1' : '0'
//             }}>
//             {props.children}
//         </div>
//     </Aux>
// );
// export default modal;

const Modal = (props) => {
    const [maxHeight, setMaxHeight] = useState('80vh');

    const updateModalSize = () => {
        const viewportHeight = window.innerHeight;
        const modalTop = 0.3 * viewportHeight; // Assuming the modal starts at 30% from the top, adjust as needed
        const maxModalHeight = viewportHeight - modalTop - 10; // 20px for a little margin from the bottom
        setMaxHeight(`${maxModalHeight}px`);
    };

    useEffect(() => {
        window.addEventListener('resize', updateModalSize);
        updateModalSize(); // Initial size update

        // Cleanup listener on unmount
        return () => window.removeEventListener('resize', updateModalSize);
    }, []);

    return (
        <Aux>
            <Backdrop show={props.show} clicked={props.modalClosed} />
            <div
                className={classes.Modal}
                style={{
                    transform: props.show ? 'translateY(0)' : 'translateY(-100vh)',
                    opacity: props.show ? '1' : '0',
                    maxHeight: maxHeight, // Use the dynamic maxHeight
                    overflowY: 'auto' // Ensure content can scroll
                }}>
                {props.children}
            </div>
        </Aux>
    );
};

export default Modal;