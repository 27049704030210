import React, { Component } from 'react';
import Aux from './hoc/Auxil';
import { BrowserRouter, useParams } from 'react-router-dom';
import Layout from './components/Layout/Layout';
import {Route, Routes} from 'react-router-dom';
import Admin from './containers/Admin/Admin';
import RecoltePlanner from './containers/RecoltePlanner/RecoltePlanner';
import Preparation from './containers/LibraryOrderPreparation/Preparation';
import Recolte from './containers/Recolte/Recolte';
import Reception from './containers/Reception/Reception';
import Rassembleur from './containers/Rassembleur/OrderProcessing';
import EtatCommande from './containers/EtatCommande/EtatCommande';
import Suivi from './containers/Suivi/Suivi';
import SuiviItems from './containers/SuiviItems/SuiviItems';
import CartonManager from './containers/CartonManager/CartonManager';
import InternalInventoryPurchase from './containers/InventoryManager/InternalInventoryPurchase';
import Finances from './containers/Finances/Finances';
import DeliveryRemittance from './containers/Finances/DeliveryRemittance';
import InventoryUse from './containers/InventoryManager/InventoryUse';
import RequestCollecte from './containers/Delivery/RequestCollecte';
import RegisterCollection from './containers/Delivery/RegisterCollection';
import RequireRole from './hoc/RequireRole';
import RegisterDeliveryFailure from './containers/Delivery/RegisterDeliveryFailure';
import FinancialOverviewComponent from './containers/Finances/FinancialOverviewComponent';
import OrdersOverviewComponent from './containers/Admin/OrdersOverviewComponent';
import FournitureManager from './containers/Crud/FournitureCrud';
import SearchOrDeliverChoice from './containers/SearchOrDeliverChoice/SearchOrDeliverChoice';
import LivreCrud from './containers/Crud/LivreCrud';
import FreeEtagere from './containers/LibraryOrderPreparation/FreeEtagere';

class UserApp extends Component {
    // Utility function to get sandbox status for a specific role
    getSandboxStatusForRole = (roleName) => {
        const { userRoles } = this.props;
        const roleInfo = userRoles.find(role => role.roleName === roleName);
        return roleInfo ? roleInfo.sandbox : false; // Default to false if role not found
    }
    render() {
        const { userRoles, userId } = this.props;

        return (

            <Aux>
                <BrowserRouter>
                    <Layout userRoles={userRoles} userId={userId}>
                        <Routes>
                            <Route path="/admin" element={RequireRole(['admin'], Admin)({ userId, userRoles: userRoles || [], sandbox: this.getSandboxStatusForRole('admin') })} />
                            <Route path="/ordersoverview" element={RequireRole(['admin'], OrdersOverviewComponent)({ userId, userRoles: userRoles || [], sandbox: this.getSandboxStatusForRole('admin') })} />
                            <Route path="/quoterFourniture" element={RequireRole(['quoter'], FournitureManager)({ userId, userRoles: userRoles || [], sandbox: this.getSandboxStatusForRole('quoter') })} />
                            <Route path="/quoterLivre" element={RequireRole(['quoter'], LivreCrud)({ userId, userRoles: userRoles || [], sandbox: this.getSandboxStatusForRole('quoter') })} />
                            <Route path="/preparator" element={RequireRole(['preparator'], Preparation)({ userId, userRoles: userRoles || [], sandbox: this.getSandboxStatusForRole('preparator') })} />
                            <Route path="/freeetagere" element={RequireRole(['preparator'], FreeEtagere)({ userId, userRoles: userRoles || [], sandbox: this.getSandboxStatusForRole('preparator') })} />
                            <Route path="/etatcommande" element={RequireRole (['etatcommande'], EtatCommande) ({ userId, userRoles: userRoles || [], sandbox: this.getSandboxStatusForRole('etatcommande') })} />
                            <Route path="/suivi" element={RequireRole (['suivi'], Suivi)({ userId, userRoles: userRoles || [], sandbox: this.getSandboxStatusForRole('suivi') })} />
                            <Route path="/suiviitems" element={RequireRole (['suiviitems'], SuiviItems) ({ userId, userRoles: userRoles || [], sandbox: this.getSandboxStatusForRole('suiviitems') })} />
                            <Route path="/searchordeliverchoice" element={RequireRole (['searchordeliverchoice'], SearchOrDeliverChoice) ({ userId, userRoles: userRoles || [], sandbox: this.getSandboxStatusForRole('searchordeliverchoice') })} />
                            <Route path="/ramasseur" element={RequireRole(['ramasseur'], Recolte)({ userId,userRoles: userRoles || [], sandbox: this.getSandboxStatusForRole('ramasseur') }) } />
                            <Route path="/recolte-planner" element={RequireRole (['recolte-planner'], RecoltePlanner) ({ userId, userRoles: userRoles || [], sandbox: this.getSandboxStatusForRole('recolte-planner') })} />
                            <Route path="/reception" element={RequireRole (['reception'], Reception) ({ userId, userRoles: userRoles || [], sandbox: this.getSandboxStatusForRole('reception') })} />
                            <Route path="/rassembleur" element={RequireRole (['rassembleur'], Rassembleur) ({ userId, userRoles: userRoles || [], sandbox: this.getSandboxStatusForRole('rassembleur') })} />
                            <Route path="/cartonmanager" element={RequireRole (['cartonmanager'], CartonManager) ({ userId, userRoles: userRoles || [], sandbox: this.getSandboxStatusForRole('cartonmanager') })} />
                            <Route path="/inventorymanager" element={RequireRole (['inventorymanager'], InternalInventoryPurchase)({ userId, userRoles: userRoles || [], sandbox: this.getSandboxStatusForRole('inventorymanager') })} />
                            <Route path="/inventoryuse" element={RequireRole (['inventoryuse'], InventoryUse) ({ userId, userRoles: userRoles || [], sandbox: this.getSandboxStatusForRole('inventoryuse') })} />
                            <Route path="/financialmanager" element={RequireRole (['financialmanager'], Finances) ({ userId, userRoles: userRoles || [], sandbox: this.getSandboxStatusForRole('financialmanager') })} />
                            <Route path="/financialmanagerdeliveryremittance" element={RequireRole(['financialmanager'], DeliveryRemittance)({ userId, userRoles: userRoles || [], sandbox: this.getSandboxStatusForRole('financialmanager') }) } />
                            <Route path="/financialoverview" element={RequireRole(['financialmanager'], FinancialOverviewComponent)({ userId, userRoles: userRoles || [], sandbox: this.getSandboxStatusForRole('financialmanager') }) } />
                            <Route path="/deliveryRequestCollecte" element={RequireRole(['deliverymanager'], RequestCollecte)({ userId, userRoles: userRoles || [], sandbox: this.getSandboxStatusForRole('deliverymanager') }) } />
                            <Route path="/deliveryRegisterCollection" element={RequireRole(['deliverymanager'], RegisterCollection)({ userId, userRoles: userRoles || [], sandbox: this.getSandboxStatusForRole('deliverymanager') }) } />
                            <Route path="/deliveryRegisterFailure" element={RequireRole(['deliverymanager'], RegisterDeliveryFailure)({ userId, userRoles: userRoles || [], sandbox: this.getSandboxStatusForRole('deliverymanager') }) } />
                            
                        </Routes>
                    </Layout>
                </BrowserRouter>
            </Aux>
        );
    }
}

export default UserApp;