import React, { Component } from 'react';
import { OrderIdsByPhoneNumber, StudentOrderIdsByPhoneNumber, PhoneNumberByStudentOrderId, OrderIdByQuotationUrl, QuotationUrlByOrderId  } from './SuiviComponents/SuiviComponents';

class Suivi extends Component {
  render() {
    return (
      <div>
        <OrderIdsByPhoneNumber />
        <StudentOrderIdsByPhoneNumber />
        <PhoneNumberByStudentOrderId />
        <OrderIdByQuotationUrl/>
        <QuotationUrlByOrderId/>
      </div>
    );
  }
}

export default Suivi;