import React, { Component } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import styles from './RequestCollecte.module.css';
import RequestModal from './RequestModal';
import getAxiosInstance from '../../axios-clicks';
import Spinner from '../../components/UI/Spinner/Spinner'
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

class RequestCollecte extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deliveryCompanies: [],
            ordersToDeliver: [],
            requestedOrders: [],
            selectedDeliveryCompany: '',
            showModal: false,
            selectedOrder: null,
            selectedOrders: [],
            loading:false
        }; this.axiosInstance = getAxiosInstance(this.props.sandbox);
    }

    componentDidMount() {
        this.fetchInitialData();
    }

    fetchInitialData = async () => {
        this.setState({loading:true});
        try {
            // Fetching delivery companies and orders with boxes in one go
            const companiesResponse = this.axiosInstance.get('/delivery/deliveryCompanies');
            const ordersResponse = this.axiosInstance.get('/orders/ordersWithBoxes');
    
            const [companies, orders] = await axios.all([companiesResponse, ordersResponse]);
    
            this.setState({
                deliveryCompanies: companies.data,
                ordersToDeliver: orders.data, // Assuming orders data now includes boxes
                loading: false
            });
        } catch (error) {
            console.error('Error fetching data', error);
            const errorMessage = error.response && error.response.data ? error.response.data : 'Error fetching data.';
            this.setState({ errorMessage: errorMessage, loading: false });
        }
    };
    


    handleDeliveryCompanyChange = (event) => {
        this.setState({ selectedDeliveryCompany: event.target.value });
    };

    handleRequestModal = (order) => {
        // Set the state to show the modal and set the selected order
        this.setState({
            showModal: true,
            selectedOrder: order
        });
        console.log('Open modal for order:', order);
    };

    handleCloseModal = () => {
        // Set the state to hide the modal
        this.setState({
            showModal: false,
            selectedOrder: null
        });
    };

    handleMoveOrders = () => {
        this.setState(prevState => {
            const { ordersToDeliver, requestedOrders, selectedOrders } = prevState;

            // Filter out the selected orders from ordersToDeliver
            const remainingOrdersToDeliver = ordersToDeliver.filter(order =>
                !selectedOrders.includes(order.orderId)
            );

            // Transform the selected orders to match the structure in requestedOrders
            const newRequestedOrders = selectedOrders.map(orderId => {
                // Find the order in ordersToDeliver
                const order = ordersToDeliver.find(o => o.orderId === orderId);

                if (!order) return null;

                // Create a deliveryOrder with all boxes of the order
                const deliveryOrder = {
                    boxes: order.boxes
                };

                // Return new order structure for requestedOrders
                return {
                    orderId: orderId,
                    deliveryOrders: [deliveryOrder]
                };
            }).filter(order => order !== null); // Ensure no null values are added

            // Combine the new requested orders with the existing ones
            return {
                ordersToDeliver: remainingOrdersToDeliver,
                requestedOrders: [...requestedOrders, ...newRequestedOrders]
            };
        });
    };


    handleCheckboxChange = (order, isChecked) => {
        this.setState(prevState => {
            const newSelectedOrders = isChecked
                ? [...prevState.selectedOrders, order.orderId]
                : prevState.selectedOrders.filter(id => id !== order.orderId);

            return { selectedOrders: newSelectedOrders };
        });
    };

    handleRequestOk = (order, deliveryOrders) => {
        this.setState(prevState => {
            const { ordersToDeliver, requestedOrders } = prevState;

            // Remove the order from ordersToDeliver
            const updatedOrdersToDeliver = ordersToDeliver.filter(o => o.orderId !== order.orderId);

            // Add the order with updated deliveryOrders to requestedOrders
            const updatedRequestedOrders = [...requestedOrders, { ...order, deliveryOrders }];

            return {
                ordersToDeliver: updatedOrdersToDeliver,
                requestedOrders: updatedRequestedOrders,
                showModal: false, // Close the modal
                selectedOrder: null // Clear the selected order
            };
        });
    };

    handleFinish = () => {
        console.log(this.state.requestedOrders);
        this.setState({ loading: true });
        const { selectedDeliveryCompany, requestedOrders } = this.state;
        this.axiosInstance.post('/delivery/request-collecte', {
            deliveryCompanyName: selectedDeliveryCompany,
            ordersToCollect: requestedOrders,
        })
            .then(response => {
                alert('Successfully requested');
                // this.resetState();
                this.setState({loading: false, templateData: response.data})
            })
            .catch(error => {
                let errorMessage = "An unexpected error occurred";

                // Check if the error response and data are defined
                if (error.response && error.response.data) {
                    // Attempt to use a detailed error message if it exists
                    if (error.response.data.message) {
                        errorMessage = error.response.data.message;
                    } else {
                        // If there's no message, but there is data, stringify it if it's an object
                        // This is helpful in cases where error data might be JSON
                        errorMessage = typeof error.response.data === 'object' ? JSON.stringify(error.response.data) : error.response.data;
                    }
                } else {
                    // If there's no response from the server (network error, server down, etc.)
                    if (error.message) {
                        errorMessage = error.message;
                    }
                }
                alert(errorMessage);
                this.setState({
                    loading: false
                });
            });
    }
    // handleDownloadXLS = () => {
    //     console.log("handleDownloadXLS");
    //     if (this.state.templateData) {
    //         console.log("handleDownloadXLS if");
    //         const ws = XLSX.utils.json_to_sheet(this.state.templateData.rows, { header: this.state.templateData.headers });
    //         const wb = XLSX.utils.book_new();
    //         XLSX.utils.book_append_sheet(wb, ws, "TemplateData");
    //         console.log("handleDownloadXLS if2");
    //         const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    //         const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });

    //         saveAs(blob, 'template_data.xlsx');
    //     }
    // };
    handleDownloadXLS = () => {
        if (this.state.templateData) {
            // Map your data rows to an array of objects where keys are the headers
            const formattedData = this.state.templateData.rows.map(row => {
                let rowObject = {};
                this.state.templateData.headers.forEach((header, index) => {
                    rowObject[header] = row[index];
                });
                return rowObject;
            });
    
            // Convert the formatted data to a worksheet
            const ws = XLSX.utils.json_to_sheet(formattedData);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "TemplateData");
    
            // Generate and save the XLS file
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    
            saveAs(blob, 'template_data.xlsx');
        }
    };
    
    resetState() {
        this.setState({ 
            requestedOrders: [],
            showModal: false,
            selectedOrder: null,
            selectedOrders: [],
            loading:false});
    }
    renderDeliveryCompanySelection() {
        const { deliveryCompanies, selectedDeliveryCompany } = this.state;
        return (
            <select value={selectedDeliveryCompany} onChange={this.handleDeliveryCompanyChange} className={styles.dropdown}>
                <option value="" disabled={selectedDeliveryCompany !== ''}>Choose a delivery company</option>
                {deliveryCompanies.map((company) => (
                    <option key={company.id} value={company.name}>{company.name}</option>
                ))}
            </select>
        );
    }

    renderOrdersToDeliver() {
        const { ordersToDeliver, selectedOrders } = this.state;
        return (
            <div className={styles.ordersList}>
                {ordersToDeliver.map((order) => (
                    <div key={order.orderId} className={styles.orderLine}>
                        <input
                            type="checkbox"
                            checked={this.state.selectedOrders.includes(order.orderId)}
                            onChange={e => this.handleCheckboxChange(order, e.target.checked)}
                        />
                        <span>order: {order.orderId}</span>
                        <span>{order.boxes.length} cartons</span>
                        <button onClick={() => this.handleRequestModal(order)}>Request</button>
                    </div>
                ))}
            </div>
        );
    }

    renderRequestedOrders() {
        const { requestedOrders, selectedDeliveryCompany } = this.state;
        return (
            <div className={styles.requestedOrdersList}>
                {requestedOrders.map(order => (
                    <div key={order.orderId} className={styles.requestedOrder}>
                        <div className={styles.requestedOrderID}>Order: {order.orderId}</div>
                        <div className={styles.separator}></div>
                        <div className={styles.deliveryOrdersContainer}>
                            {order.deliveryOrders.map(deliveryOrder => (
                                <div key={deliveryOrder.id} className={styles.deliveryOrder}>
                                    {deliveryOrder.boxes.map(box => (
                                        <div key={box.id} className={styles.box}>
                                            {box.id}
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        );
    }

    render() {
        const { deliveryCompanies, ordersToDeliver, requestedOrders, selectedDeliveryCompany, showModal, selectedOrder, loading } = this.state;
        if (loading) {
            return <Spinner />;
        }
        return (
            <div className={styles.container}>
                {this.renderDeliveryCompanySelection()}
                {this.renderOrdersToDeliver()}
                {showModal && selectedOrder &&
                    <RequestModal
                        order={selectedOrder}
                        show={showModal}
                        onClose={this.handleCloseModal}
                        onConfirm={this.handleRequestOk}
                    />
                }
                <button
                    onClick={this.handleMoveOrders}
                    disabled={this.state.selectedOrders.length === 0}
                    className={styles.moveButton}
                >
                    <FontAwesomeIcon icon={faArrowDown} />
                </button>
                {this.renderRequestedOrders()}
                <button
                    className={styles.finishButton}
                    disabled={requestedOrders.length === 0 || selectedDeliveryCompany === ''}
                    onClick={this.handleFinish}
                >
                    Finish
                </button>
                <button
                    // className={styles.finishButton}
                    disabled={!this.state.templateData}
                    onClick={this.handleDownloadXLS}
                >
                    download
                </button>
            </div>
        );
    }
}

export default RequestCollecte;
