import React, { Component } from 'react';
import getAxiosInstance from '../../axios-clicks';
import Modal from '../../components/UI/Modal/Modal';
import Button from '../../components/UI/Button/Button';
import styles from './UserRoleCrud.module.css';
import Spinner from '../../components/UI/Spinner/Spinner';
import { extractRequestErrorMessage } from '../../components/Utils/ExtractRequestErrorMessage';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

class EmployeeCrud extends Component {
    constructor(props) {
        super(props);
        this.state = {
            employees: [],
            statuses: [],
            showCreateModal: false,
            showEditModal: false,
            currentEmployee: {
                id: null,
                name: '',
                monthlySalary: '',
                lastPaidDate: new Date(),
                onboardingDate: new Date(),
                departureDate: new Date(),
                status: ''
            },
            loading: false,
        };
        this.axiosInstance = getAxiosInstance(this.props.sandbox);
    }

    componentDidMount() {
        this.fetchEmployees();
        this.fetchStatuses();
    }

    fetchEmployees = () => {
        this.setState({ loading: true });
        this.axiosInstance.get('/employee')
            .then(response => {
                this.setState({ employees: response.data, loading: false });
            })
            .catch(error => {
                const errorMessage = extractRequestErrorMessage(error);
                alert(errorMessage);
                this.setState({ loading: false });
            });
    };

    fetchStatuses = () => {
        this.setState({ loading: true });
        this.axiosInstance.get('/employee/statuses')
            .then(response => {
                this.setState({ statuses: response.data, loading: false });
            })
            .catch(error => {
                const errorMessage = extractRequestErrorMessage(error);
                alert(errorMessage);
                this.setState({ loading: false });
            });
    };

    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState(prevState => ({
            currentEmployee: { ...prevState.currentEmployee, [name]: value }
        }));
    };

    handleDateChange = (name, date) => {
        this.setState(prevState => ({
            currentEmployee: { ...prevState.currentEmployee, [name]: date }
        }));
    };

    handleSubmit = () => {
        const employee = this.state.currentEmployee;
        // If creating a new employee, set lastPaidDate to onboardingDate
        if (!employee.id) {
            employee.lastPaidDate = employee.onboardingDate;
        }
        this.setState({ loading: true });
        const method = employee.id ? 'put' : 'post';
        const url = employee.id ? `/employee/${employee.id}` : '/employee';
        this.axiosInstance[method](url, employee)
            .then(() => {
                this.fetchEmployees();
                this.closeModal();
                this.setState({ loading: false });
            })
            .catch(error => {
                console.error('Failed to submit employee', error);
                this.setState({ loading: false });
            });
    };

    openModal = (type, employee = null) => {
        if (employee) {
            this.setState({
                [type]: true,
                currentEmployee: { ...employee, lastPaidDate: new Date(employee.lastPaidDate || new Date()), onboardingDate: new Date(employee.onboardingDate || new Date()), departureDate: new Date(employee.departureDate || new Date()) }
            });
        } else {
            this.setState({
                [type]: true,
                currentEmployee: {
                    id: null,
                    name: '',
                    monthlySalary: '',
                    lastPaidDate: new Date(),
                    onboardingDate: new Date(),
                    departureDate: new Date(),
                    status: this.state.statuses[0] || ''
                }
            });
        }
    };

    closeModal = () => {
        this.setState({
            showCreateModal: false,
            showEditModal: false
        });
    };

    renderModal = (type) => {
        const { name, monthlySalary, lastPaidDate, onboardingDate, departureDate, status } = this.state.currentEmployee;
        const isCreateModal = type === 'showCreateModal';

        return (
            <Modal show={this.state[type]} modalClosed={this.closeModal}>
                <div>
                    <label>Name</label>
                    <input type="text" name="name" value={name} onChange={this.handleInputChange} placeholder="Name" />

                    <label>Monthly Salary</label>
                    <input type="text" name="monthlySalary" value={monthlySalary} onChange={this.handleInputChange} placeholder="Monthly Salary" />

                    <label>Onboarding Date</label>
                    <DatePicker selected={onboardingDate} onChange={date => this.handleDateChange('onboardingDate', date)} />

                    {!isCreateModal && (
                        <>
                            <label>Last Paid Date</label>
                            <DatePicker selected={lastPaidDate} onChange={date => this.handleDateChange('lastPaidDate', date)} />

                            <label>Departure Date</label>
                            <DatePicker selected={departureDate} onChange={date => this.handleDateChange('departureDate', date)} />
                        </>
                    )}

                    <label>Status</label>
                    <select name="status" value={status} onChange={this.handleInputChange}>
                        {this.state.statuses.map(status => (
                            <option key={status} value={status}>{status}</option>
                        ))}
                    </select>

                    <div>
                        <Button btnType="Success" clicked={this.handleSubmit}>{isCreateModal ? 'Create' : 'Update'}</Button>
                        <Button btnType="Danger" clicked={this.closeModal}>Cancel</Button>
                    </div>
                </div>
            </Modal>
        );
    };

    render() {
        const { employees, loading } = this.state;
        if (loading) {
            return <Spinner />;
        }
        return (
            <div>
                <button className={`${styles.button} ${styles.newButton}`} onClick={() => this.openModal('showCreateModal')}>Add New Employee</button>
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Salary</th>
                            <th>LastPaidDate</th>
                            <th>Onboarding</th>
                            <th>Departure</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {employees.map(employee => (
                            <tr key={employee.id}>
                                <td>{employee.name}</td>
                                <td>{employee.monthlySalary}</td>
                                <td>{employee.lastPaidDate}</td>
                                <td>{employee.onboardingDate}</td>
                                <td>{employee.departureDate}</td>
                                <td>{employee.status}</td>
                                <td className={styles.buttonContainer}>
                                    <button className={`${styles.button} ${styles.modifyButton}`} onClick={() => this.openModal('showEditModal', employee)}>Edit</button>
                                    <button className={`${styles.button} ${styles.deleteButton}`} onClick={() => this.deleteEmployee(employee.id)}>Delete</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {this.renderModal('showCreateModal')}
                {this.renderModal('showEditModal')}
            </div>
        );
    }

    deleteEmployee = (id) => {
        this.setState({ loading: true });
        this.axiosInstance.delete(`/employee/${id}`)
            .then(() => {
                this.fetchEmployees();
                this.setState({ loading: false });
            })
            .catch(error => {
                console.error('Failed to delete employee', error);
                this.setState({ loading: false });
            });
    };
}

export default EmployeeCrud;
