import React from 'react';
import { Navigate } from 'react-router-dom';

const RequireRole = (allowedRoles, WrappedComponent) => {
    return (props) => {
        const { userRoles } = props;
        
        // Check if userRoles is defined and if it contains the required role
        const hasRequiredRole = userRoles ? userRoles.some(role => allowedRoles.includes(role.roleName)) : false;

        if (!hasRequiredRole) {
            // Redirecting to a generic "Not Authorized" page or home page
            return <Navigate to="/" replace />;
        }

        return <WrappedComponent {...props} />;
    };
};

export default RequireRole;