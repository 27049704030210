import React, { Component } from 'react';
import getAxiosInstance from '../../axios-clicks';
import Modal from '../../components/UI/Modal/Modal';
import Button from '../../components/UI/Button/Button';
import styles from './RassembleurCrud.module.css';
import Spinner from '../../components/UI/Spinner/Spinner';
import { extractRequestErrorMessage } from '../../components/Utils/ExtractRequestErrorMessage';

class RassembleurCrud extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rassembleurs: [],
            users: [],
            libraries: [],
            showCreateModal: false,
            showDeleteModal: false,
            currentRassembleur: { idUser: '', idLibrary: '', status: '' },
            loading: false,
        };
        this.axiosInstance = getAxiosInstance(this.props.sandbox);
    }

    componentDidMount() {
        this.fetchRassembleurs();
        this.fetchUsers();
        this.fetchLibraries();
    }

    fetchRassembleurs = () => {
        this.setState({ loading: true });
        this.axiosInstance.get('/rassembleur')
            .then(response => {
                this.setState({ rassembleurs: response.data, loading: false });
            })
            .catch(error => {
                const errorMessage = extractRequestErrorMessage(error);
                alert(errorMessage);
                this.setState({ loading: false });
            });
    };

    fetchUsers = () => {
        this.axiosInstance.get('/user')
            .then(response => {
                this.setState({ users: response.data });
            })
            .catch(error => {
                alert('Failed to fetch users');
            });
    };

    fetchLibraries = () => {
        this.axiosInstance.get('/library')
            .then(response => {
                this.setState({ libraries: response.data });
            })
            .catch(error => {
                alert('Failed to fetch libraries');
            });
    };

    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState(prevState => ({
            currentRassembleur: { ...prevState.currentRassembleur, [name]: value }
        }));
    };

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({ loading: true });
        this.axiosInstance.post('/rassembleur', this.state.currentRassembleur)
            .then(this.fetchRassembleurs)
            .catch(error => {
                const errorMessage = extractRequestErrorMessage(error);
                alert(errorMessage);
                this.setState({ loading: false });
            });
        this.closeModal();
    };

    openModal = (type, idUser = '', idLibrary = '', status = '') => {
        this.setState({
            [type]: true,
            currentRassembleur: { idUser, idLibrary, status }
        });
    };

    closeModal = () => {
        this.setState({
            showCreateModal: false,
            showDeleteModal: false
        });
    };

    deleteRassembleur = (idUser, idLibrary) => {
        this.setState({ loading: true });
        this.axiosInstance.delete(`/rassembleur/${idUser}/${idLibrary}`)
            .then(this.fetchRassembleurs)
            .catch(error => {
                const errorMessage = extractRequestErrorMessage(error);
                alert(errorMessage);
                this.setState({ loading: false });
            });
    };

    renderCreateModal = () => {
        const { showCreateModal, currentRassembleur, users, libraries } = this.state;
        return (
            <Modal show={showCreateModal} modalClosed={this.closeModal}>
                <div>
                    <div>
                        <label htmlFor="idUser">User:</label>
                        <select
                            id="idUser"
                            name="idUser"
                            value={currentRassembleur.idUser}
                            onChange={this.handleInputChange}
                            required
                        >
                            <option value="">Select User</option>
                            {users.map(user => (
                                <option key={user.id} value={user.id}>{user.username}</option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label htmlFor="idLibrary">Library:</label>
                        <select
                            id="idLibrary"
                            name="idLibrary"
                            value={currentRassembleur.idLibrary}
                            onChange={this.handleInputChange}
                            required
                        >
                            <option value="">Select Library</option>
                            {libraries.map(library => (
                                <option key={library.id} value={library.id}>{library.name}</option>
                            ))}
                        </select>
                    </div>

                    <div className={styles.modalButtonContainer}>
                        <Button btnType="Success" clicked={this.handleSubmit}>Add Rassembleur</Button>
                        <Button btnType="Danger" clicked={this.closeModal}>Cancel</Button>
                    </div>
                </div>
            </Modal>
        );
    };

    renderDeleteModal = () => {
        const { showDeleteModal, currentRassembleur } = this.state;
        return (
            <Modal show={showDeleteModal} modalClosed={this.closeModal}>
                <div>
                    <p>Rassembleur Deletion is not yet implemented</p>
                    <div className={styles.modalButtonContainer}>
                        <Button btnType="Danger" clicked={this.closeModal}>Cancel</Button>
                    </div>
                </div>
            </Modal>
        );
    };

    render() {
        const { rassembleurs, loading } = this.state;
        if (loading) {
            return <Spinner />;
        }
        return (
            <div>
                <button className={`${styles.button} ${styles.newButton}`} onClick={() => this.openModal('showCreateModal')}>New Rassembleur</button>
                <table className={styles.rassembleurTable}>
                    <thead>
                        <tr>
                            <th>UserID</th>
                            <th>Username</th>
                            <th>LibID</th>
                            <th>LibName</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rassembleurs.map(rassembleur => (
                            <tr key={`${rassembleur.idUser}-${rassembleur.idLibrary}`}>
                                <td>{rassembleur.idUser}</td>
                                <td>{this.state.users.find(user => user.id === rassembleur.idUser)?.username || 'N/A'}</td>
                                <td>{rassembleur.idLibrary}</td>
                                <td>{this.state.libraries.find(library => library.id === rassembleur.idLibrary)?.name || 'N/A'}</td>
                                <td>{rassembleur.status}</td>
                                <td className={styles.buttonContainer}>
                                    <button className={`${styles.button} ${styles.deleteButton}`} onClick={() => this.openModal('showDeleteModal', rassembleur.idUser, rassembleur.idLibrary)}>
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>

                </table >
                {this.renderCreateModal()}
                {this.renderDeleteModal()}
            </div >
        );
    }
}

export default RassembleurCrud;