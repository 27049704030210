// Import necessary modules
import React, { Component } from 'react';
import styles from './RetreiveCashFromBank.module.css'; // Import CSS module
import getAxiosInstance from '../../axios-clicks';
import Spinner from '../../components/UI/Spinner/Spinner';

class RetreiveCashFromBank extends Component {
    constructor(props) {
        super(props);
        this.state = {
            amount: '',
            isFormValid: false,
            successMessage: '',
            errorMessage: '',
            showForm: false,
            loading: false,
        };
        this.axiosInstance = getAxiosInstance(this.props.sandbox);
    }

    start = () => {
        this.setState({ showForm: true, });
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value }, this.validateForm);
    }

    validateForm = () => {
        const { amount } = this.state;
        const isAmountValid = parseFloat(amount) > 0;
        this.setState({
            isFormValid:  isAmountValid
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({ loading: true });
        const { amount } = this.state;
        this.axiosInstance.post('/finances/retreive-cash-from-bank', 
            
            { amount: parseFloat(amount) }
        )
        .then(response => {
            this.setState({ successMessage: 'Retrieve registered successfully!', errorMessage: '', loading: false });
        })
        .catch(error => {
            let errorMessage = 'Error register retrieve.';
            if (error.response && error.response.data) {
                // Check if error.response.data is an object and stringify it if necessary
                errorMessage = typeof error.response.data === 'object' ? JSON.stringify(error.response.data) : error.response.data;
            }
            this.setState({ errorMessage: errorMessage, successMessage: '', loading: false });
        });
    }

    render() {
        const {  isFormValid, successMessage, errorMessage, showForm } = this.state;
        return (
            <div className={styles.expenseForm}>
                {this.state.loading && <Spinner />}
                {!this.state.loading && (
                    <>
                        <h1>Retreive Cash from Bank</h1>
                        <button onClick={this.start}>New Retreive</button>
                        {showForm && (
                            <form onSubmit={this.handleSubmit}>
                                <input type="number" name="amount" placeholder="Amount" step="0.01" onChange={this.handleChange} />
                                <button type="submit" disabled={!isFormValid}>Done</button>
                            </form>
                        )}
                        {successMessage && <p className={styles.success}>{successMessage}</p>}
                        {errorMessage && <p className={styles.error}>{errorMessage}</p>}
                    </>
                )}
            </div>
        );
    }
}

export default RetreiveCashFromBank;
