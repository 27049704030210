import React from 'react';
import getAxiosInstance from '../../axios-clicks';
import styles from './FinancialOverviewComponent.module.css';
import Spinner from '../../components/UI/Spinner/Spinner';


class FinancialOverviewComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            financialOverview: null,
            loading: true,
            error: null,
        };
        this.axiosInstance = getAxiosInstance(this.props.sandbox);
    }

    componentDidMount() {
        this.axiosInstance.get('/finances/financial-overview')
            .then(response => {
                this.setState({
                    financialOverview: response.data,
                    loading: false
                });
            })
            .catch(error => {
                let errorMessage = "An unexpected error occurred";

                // Check if the error response and data are defined
                if (error.response && error.response.data) {
                    // Attempt to use a detailed error message if it exists
                    if (error.response.data.message) {
                        errorMessage = error.response.data.message;
                    } else {
                        // If there's no message, but there is data, stringify it if it's an object
                        // This is helpful in cases where error data might be JSON
                        errorMessage = typeof error.response.data === 'object' ? JSON.stringify(error.response.data) : error.response.data;
                    }
                } else {
                    // If there's no response from the server (network error, server down, etc.)
                    if (error.message) {
                        errorMessage = error.message;
                    }
                }
                this.setState({
                    loading: false,
                    error: errorMessage
                });
            });
    }

    render() {
        const { loading, financialOverview, error } = this.state;
        if (loading) {
            return <Spinner />;
        }

        if (error) {
            return <div className={styles.error}>Error: {error}</div>;
        }
        const totalRemaining = (financialOverview.cashBalance + financialOverview.bankBalance
        + financialOverview.totalDeliveryFeesReceivable + financialOverview.totalAccountsReceivable 
        + financialOverview.totalInternalStockValue + financialOverview.totalFulfillmentStockValue
            - financialOverview.totalPendingSalaries - financialOverview.totalPersonAccountsPayable - financialOverview.totalLibraryAccountsPayable
            - financialOverview.totalInvestments);
        return (
            <div className={styles.financialOverview}>
                <h1>Financial Overview</h1>
                <div className={styles.section}>
                    <h2>Recap</h2>
                    <p>Benefice Remaining after Everything Sold/paid/received: {totalRemaining}</p>
                    <p>Total Investments: {financialOverview.totalInvestments}</p>
                </div>
                <div className={styles.section}>
                    <h2>Cash and Bank Balances</h2>
                    <p>Cash: {financialOverview.cashBalance}</p>
                    <p>Bank: {financialOverview.bankBalance}</p>
                </div>
                <div className={styles.section}>
                    <h2>Payable</h2>
                    <p>Libraries: {financialOverview.totalLibraryAccountsPayable}</p>
                    <p>Persons: {financialOverview.totalPersonAccountsPayable}</p>
                    <p>Pending Salaries: {financialOverview.totalPendingSalaries}</p>
                </div>
                <div className={styles.section}>
                    <h2>Receivable</h2>
                    <p>Total: {financialOverview.totalAccountsReceivable}</p>
                    <p>Delivery Fees: {financialOverview.totalDeliveryFeesReceivable}</p>
                </div>
                <div className={styles.section}>
                    <h2>Expenses</h2>
                    <p>Total Expenses: {financialOverview.totalExpenses}</p>
                    <p>Purchases: {financialOverview.totalPurchases}</p>
                    <p>Purchase Returns: {financialOverview.totalPurchaseReturns}</p>
                    <p>Prepaid Expenses: {financialOverview.totalPrepaidExpenses}</p>
                </div>
                <div className={styles.section}>
                    <h2>Sales</h2>
                    <p>Total Sales: {financialOverview.totalSales}</p>
                    <p>Sale Returns: {financialOverview.totalSaleReturns}</p>
                </div>
                
                <div className={styles.section}>
                    <h2>Inventory</h2>
                    <p>Internal Stock: {financialOverview.totalInternalStockValue}</p>
                    <p>Fulfillment Stock: {financialOverview.totalFulfillmentStockValue}</p>
                </div>
                

            </div>
        );
    }
}

export default FinancialOverviewComponent;
