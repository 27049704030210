export const extractRequestErrorMessage = (error) => {
    let errorMessage = "An unexpected error occurred";

    if (error.response && error.response.data) {
        if (error.response.data.message) {
            errorMessage = error.response.data.message;
        } else {
            errorMessage = typeof error.response.data === 'object' ? JSON.stringify(error.response.data) : error.response.data;
        }
    } else if (error.message) {
        errorMessage = error.message;
    }

    return errorMessage;
};