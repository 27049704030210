import React, { Component } from 'react';
import Modal from '../../components/UI/Modal/Modal';
import getAxiosInstance from '../../axios-clicks';
import styles from './FournitureCrud.module.css';
import Button from '../../components/UI/Button/Button';
import Spinner from '../../components/UI/Spinner/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

class LivreCrud extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchQuery: {
                livre: ''
            },
            data: [],
            showModal: false,
            modalData: {
                titre: '',
                isbn: '',
                edition: '',
                price: 0.0
            },
            message: '',
            loading: false,
        };
        this.axiosInstance = getAxiosInstance(this.props.sandbox);
    }

    handleSearchChange = (e, type) => {
        const newQuery = { livre: '' };
        newQuery[type] = e.target.value;
        this.setState({ searchQuery: newQuery });
    };

    handleSearch = (type) => {
        const { searchQuery } = this.state;
        const query = searchQuery[type];
        let url = '';

        if (type === 'livre') url = `/article/search_livre?query=${query}&limit=200`;

        this.setState({ loading: true });
        this.axiosInstance.get(url)
            .then(response => {
                this.setState({ data: response.data, loading: false });
            })
            .catch(error => {
                this.setState({ loading: false });
                console.error('Error fetching data:', error);
            });
    };
    openModal = () => {
        this.setState({ showModal: true });
    };

    closeModal = () => {
        this.setState({
            showModal: false,
            modalData: {
                titre: '',
                isbn: '',
                edition: '',
                price: 0.0
            }
        });
    };

    handleModalChange = (e) => {
        const { name, value } = e.target;
        this.setState(prevState => ({
            modalData: {
                ...prevState.modalData,
                [name]: value
            }
        }));
    };

    handleModalSubmit = () => {
        this.setState({ loading: true });
        const { modalData } = this.state;
        let url = '';
        let data = {};


        url = '/article/save-livre';
        data = {
            titre: modalData.titre,
            isbn: modalData.isbn,
            edition: modalData.edition,
            prixVente: modalData.price,
        };




        this.axiosInstance.post(url, data)
            .then(response => {
                if (response.status === 200) {
                    this.setState({ message: 'Successfully created!' });
                    this.clearSearchBars();
                }
                this.setState({ loading: false });
            })
            .catch(error => {
                this.setState({ message: 'Error not created', loading: false });
                console.error('Error creating item:', error);
            })
            .finally(() => {
                this.closeModal();
            });
    };

    clearSearchBars = () => {
        this.setState({
            searchQuery: { livre: '' },
            data: [],
        });
    };
    render() {
        const { searchQuery, data,  showModal,  modalData, message, loading } = this.state;
        if (loading) {
            return <Spinner />;
        }
        return (
            <div className={styles.FournitureManager}>
                <div className={styles.ButtonGroup}>
                    <button
                        onClick={() => this.openModal()}>
                        New Livre
                    </button>
                    
                </div>

                {message && <p>{message}</p>}

                <div className={styles.Table}>
                    <table>
                        <thead>
                            <tr>
                                <th>Livre</th>
                            </tr>
                            <tr>
                                <th>
                                    <input
                                        type="text"
                                        value={searchQuery.livre}
                                        onChange={(e) => this.handleSearchChange(e, 'livre')} />
                                    <button onClick={() => this.handleSearch('livre')}>
                                        <FontAwesomeIcon icon={faSearch} />
                                    </button>
                                </th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((livre, index) => (
                                <tr key={index}>
                                    
                                    <td>
                                        {livre.titre} - {livre.edition} - {livre.isbn} - {livre.price}
                                    </td>
                                   
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>



                <Modal show={showModal} modalClosed={this.closeModal}>
                    <div className={styles.ModalContent}>
                        
                            
                                <input
                                    type="text"
                                    placeholder="Titre"
                                    name="titre"
                                    value={modalData.titre}
                                    onChange={this.handleModalChange} />
                                <input
                                    type="text"
                                    placeholder="isbn"
                                    name="isbn"
                                    value={modalData.isbn}
                                    onChange={this.handleModalChange} />
                                <input
                                    type="text"
                                    placeholder="edition"
                                    name="edition"
                                    value={modalData.edition}
                                    onChange={this.handleModalChange} />
                                <input
                                    type="number"
                                    placeholder="Price"
                                    name="price"
                                    value={modalData.price}
                                    onChange={this.handleModalChange} />
                            
                            <div className={styles.ButtonGroup}>
                            <Button btnType="Success" clicked={this.handleModalSubmit}>OK</Button>
                            <Button btnType="Danger" clicked={this.closeModal}>Cancel</Button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default LivreCrud;
