import React, { useState } from 'react';
import getAxiosInstance  from './axios-clicks';
import UserApp from './UserApp';
import classes from './Login.module.css';
// Login component
const Login = ({ handleLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginFailed, setLoginFailed] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const axiosInstance = getAxiosInstance(false);
    try {
      

      const response = await axiosInstance.post('/login', {'username': username, 'password': password});

      const { success, roles, userId } = response.data;

      if (success) {
        handleLogin(roles, userId);
      } else {
        console.log("login failure")
        setLoginFailed(true);
      }
    } catch (error) {
      console.log("error login")
      setLoginFailed(true);
    }
  };

  return (
    <form className={classes.LoginForm} onSubmit={handleSubmit}>
      
      <input
      className={classes.LoginInput}
        type="text"
        placeholder="Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      <input
        className={classes.LoginInput}
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button className={classes.LoginButton} type="submit">Login</button>
      {loginFailed && <label className={classes.LoginLabel}>Login failed</label>}
    </form>
  );
};



// Main app component
const App = () => {
  const [userRoles, setUserRoles] = useState([]); 
  const [userId, setUserId] = useState(null);

  const validRoles = ['admin', 'quoter', 'rassembleur', 'preparator', 'ramasseur', 'reception', 'etatcommande', 
        'suivi', 'suiviitems', 'cartonmanager', 'inventorymanager', 'inventoryuse', 'financialmanager', 'deliverymanager', 'searchordeliverchoice'];
  
  const handleLogin = (roles, id) => {
    // Filter roles to include only those present in validRoles
    const filteredRoles = roles.filter(role => validRoles.includes(role.roleName));
    if (filteredRoles.length > 0) {
      setUserRoles(filteredRoles); // Set the filtered roles
      setUserId(id);
    } else {
      console.log('No valid roles found for user.');
    }
  };

  return (
    <div>
    {console.log('UserId inside Login:', userId)}
    {userRoles.length === 0 ? (
      <Login handleLogin={handleLogin} />
    ) : (
      <UserApp userRoles={userRoles} userId={userId} />
    )}
  </div>
  );
};

export default App;