import React, { Component } from 'react';
import getAxiosInstance  from '../../axios-clicks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import classes from './Recolte.module.css'; // Assume your CSS module is named Recolte.module.css
import Modal from '../../components/UI/Modal/Modal';
import OptionTabComponent from './OptionTabComponent';
import RecoltedOrders from './RecoltedOrders';
import Button from '../../components/UI/Button/Button';
import Spinner from '../../components/UI/Spinner/Spinner';


class Recolte extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groups: [],
      currentTab: 'original', // Set the default tab to 'original'
      showModal: false,
      selectedOrderId: null,
      modalContent: null,
      prevTab: '',
      loading: false,
    };
    this.axiosInstance = getAxiosInstance(this.props.sandbox);
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps, prevState) {
    // Check if the current tab is 'original' and it was different from the previous tab
    if (this.state.currentTab === 'original' && prevState.currentTab !== 'original') {
      this.fetchData();
    }
  }

  fetchData = () => {
    this.setState({ loading: true });
    this.axiosInstance.get('/libraryOrders/gatheredLibraryOrdersForRecolte')
      .then(response => {
        this.setState({ groups: response.data, loading:false });
      })
      .catch(error => {
        this.setState({ loading: false });
        console.error('Error fetching library groups:', error);
      });
  }
  handleTabChange = (newTab) => {
    this.setState(prevState => ({
      currentTab: newTab,
      prevTab: prevState.currentTab, // Update prevTab with the current tab before changing it
    }));
  }

  renderToolbar() {
    const { currentTab } = this.state;
    const tabs = ['original', 'recolted', 'option'];

    return (
      <ul className={classes.ScrollableStudentsMenu}>
        {tabs.map((tab) => (
          <li
            key={tab}
            className={`${classes.ScrollableStudentsMenuItem} ${currentTab === tab ? classes.ActiveStudentItem : ""}`}
            onClick={() => this.handleTabChange(tab)}
          >
            {tab === 'option' ? <FontAwesomeIcon icon={faCog} /> : tab}
          </li>
        ))}
      </ul>
    );
  }

  renderOriginalTab() {
    const { groups } = this.state;
    return (
      <div className={classes.LibraryGroupsContainer}>
        {groups.map(group => (
          <div key={group.libraryName} className={classes.LibraryGroup}>
            <div className={classes.LibraryName}>{group.libraryName}</div>
            {group.orders.map(order => (
              <div key={order.id} className={classes.OrderDetails}>
                <div className={classes.OrderDate}>
                  {new Date(order.date).toLocaleDateString('en-US', { day: '2-digit', month: '2-digit' }).replace(/\/(\d{2})$/, '/$1')}
                </div>
                <div className={classes.OrderId}>{order.id}</div>
                <button className={classes.RecolteButton} onClick={() => this.handleRecolteClick(order.id)}>Recolte</button>
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  }

  handleRecolteClick = (orderId) => {
    console.log("recolte order")
    this.setState({
      showModal: true,
      selectedOrderId: orderId,
      modalContent: (
        <div>
          <p className={classes.ModalOrderId}>{orderId}</p>
          <Button btnType="Danger" clicked={this.handleCloseModal}>NO</Button>
          <Button btnType="Success" clicked={this.handleConfirmRecolte}>YES</Button>
        </div>
      )
    });
  };
  handleConfirmRecolte = () => {
    const { selectedOrderId } = this.state;
    const { userId } = this.props; // Assuming userId is passed as a prop
    this.setState({ loading: true });
    this.axiosInstance.post('/recolte/recolteOrder', { orderId: selectedOrderId, userId })
      .then(response => {
        if (response.status === 200) {
          // Assuming the operation was successful, remove the order from the state
          this.setState(prevState => ({
            groups: prevState.groups.map(group => ({
              ...group,
              orders: group.orders.filter(order => order.id !== selectedOrderId),
            }))
              .filter(group => group.orders.length > 0),
            showModal: false, // Close the modal
            selectedOrderId: null,
            loading: false,
          }));
        }
      })
      .catch(error => {
        // Handle error by showing an error message in the modal
        this.setState({
          modalContent: (
            <div>
              <p>Error: {error.message}</p>
              <button onClick={this.handleCloseModal}>OK</button>
            </div>
          ),
          loading: false
        });
      });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false, selectedOrderId: null, modalContent: null });
  };

  renderModal() {
    const { showModal, modalContent } = this.state;
    return showModal && (
      <Modal show={showModal} modalClosed={this.handleCloseModal}>
        {modalContent}
      </Modal>
    );
  }

  renderRecoltedOrders() {
    const { userId } = this.props;
    return <RecoltedOrders userId={userId} />;
  }

  renderOptionTab() {
    const { userId } = this.props;
    return <OptionTabComponent userId={userId} />;
  }

  renderContent() {
    const { currentTab } = this.state;
    switch (currentTab) {
      case 'original':
        return this.renderOriginalTab();
      case 'recolted':
        return this.renderRecoltedOrders();
      case 'option':
        return this.renderOptionTab();
      default:
        return null;
    }
  }

  render() {
    return (
      <div>
        {this.state.loading && <Spinner />}
        {!this.state.loading && (
          <>
            {this.renderToolbar()}
            {this.renderContent()}
            {this.renderModal()}
          </>
        )}
        
      </div>
    );
  }
}

export default Recolte;
