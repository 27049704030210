// Import necessary modules
import React, { Component } from 'react';
import styles from './Investment.module.css'; // Import CSS module
import getAxiosInstance from '../../axios-clicks';
import Spinner from '../../components/UI/Spinner/Spinner';

class Investment extends Component {
    constructor(props) {
        super(props);
        this.state = {

            investor: '',
            amount: '',
            isFormValid: false,
            successMessage: '',
            errorMessage: '',
            showForm: false,
            loading: false,
        };
        this.axiosInstance = getAxiosInstance(this.props.sandbox);
    }

    start = () => {
        this.setState({ showForm: true, });
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value }, this.validateForm);
    }

    validateForm = () => {
        const { investor,  amount } = this.state;
        const isAmountValid = parseFloat(amount) > 0;
        this.setState({
            isFormValid: investor && isAmountValid
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({ loading: true });
        const { investor, amount } = this.state;
        this.axiosInstance.post('/finances/record-investment', {
            investor: investor,
            amount: parseFloat(amount),
        })
            .then(response => {
                this.setState({ successMessage: 'Investment registered successfully!', errorMessage: '', loading: false });
            })
            .catch(error => {
                const errorMessage = error.response && error.response.data ? error.response.data : 'Error registering expense.';
                this.setState({ errorMessage: errorMessage, successMessage: '', loading: false });
            });
    }

    render() {
        const {  isFormValid, successMessage, errorMessage, showForm } = this.state;
        return (
            <div className={styles.expenseForm}>
                {this.state.loading && <Spinner />}
                {!this.state.loading && (
                    <>
                        <h1>Register New Investment</h1>
                        <button onClick={this.start}>New Investment</button>
                        {showForm && (
                            <form onSubmit={this.handleSubmit}>
                                <input type="text" name="investor" placeholder="Investor" onChange={this.handleChange} />
                                <input type="number" name="amount" placeholder="Amount" step="0.01" onChange={this.handleChange} />
                                <button type="submit" disabled={!isFormValid}>Done</button>
                            </form>
                        )}
                        {successMessage && <p className={styles.success}>{successMessage}</p>}
                        {errorMessage && <p className={styles.error}>{errorMessage}</p>}
                    </>
                )}
            </div>
        );
    }
}

export default Investment;
